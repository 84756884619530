import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ProgressIndicator, Spinner, SpinnerSize } from "@fluentui/react";

import { login, logout, setUserFullName } from 'vidaplanninglogic/lib/reduxx/slices/userSlice'
import { setLanguage } from 'vidaplanninglogic/lib/reduxx/slices/commonSlice'
import { setSubscription } from 'vidaplanninglogic/lib/reduxx/slices/subscriptionSlice'
import { setConversionFactor, setUserSettings } from 'vidaplanninglogic/lib/reduxx/slices/userSettingsSlice'
import { Apis } from "vidaplanninglogic/lib/services/apis/Apis";
import { AppSetting } from "vidaplanninglogic/lib/data/constants/AppSetting";
import { ILoadable } from "vidaplanninglogic/lib/data/entities/ILoader";
import { RootState } from "vidaplanninglogic/lib/reduxx/store";

class LoginFormClass extends Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            userId: '',
            password: '',

            progressHidden: true,
            progressPercent: 0,
            progressDescription: '',

            loading: false,
            failed: false,
            error: '',
        }
    }

    onSubmit = async (e: any) => {
        e.preventDefault();
        this.setState({
            loading: true,
            progressHidden: false,
            progressPercent: 0.05,
            progressDescription: 'Getting Vts Token ...',
        });

        try {
            let tokenResponse = await Apis.VtsToken.GetToken(this.state.userId, this.state.password);
            await this.loadSubscriptions(tokenResponse.data, this.state.userId);
        }
        catch (exception) {
            this.catchError(exception);
        };
    }

    loadSubscriptions = async (token: string, username: string) => {
        console.log("VTS Token Success");
        this.setState({
            progressPercent: 0.4,
            progressDescription: 'Getting Subscription ...',
        });
        Apis.AuthServices.authenticate(token, username);
        let subscriptionResponse = await Apis.Subscription.GetSubscription(AppSetting.DeviceId, AppSetting.ComputerName);
        this.setState({
            progressPercent: 0.6,
            progressDescription: 'Getting User Settings ...',
        });
        let userSettingsResponse = await Apis.UserSettingsApi.GetUserSettings();
        this.setState({
            progressPercent: 0.8,
            progressDescription: 'Getting User Settings ...',
        });
        let conversionResponse = await Apis.ConversionFactorApi.GetConversionFactor();
        this.setState({
            progressPercent: 1,
            progressDescription: 'Loading is completed ...',
        });

        this.props.setUserSettings(userSettingsResponse);
        this.props.setConversionFactor(conversionResponse);
        this.props.setUserFullName({ firstName: subscriptionResponse.data.firstName, lastName: subscriptionResponse.data.lastName });
        this.props.setLanguage('en-GB');
        this.props.setSubscription(subscriptionResponse.data);
        this.props.login();
    }

    catchError = (errors: any) => {
        console.log(errors.message);
        this.setState({

            progressHidden: true,
            progressPercent: 0,
            progressDescription: '',

            failed: true,
            error: 'We have problem to Login!',
            loading: false,
        });
    }

    validate(): boolean {
        return !(this.state.userId.length > 0 && this.state.password.length > 0)
    }

    render() {
        let errors = this.state.failed && (
            <div className='alert alert-danger'>
                <em >{this.state.error}</em>
            </div>
        );
        let loading = this.state.loading && (
            <Spinner style={{ display: 'inline-block' }} size={SpinnerSize.small} />
        );
        return (
            <div>
                <div className="p-3" >
                    <h3 className="m-3">Login User</h3>
                    {errors}
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group m-3">
                            <label>User ID:  </label>
                            <input
                                type="text"
                                className="form-control"
                                name="email"
                                value={this.state.userId}
                                onChange={(ev) => this.setState({ userId: ev.target.value })}
                            />
                        </div>
                        <div className="form-group m-3">
                            <label>Password:  </label>
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                value={this.state.password}
                                onChange={(ev) => this.setState({ password: ev.target.value })}
                            />
                        </div>
                        <div className='m-3'>
                            <ProgressIndicator
                                progressHidden={this.state.progressHidden}
                                styles={{ itemDescription: { color: 'white' } }}
                                description={this.state.progressDescription}
                                percentComplete={this.state.progressPercent} />
                        </div>
                        <div className="form-group">
                            <button type="submit" disabled={this.validate()} className="btn btn-primary m-3">Login {loading}</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

}

interface props extends PropsFromRedux {
}

interface state extends ILoadable {
    userId: string,
    password: string,

    progressHidden: boolean,
    progressPercent: number,
    progressDescription: string,
}

const mapStateToProps = (state: RootState) => ({
})
const mapDispatchToProps = {
    login, logout,
    setUserFullName,
    setSubscription,
    setLanguage,
    setConversionFactor,
    setUserSettings
}


const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export const LoginForm = connector(LoginFormClass)