import * as React from 'react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { MessageType } from 'vidaplanninglogic/lib/data/constants/MessageType';
import { TIMEOUT } from 'dns';

interface props {
    MessageType?: MessageType,
    MessageText: string,
    MessageId: number,
    ClearMessage(): void,
}
export const Message: React.FunctionComponent<props> = (props: props) => {


    const [show, setShow] = React.useState(false);
    // const updateTimer = React.useRef<any>(null);

    // function setUpdate() {
    //     setShow(true);
    //     updateTimer.current = setTimeout(() => {
    //         setShow(false);
    //         updateTimer.current = null;
    //     }, 5000);
    // }


    React.useEffect(() => {
        setShow(true);
        setTimeout(() => {
            setShow(false);
            //updateTimer.current = null;
        }, 5000);
        // if(!updateTimer.current) {
        //     console.log('useEffect!');

        //   setUpdate();
        // }
    }, [props.MessageId]);

    return (
        <>
            {
                (props.MessageText.length > 0) && show ? (
                    <MessageBar onClick={props.ClearMessage}
                        // IMPORTANT: Set role="none" to prevent nested status regions
                        messageBarType={props.MessageType == undefined ? MessageBarType.info : props.MessageType.valueOf()}
                    >
                        {props.MessageText}
                    </MessageBar>
                ) : (<div style={{ height: 32 }}>
                </div>)
            }
        </>
    );
}
