import { IIconProps } from '@fluentui/react';

export const Accept: IIconProps = { iconName: 'Accept' };
export const Cancel: IIconProps = { iconName: 'Cancel' };
export const Edit: IIconProps = { iconName: 'Edit' };
export const Send: IIconProps = { iconName: 'Send' };
export const Print: IIconProps = { iconName: 'Print' };
export const Note: IIconProps = { iconName: 'QuickNote' };
export const Down: IIconProps = { iconName: 'ChevronDown' };
export const Right: IIconProps = { iconName: 'ChevronRight' };
export const SignOut: IIconProps = { iconName: 'SignOut' };
export const Medical: IIconProps = { iconName: 'Medical' };
export const Book: IIconProps = { iconName: 'BookAnswers' };
export const Cube: IIconProps = { iconName: 'CubeShape' };
export const Delete: IIconProps = { iconName: 'delete' };