import { IWorkListViewModel } from "vidaplanninglogic/lib/data/entities/IProfiledVehicle";


function TableFooter(props: props) {

    const anyItemSelected = (): boolean => {
        return props.WorkList.ServiceProgramOperations.length > 0;
    }

    const calculateSubTotalTime = (): number => {
        let subTotalTime = 0;
        props.WorkList.ServiceProgramOperations.filter(x => x.snoozed == false).forEach(spo => {
            subTotalTime += Number(spo.vst) * spo.quantity
        })
        return subTotalTime;
    }

    const calculateSubTotalCost = (): number => {
        let subTotalCost: number = 0;
        props.WorkList.ServiceProgramOperations.filter(x => x.snoozed == false).forEach(spo => {
            subTotalCost += spo.hasPackage == true ? (Number(props.WorkList.IncludeTax ?
                spo.package!.package[0].dmsPrice.amountWithVAT : spo.package!.package[0].dmsPrice.amountWithoutVAT) * spo.quantity)
                :
                (Number(props.WorkList.IncludeTax ?
                    spo.dmsPrice?.amountWithVAT : spo.dmsPrice?.amountWithoutVAT) * spo.quantity)
        })
        return subTotalCost;
    }

    return (
        <>
            {
                (anyItemSelected()) && (
                    <div className='row justify-content-sm-end mb-4'>
                        <div className='col-sm-3'>
                            <div className='row'>
                                <div className='col-sm-8'>Job Subtotal Time:</div>
                                <div className='col-sm-4'>{calculateSubTotalTime()}</div>
                            </div>
                        </div>
                        <div className='col-sm-3'>
                            <div className='row'>
                                <div className='col-sm-8'>Subtotal Cost:</div>
                                <div className='col-sm-4'>{calculateSubTotalCost().toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

interface props {
    WorkList: IWorkListViewModel
}

export default TableFooter

