import * as React from 'react';
import * as Icons from '../common/IconProps';
import {
    mergeStyleSets,
    FocusTrapCallout,
    FocusZone,
    FocusZoneTabbableElements,
    FontWeights,
    Stack,
    Text,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { DefaultButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';

interface props {
    deleteWorklist(): void
}

export const ConfirmationMessage: React.FunctionComponent<props> = (props: props) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');

    return (
        <>
            <IconButton iconProps={Icons.Delete} id={buttonId} onClick={toggleIsCalloutVisible} text="Delete List" />
            {isCalloutVisible ? (
                <FocusTrapCallout
                    role="alertdialog"
                    className={styles.callout}
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <Text block variant="xLarge" className={styles.title}>
                        Delete List
                    </Text>
                    <Text block variant="small">
                        Do you want to delete the selected list?
                    </Text>
                    {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                    <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                        <Stack className={styles.buttons} gap={8} horizontal>
                            <PrimaryButton onClick={() => {
                                toggleIsCalloutVisible();
                                props.deleteWorklist();
                            }}>OK</PrimaryButton>
                            <DefaultButton onClick={toggleIsCalloutVisible}>Cancel</DefaultButton>
                        </Stack>
                    </FocusZone>
                </FocusTrapCallout>
            ) : null}
        </>
    );
};

const styles = mergeStyleSets({
    callout: {
        width: 320,
        padding: '20px 24px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
});
