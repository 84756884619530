import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Shimmer, Link, Spinner, SpinnerSize, IconButton } from "@fluentui/react";

import { Apis } from "vidaplanninglogic/lib/services/apis/Apis";
import { IRecentVehicle } from "vidaplanninglogic/lib/data/entities/dtos/IRecentVehicle";
import { MessageType } from "vidaplanninglogic/lib/data/constants/MessageType";
import { Services } from "vidaplanninglogic/lib/services/apis/Services";
import { SearchVehiclesTab, SearchVehicsTabClass } from "./SearchVehiclesTab";
import { VidaLogTypes } from "../../../common/VidaLogTypes";
import { RootState } from "vidaplanninglogic/lib/reduxx/store";
import { setLoading, setMessage, setSelectedTab } from "vidaplanninglogic/lib/reduxx/slices/commonSlice";
import { profiledVehiclesActions } from "vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice";
import * as Icons from '../../common/IconProps';
import { setUserSettings } from "vidaplanninglogic/lib/reduxx/slices/userSettingsSlice";
import React from "react";


export class RecentVehiclesTabClass extends Component<props, state> {
    searchVehicleRef;
    constructor(props: props) {
        super(props);

        this.state = {
            recentVehicles: [],
            loading: false,
            loadingVehicleId: '',
            isFineTuneOpen: this.props.userSettings?.IsExpandFinetuningOn,
            vin: '',
            chassis: '',
            partnerGroupId: 0,
            recentVehicleId: '',
            componentHeight: window.outerHeight,
        }
        this.searchVehicleRef = React.createRef<SearchVehicsTabClass>();
    }

    componentDidMount() {
        this.notifyWebLogsToVida(VidaLogTypes.Info, "RecentVehicleTab did mount");
        this.populateData();
        window.addEventListener('resize', () => { this.setState({ componentHeight: window.innerHeight }) });
    }

    notifyWebLogsToVida(vidaLogType: VidaLogTypes, message: string) {
        if (this.props.notifyWebLogstoVida)
            this.props.notifyWebLogstoVida(vidaLogType, message);
    }

    async populateData() {
        this.notifyWebLogsToVida(VidaLogTypes.Info, "Recent vehicle list grid population started");
        this.props.setLoading(true);
        this.setState({ loading: true })
        Apis.RecentVehicles.RecentVehiclesList()
            .then((result) => {
                this.props.setLoading(false);
                this.setState({
                    recentVehicles: result.data,
                    loading: false
                })
                this.setState({ isFineTuneOpen: this.props.userSettings?.IsExpandFinetuningOn });
            }).catch(error => {
                console.log(error)
                this.notifyWebLogsToVida(VidaLogTypes.Error, error);
                this.props.setLoading(false);
                this.props.setMessage({ messageText: 'Recent Vehicles could not be loaded!', messageType: MessageType.error })
                this.setState({
                    recentVehicles: [],
                    loading: false
                })
            })
        this.notifyWebLogsToVida(VidaLogTypes.Info, "Recent vehicle list grid population ended");
    }

    renderSelectButtonLoading = (id: string) => {
        return (
            <>
                {(this.state.loadingVehicleId == id) ?
                    (<Spinner style={{ display: 'inline-block' }} size={SpinnerSize.small} />) :
                    (<span>{this.props.translationTexts["vida.btn.select"]}</span>)}
            </>
        )

    }

    compareModelYear = (a: IRecentVehicle, b: IRecentVehicle, asc: boolean) => {
        if (a.modelYear.description < b.modelYear.description) {
            return asc ? -1 : 1;
        }
        if (a.modelYear.description > b.modelYear.description) {
            return asc ? 1 : -1;
        }
        return 0;
    }

    compareModel = (a: IRecentVehicle, b: IRecentVehicle, asc: boolean) => {
        if (a.vehicleModel.description < b.vehicleModel.description) {
            return asc ? -1 : 1;
        }
        if (a.vehicleModel.description > b.vehicleModel.description) {
            return asc ? 1 : -1;
        }
        return 0;
    }

    renderTable() {
        return (
            <table className="table table-sm table-striped">
                <thead >
                    <tr>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: 'rgb(194, 219, 243)' }} scope="col">{this.props.translationTexts["vida.lbl.customername"]}</th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: 'rgb(194, 219, 243)' }} scope="col">{this.props.translationTexts["vida.lbl.vin"]}</th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: 'rgb(194, 219, 243)' }} scope="col"
                            onClick={() => {
                                let modelAsc = this.state.modelAsc == undefined ? true : !this.state.modelAsc;
                                this.setState({
                                    modelAsc,
                                    recentVehicles: this.state.recentVehicles.sort((x, y) => { return this.compareModel(x, y, modelAsc) })
                                })
                            }}>{this.props.translationTexts["vida.lbl.model"]}</th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: 'rgb(194, 219, 243)' }} scope="col"
                            onClick={() => {
                                let modelYearAsc = this.state.modelYearAsc == undefined ? true : !this.state.modelYearAsc;
                                this.setState({
                                    modelYearAsc,
                                    recentVehicles: this.state.recentVehicles.sort((x, y) => { return this.compareModelYear(x, y, modelYearAsc) })
                                })
                            }}>{this.props.translationTexts["vida.lbl.year"]}</th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: 'rgb(194, 219, 243)' }} scope="col">{this.props.translationTexts["vida.lbl.licenseplate"]}</th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: 'rgb(194, 219, 243)' }} scope="col">{this.props.translationTexts["lblCustomerType"]}</th>
                        <th style={{ position: 'sticky', top: 0, backgroundColor: 'rgb(194, 219, 243)' }} scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.recentVehicles.map((item) => (
                            <tr key={item.id} onClick={() => this.onRecentVehicleRowClick(item)}
                                style={{
                                    verticalAlign: "center",
                                    backgroundColor: this.state.recentVehicleId == item.id ? '#c2dbf3' : ''
                                }}>
                                <td style={{ fontSize: 13 }}>{item.customerName}</td>
                                <td style={{ fontSize: 13 }}>
                                    <Link onClick={() => {
                                        if (this.props.notifyVidaVehicleViewDisplay)
                                            this.props.notifyVidaVehicleViewDisplay!(item.vin, item.partnerGroup.id)
                                    }}>
                                        {item.vin}
                                    </Link>
                                </td>
                                <td style={{ fontSize: 13 }}>{item.vehicleModel.description}</td>
                                <td style={{ fontSize: 13 }}>{item.modelYear.cid}</td>
                                <td style={{ fontSize: 13 }}>{item.licensePlateNumber}</td>
                                <td style={{ fontSize: 13 }}></td>
                                <td className="text-end px-4">
                                    <button
                                        onClick={() => this.profileVehicle(item)}
                                        style={{ minWidth: 100, height: 30, fontSize: 13, verticalAlign: "top" }}
                                        type="button"
                                        className="btn btn-outline-primary btn-sm">{this.renderSelectButtonLoading(item.id)}</button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table >
        )
    }

    onRecentVehicleRowClick = (vehicle: IRecentVehicle) => {
        this.notifyWebLogsToVida(VidaLogTypes.Info, "onRecentVehicleRowClick has started");
        this.searchVehicleRef.current?.setVinFromParrent(vehicle.vin.substring(0, 11), vehicle.vin.substring(11, 17), vehicle.partnerGroup.id)
        this.setState({
            vin: vehicle.vin.substring(0, 11),
            chassis: vehicle.vin.substring(11, 17),
            partnerGroupId: vehicle.partnerGroup.id,
            recentVehicleId: vehicle.id,
        })
    }

    clearSelectedRecentVehicle = () => {
        console.log('clearSelectedRecentVehicle');
        this.setState({ recentVehicleId: '' })
    }

    profileVehicle = async (recentVehicle: IRecentVehicle) => {
        this.notifyWebLogsToVida(VidaLogTypes.Info, "profileVehicle has started");

        if (this.props.notifyVidaRecentVehicle) {
            this.props.notifyVidaRecentVehicle(recentVehicle.vin, recentVehicle.partnerGroup.id);
            return;
        }

        if (this.props.profiledVehicles.some(x => x.vin == recentVehicle.vin)) {
            this.props.setMessage({ messageText: 'You already profiled this Vehicle!', messageType: MessageType.warning });
            return;
        }
        if (this.props.profiledVehicles.length == 5) {
            this.props.setMessage({ messageText: 'You already profiled 5 Vehicle, Please remove some of them!', messageType: MessageType.warning });
            return;
        }

        this.props.setLoading(true);
        this.setState({ loadingVehicleId: recentVehicle.id })
        try {
            let selectedVehicle = await Services.vehicleProfileServices.
                GetProfiledVehicleByVin(recentVehicle.vin, recentVehicle.partnerGroup.id);
            this.props.addProfiledVehicleWithData(selectedVehicle);
            this.props.setLoading(false);
            this.setState({ loadingVehicleId: '' })
            this.props.setSelectedTab(recentVehicle.vin)

        } catch (error) {
            this.props.setLoading(false);
            this.setState({ loadingVehicleId: '' })
        }
    }

    togglePanel() {
        this.setState({ isFineTuneOpen: !this.state.isFineTuneOpen })
    }

    render() {
        let loading = this.state.loading && (
            <div>
                <Shimmer className="p-2" />
                <Shimmer className="p-2" width="75%" />
                <Shimmer className="p-2" width="50%" />
                <Shimmer className="p-2" />
                <Shimmer className="p-2" width="75%" />
            </div>
        )
        return (
            <div className="row" style={{ height: this.state.componentHeight, width: '100%' }}>
                <div className="pt-3 px-3 overflow-hidden" style={{ height: this.state.isFineTuneOpen ? this.state.componentHeight - 320 : this.props.isVidaClientUi ? this.state.componentHeight - 50 : this.state.componentHeight - 320 }}>
                    <h5>{this.props.translationTexts["vida.lbl.recentvehicletitle"]}</h5>
                    <div className="row px-3" style={{ height: '90%', overflowY: 'scroll' }}>
                        {!this.state.loading ? (this.renderTable()) : (loading)}
                    </div>
                </div>
                <div className="px-3 overflow-hidden" style={{ height: this.state.isFineTuneOpen ? 320 : this.props.isVidaClientUi ? 50 : 320 }}>
                    <h5 className="px-1" onClick={(e) => this.togglePanel()} style={{ backgroundColor: 'rgb(194, 219, 243)', minHeight: '30px' }}>
                        <span><IconButton iconProps={this.state.isFineTuneOpen ? Icons.Down : Icons.Right} onClick={() => this.togglePanel()} /></span>
                        <span className="ms-3">{this.props.translationTexts["vida.lbl.finetunevehicle"]}</span>
                    </h5>
                    {
                        this.state.isFineTuneOpen ? (<SearchVehiclesTab
                            ref={this.searchVehicleRef}
                            isRecentVehicle={true}
                            partnerGroupCid={this.props.PartnerGroupCid}
                            clearSelectedRecentVehicle={this.clearSelectedRecentVehicle}
                            notifyVidaRecentVehicle={this.props.notifyVidaRecentVehicle}
                            vin={this.state.vin} chassis={this.state.chassis} partnerGroupId={this.state.partnerGroupId}
                            translationTexts={this.props.translationTexts}
                            notifySendVehicleInfoToDms={this.props.notifySendVehicleInfoToDms}
                            notifyProfileVehicleWithoutVinToVida={this.props.notifyProfileVehicleWithoutVinToVida} />) : null
                    }
                </div>
            </div >
        )
    }
}



interface props extends PropsFromRedux {
    translationTexts?: any,
    isVidaClientUi?: boolean
    PartnerGroupCid?: string,
    notifyVidaVehicleViewDisplay?(vin: string, partnerGroupId: number): void,
    notifySendVehicleInfoToDms?(vin: string, partnerGroupId: number): void,
    notifyWebLogstoVida?(logType: VidaLogTypes, message: string): void,
    notifyVidaRecentVehicle?(vin: string, partnerGroupId: number): void,
    notifyProfileVehicleWithoutVinToVida?(selectedModel: number,
        selectedYear: number,
        selectedPartnerGroup: number,
        selectedEngine: number | null,
        selectedTransmission: number | null,
        selectedSteering: number,
        selectedSpecialVehicle: number,
        selectedBodyStyle: number): void
}

interface state {
    recentVehicles: IRecentVehicle[],
    loading: boolean,
    loadingVehicleId: string,
    isFineTuneOpen: boolean,
    vin: string,
    chassis: string,
    partnerGroupId: number,
    recentVehicleId: string,
    componentHeight: number,
    modelYearAsc?: boolean,
    modelAsc?: boolean,
}

const mapStateToProps = (store: RootState) => ({
    profiledVehicles: store.profiledVehicles.Vehicles,
    userSettings: store.userSettings,
    setUserSettings
})
const mapDispatchToProps = {
    addProfiledVehicleWithData: profiledVehiclesActions.addProfiledVehicleWithData,
    setLoading,
    setMessage,
    setSelectedTab
}

const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;
export const RecentVehiclesTab = connector(RecentVehiclesTabClass);