import * as React from 'react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';

interface props {
    Label?: string,
    Description?: string,
    Show: boolean
}
export const LoadingBar: React.FunctionComponent<props> = (props: props) => {
    return (
        <>
            {
                (props.Show == true) ? (
                    <ProgressIndicator
                        styles={
                            {
                                progressBar:
                                {
                                    height: 5
                                },
                                itemProgress:
                                {
                                    padding: 0,
                                    height: 5
                                }
                            }
                        }
                        label={props.Label}
                        description={props.Description} />
                ) : (<div style={{ height: 5 }}>
                    </div>)
            }
        </>
    );
}
