import { FontIcon, IBasePicker, IBasePickerSuggestionsProps, IInputProps, ISuggestionItemProps, ITag, IToggleStyles, Link, mergeStyles, TagPicker } from '@fluentui/react';
import React from 'react'
import { connect, ConnectedProps } from 'react-redux';

import { ICsc } from 'vidaplanninglogic/lib/data/entities/dtos/ICsc';
import { IOperation } from 'vidaplanninglogic/lib/data/entities/dtos/IOperation';
import { IOperationViewModel } from 'vidaplanninglogic/lib/data/entities/IOperationViewModel';
import { IPackageViewModel } from 'vidaplanninglogic/lib/data/entities/IPackageViewModel';
import { IProfiledVehicle } from 'vidaplanninglogic/lib/data/entities/IProfiledVehicle';
import { Apis } from 'vidaplanninglogic/lib/services/apis/Apis';
import { MapIOperationToIOperationViewModel } from 'vidaplanninglogic/lib/services/services/mapper/IOperationToIOperationViewModel'
import { setLoading, setMessage, } from "vidaplanninglogic/lib/reduxx/slices/commonSlice";
import { actionCreators } from 'vidaplanninglogic/lib/services/services/worklist/actionCreators/actionCreators';

import { OperationRow } from './OperationRow';
import { PackageRow } from './PackageRow';
import SuggestionRow from './SuggestionRow';
import TableFooter from './TableFooter';
import { Services } from 'vidaplanninglogic/lib/services/apis/Services';
import { ContentType } from 'vidaplanninglogic/lib/data/entities/ContentType';
import { IServiceOrderActionRequest } from 'vidaplanninglogic/lib/data/entities/IServiceOrderActionRequest';
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice'
import { MessageType } from 'vidaplanninglogic/lib/data/constants/MessageType';
import { RootState } from 'vidaplanninglogic/lib/reduxx/store';

class OperationPackagesClass extends React.Component<props, state>{

    dropdownRef = React.createRef<IBasePicker<ITag>>();

    constructor(props: any) {
        super(props);
        this.state = {
            showDropdown: false,
            showSearchTools: false,
            showResultPanel: false,
            opOptions: [],
            selectedKey: '',
            searchText: '',
            tagPickerValue: [],
        }
    }

    rootClass = mergeStyles({
        maxWidth: 1000,
    });

    toggleStyles: Partial<IToggleStyles> = { root: { margin: '10px 0' } };

    pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested Operations and Packages',
        noResultsFoundText: 'No Operation and Packages found',
    };

    onRenderSuggestionsItem = (props: ITag, itemProps: ISuggestionItemProps<ITag>) => {
        let op = this.state.opOptions.filter(x => x.operationNumber == props.key);
        return (
            <SuggestionRow
                addSelectedPackage={this.addSelectedPackage}
                addSelectedOperation={this.addSelectedOperation}
                operation={op[0]}
                vehicleContext={this.props.selectedVehicle.vehicleContext} />
        )
    };

    listContainsTagList = (op: IOperation): boolean => {
        return this.props.selectedVehicle.currentWorkList!.Operations.some(x => x.operationNumber == op.operationNumber);
    };

    filterSuggestedItems = async (filterText: string, tagList?: ITag[]): Promise<ITag[]> => {
        let result: ITag[] = []
        if (filterText) {
            if (filterText.length > 2) {
                let operationList = await Apis.OperatonApi.GetOperations('en-GB', filterText + '*', this.props.selectedVehicle.vehicleContext);

                if (operationList.data?.operationitem && operationList.data?.operationitem.length > 0) {
                    let operations = MapIOperationToIOperationViewModel(operationList.data?.operationitem);
                    this.setState({ opOptions: operations })
                    let filteredOp = operationList?.data.operationitem.filter(
                        op =>
                            // op.operationNumber.toLowerCase().startsWith(filterText.toLowerCase()) &&
                            !this.listContainsTagList(op))
                    result = filteredOp.map(x => {
                        return { key: x.operationNumber, name: x.operationNumber, ...x }
                    })
                }

            }
        }
        return result;
    };

    getTextFromItem = (item: ITag) => item.name;

    onRenderOption = (item: ICsc) => {
        return (
            <table>
                <tbody>
                    <tr>
                        <td style={{ width: 250 }}>{item.code}</td>
                        <td style={{ width: '*' }}>
                            {item.componentFunction.desc}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    onRenderTitle(item: any): JSX.Element | null {
        return (
            <div>{item[0].code}&nbsp;&nbsp;{item[0].componentFunction.desc}</div>
        );
    }

    onTextInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        switch (ev.target.name) {
            case 'searchText':
                this.setState({ searchText: ev.target.value });
                break;
        }
    }

    SaveEngineCall = (data: IServiceOrderActionRequest) => {
        this.props.addAction({ vin: this.props.selectedVehicle.vin, action: data.actions[0] })
        // console.log(this.props.selectedVehicle.currentWorkList);
        // Apis.ServiceOrder.SaveEngineCall(this.props.selectedVehicle.currentWorkList!.ServiceOrderId, this.props.selectedVehicle.currentWorkList!.Version, data).
        //     then((response) => {
        //         this.props.updateVersion({ version: response.data.version, vin: this.props.selectedVehicle.vin });
        //         console.log("Row Saved!!!");
        //     }).catch((error) => {
        //         console.log(error);
        //     })
    }

    // addSelectedOperation = async (items?: ITag[]) => {
    addSelectedOperation = async (op: IOperationViewModel) => {
        this.dropdownRef.current?.completeSuggestion(true);
        let isOperationExistInSP: boolean = this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations
            .filter(x => x.snoozed == false && x.operationNumber == op.operationNumber).length > 0;

        if (isOperationExistInSP) {
            this.props.setMessage({ messageText: 'Max quantity reached!', messageType: MessageType.warning });
            return;
        }

        op.jobNumber = Services.WorklistServices.GetNextJobNumber(this.props.selectedVehicle.currentWorkList!, ContentType.Operations);
        op.quantity = 1;
        op.rowId = Services.WorklistServices.GetMaxRowId(this.props.selectedVehicle.currentWorkList!.Operations);

        let action = actionCreators.AddOperation(op);
        this.SaveEngineCall(action);

        let operation: IOperationViewModel = {
            ...op,
            jobNumber: op.jobNumber,
            quantity: 1,
            dmsPrice: {
                amountWithVAT: 0,
                amountWithoutVAT: 0,
                currency: ''
            }
        }
        await Apis.DMSApi.getOperationDetails(operation, this.props.selectedVehicle);
        this.props.addOperation({ operation: operation, vin: this.props.selectedVehicle.vin });
    }

    addSelectedPackage = async (pk: IPackageViewModel) => {
        this.dropdownRef.current?.completeSuggestion(true);
        // console.log(this.props.selectedVehicle);
        let operation = pk.includedOperations.operationitem[0];
        pk.packageNumber = `${pk.packageKey.mainOperationNumber}-${pk.packageKey.sequenceGroup}`
        // console.log("this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations");
        // console.log(this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations);
        // console.log(pk.packageNumber);


        let isPackageExistInSP: boolean = this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations
            .filter(x => x.package?.package?.[0].snoozed! == false && x.package?.package?.[0].packageNumber == pk.packageNumber).length > 0;

        // console.log("isPackageExistInSP: " + isPackageExistInSP);

        if (isPackageExistInSP) {
            this.props.setMessage({ messageText: 'Max quantity reached!', messageType: MessageType.warning });
            return;
        }

        operation.rowId = 1;
        pk.jobNumber = Services.WorklistServices.GetNextJobNumber(this.props.selectedVehicle.currentWorkList!, ContentType.Packages);
        pk.quantity = 1;
        pk.rowId = Services.WorklistServices.GetMaxRowId(this.props.selectedVehicle.currentWorkList!.Packages);

        // console.log(pk);

        let action = actionCreators.AddPackage(pk);
        this.SaveEngineCall(action);
        await Apis.DMSApi.getPackageDetails(pk, this.props.selectedVehicle);
        this.props.addPackage({ pkg: pk, vin: this.props.selectedVehicle.vin });


    }

    GetLatestServiceOrderVersion = (): number => {
        return this.props.selectedVehicle.currentWorkList!.Version
    }

    renderSearchPanel = () => {
        return (
            <React.Fragment>
                <div className={'row p-2 ' + ((this.state.showSearchTools == false) ? 'd-inline' : 'd-none')}>
                    <Link onClick={() => this.setState({ showSearchTools: true }, () => {
                        console.log("we are here")
                        this.dropdownRef.current!.focus();
                    })}>Add</Link>
                </div>
                <div className={'row p-2 ' + ((this.state.showSearchTools == true) ? 'd-inline' : 'd-none')}>
                    <TagPicker
                        removeButtonAriaLabel="Remove"
                        selectionAriaLabel="Selected Operations and Packages"
                        onResolveSuggestions={this.filterSuggestedItems}
                        getTextFromItem={this.getTextFromItem}
                        pickerSuggestionsProps={this.pickerSuggestionsProps}
                        itemLimit={1}
                        resolveDelay={500}
                        componentRef={this.dropdownRef}
                        onRenderSuggestionsItem={this.onRenderSuggestionsItem}
                        selectedItems={[]}
                    />
                </div>
            </React.Fragment>
        )
    }

    renderResultPanel = () => {
        return (
            <React.Fragment>
                <div className='row'>
                    {((this.props.selectedVehicle.currentWorkList!.Operations?.length > 0) || (this.props.selectedVehicle.currentWorkList!.Packages.length > 0)) && (
                        <table className="table table-hover table-sm">
                            <thead className='table-light'>
                                <tr className='text-center'>
                                    <th style={{ width: 20 }} scope="col"></th>
                                    <th style={{ width: 10 }} scope="col"></th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Item No.</th>
                                    <th className="text-start" scope="col">Description</th>
                                    <th scope="col">Skill level</th>
                                    <th scope="col">Notes</th>
                                    <th scope="col">Job No</th>
                                    <th scope="col">Job Time</th>
                                    <th scope="col">Fixed Price</th>
                                    <th scope="col">Unit Cost</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(this.props.selectedVehicle.currentWorkList!.Operations?.length > 0) && (
                                    <>
                                        <tr><td colSpan={15}><FontIcon iconName="DeveloperTools" />Operations</td></tr>
                                        {
                                            this.props.selectedVehicle.currentWorkList!.Operations.map(item => (<OperationRow
                                                IsServiceProgramOperation={false}
                                                Operation={item}
                                                SelectedVehicle={this.props.selectedVehicle}
                                                SaveEngineCall={this.SaveEngineCall}
                                                GetLatestServiceOrderVersion={this.GetLatestServiceOrderVersion} />))
                                        }
                                    </>
                                )}
                                {(this.props.selectedVehicle.currentWorkList!.Packages.length > 0) && (
                                    <>
                                        <tr><td colSpan={13}><FontIcon iconName="CubeShape" />Packages</td></tr>
                                        {
                                            this.props.selectedVehicle.currentWorkList!.Packages.map((pk) => <PackageRow
                                                ShowIncludeButton={false}
                                                Package={pk}
                                                SelectedVehicle={this.props.selectedVehicle}
                                                SaveEngineCall={this.SaveEngineCall}
                                                GetLatestServiceOrderVersion={this.GetLatestServiceOrderVersion} />)
                                        }
                                    </>
                                )}

                            </tbody>
                        </table>
                    )}
                </div>
            </React.Fragment>
        )
    }

    render() {
        return (
            <>
                <div className="row p-2 mt-2 border">
                    <div className='bg-light p-2 border'>
                        <h5 className='mb-0'><FontIcon iconName="CubeShape" /><span className='ms-3'>Operations and Packages - </span>
                            <span>{this.props.selectedVehicle.currentWorkList!.Operations?.length + this.props.selectedVehicle.currentWorkList!.Packages.length}</span></h5>
                    </div>
                    <div className='row p-2' >
                        {
                            this.renderResultPanel()
                        }
                    </div>
                    {
                        this.renderSearchPanel()
                    }
                </div>
                <TableFooter SelectedOperations={this.props.selectedVehicle.currentWorkList!.Operations}
                    SelectedPackages={this.props.selectedVehicle.currentWorkList!.Packages}
                    IncludeTax={this.props.selectedVehicle.currentWorkList!.IncludeTax}
                    ShowFixedPrice={this.props.selectedVehicle.currentWorkList!.ShowFixedPrice} />
            </>
        )
    }
}

interface props extends PropsFromRedux {
    selectedVehicle: IProfiledVehicle
}

interface state {
    showSearchTools: boolean,
    showDropdown: boolean,
    showResultPanel: boolean,
    selectedKey: string,
    searchText: string,
    opOptions: IOperationViewModel[],
    tagPickerValue: any,
}

const mapStateToProps = (store: RootState) => ({
    profiledVehicles: store.profiledVehicles.Vehicles
})
const mapDispatchToProps = {
    setLoading,
    setMessage,
    updateJobNumber: profiledVehiclesActions.updateJobNumber,
    updateWorkList: profiledVehiclesActions.updateWorkList,
    addOperation: profiledVehiclesActions.addOperation,
    addPackage: profiledVehiclesActions.addPackage,
    updateVersion: profiledVehiclesActions.updateVersion,
    addAction: profiledVehiclesActions.addAction
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const OperationPackages = connector(OperationPackagesClass)