import { FontIcon, IconButton } from '@fluentui/react';
import { forwardRef, useState } from 'react';

import { IVehicleContext } from 'vidaplanninglogic/lib/data/entities/dtos/IVehicleContext';
import { IPackage } from 'vidaplanninglogic/lib/data/entities/IPackage';
import { Apis } from 'vidaplanninglogic/lib/services/apis/Apis';

import './SuggestionRow.css'

import { Down, Right } from '../../common/IconProps';
import { IPackageViewModel } from 'vidaplanninglogic/lib/data/entities/IPackageViewModel';
import { MapEachIPackageToIPackageViewModel } from 'vidaplanninglogic/lib/services/services/mapper/IPackageToIPackageViewModel'
import { IOperationViewModel } from 'vidaplanninglogic/lib/data/entities/IOperationViewModel';

function SuggestionRow(props: props) {
    const [showPackage, setPackageVisibility] = useState<boolean>(false);
    const [packages, setPackages] = useState<IPackage[]>();

    const onExpandButtonClick = (e: { stopPropagation: () => void; }) => {
        console.log('button clicked');
        Apis.PackageApi.GetPackages('en-GB', props.operation.operationNumber, props.operation.variantGroupId,
            props.vehicleContext)
            .then((response) => {
                setPackages(response.data.package);
            })
        setPackageVisibility(!showPackage)
        e.stopPropagation()
    }

    const renderExpandButton = () => {
        return (
            <>
                {(props.operation.hasPackage == true) && (<IconButton onClick={onExpandButtonClick} iconProps={showPackage ? Down : Right} />)}
            </>
        )
    }

    return (
        <div className='bg-white'>
            <div className='row p-1 text-nowrap text-start hover-item' style={{ width: 1000 }}
                onClick={(e) => {
                    props.addSelectedOperation(props.operation)
                    e.stopPropagation();
                }}
            >
                <div className='col-1'>{renderExpandButton()}</div>
                <div className='col-2'>Operation</div>
                <div className='col-2'>{props.operation.operationNumber}-{props.operation.operationType}</div>
                <div className='col-3' style={{ overflow: 'hidden' }}>
                    <p className='mb-0'>{props.operation.operationDescription}</p>
                    <p className="mb-0 text-muted">{props.operation.jobscopeDescription}</p>
                </div>
                <div className='col-2'>Job Time: {props.operation.vst}</div>
                <div className='col-2'>{props.operation.mpCategory}: {props.operation.functionGroup}</div>
            </div>

            {
                showPackage && (
                    packages?.map((pk: IPackage) => {
                        return (
                            <div className='row p-1 text-nowrap text-start hover-item' style={{ width: 1000 }}
                                onClick={(e) => {
                                    props.addSelectedPackage(MapEachIPackageToIPackageViewModel(pk));
                                    e.stopPropagation();
                                }}>
                                <div className='col-1'></div>
                                <div className='col-2'>Package <FontIcon iconName="CubeShape" /></div>
                                <div className='col-2'>{pk.packageKey.mainOperationNumber}-{pk.packageKey.sequenceGroup}</div>
                                <div className='col-3' style={{ overflow: 'hidden' }}>
                                    <p className='mb-0'>{props.operation.operationDescription}</p>
                                    <p className="mb-0 text-muted">{props.operation.jobscopeDescription}</p>
                                </div>
                                <div className='col-2'>Job Time: {props.operation.vst}</div>
                                <div className='col-2'>{props.operation.mpCategory}: {props.operation.functionGroup}</div>
                            </div>
                        )
                    })
                )
            }
        </div>
    )
}

interface props {
    operation: IOperationViewModel;
    vehicleContext: IVehicleContext;
    addSelectedPackage(pk: IPackageViewModel): void
    addSelectedOperation(op: IOperationViewModel): void
}

export default SuggestionRow