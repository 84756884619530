import { Accept, Cancel, Note } from '../../common/IconProps'
import { IconButton, Toggle } from '@fluentui/react'
import { IOperationViewModel } from 'vidaplanninglogic/lib/data/entities/IOperationViewModel';
import { IDataStore } from 'vidaplanninglogic/lib/data/entities/IDataStore';
import { connect, ConnectedProps } from 'react-redux';
import { setLoading, setMessage, } from "vidaplanninglogic/lib/reduxx/slices/commonSlice";
import { Apis } from 'vidaplanninglogic/lib/services/apis/Apis';
import { ActionsEnum } from 'vidaplanninglogic/lib/data/entities/WorkListActions';
import { MessageType } from 'vidaplanninglogic/lib/data/constants/MessageType';
import { IServiceOrderActionRequest } from 'vidaplanninglogic/lib/data/entities/IServiceOrderActionRequest';
import { actionCreators } from 'vidaplanninglogic/lib/services/services/worklist/actionCreators/actionCreators';
import { UpdateServiceProgram } from 'vidaplanninglogic/lib/services/services/worklist/actionCreators/UpdateServiceProgram.services';
import { IProfiledVehicle } from 'vidaplanninglogic/lib/data/entities/IProfiledVehicle';
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice';
import { ContentType } from 'vidaplanninglogic/lib/data/entities/ContentType';
import { IServiceProgram } from 'vidaplanninglogic/lib/data/entities/dtos/IServiceProgram';
function OperationRowFunction(props: props) {

    let jobNumber = '';
    // const calculateOperationUnitCost = (operation: IOperationViewModel): string => {

    //     if (operation.dmsPrice.amountWithVAT == undefined) return '';
    //     if (operation.dmsPrice.amountWithoutVAT == undefined) return '';

    //     let price = Number(operation.vst) * 0.1 * operation.dmsPrice.amountWithVAT!;
    //     return price.toFixed(2).toString();
    // }

    // const calculateOperationCost = (operation: IOperationViewModel): string => {

    //     if (operation.dmsPrice.amountWithVAT == undefined) return '';
    //     if (operation.dmsPrice.amountWithoutVAT == undefined) return '';

    //     let price = Number(operation.vst) * 0.1 * operation.quantity * operation.dmsPrice.amountWithVAT!;
    //     return price.toFixed(2).toString();
    // }
    let qty = '';

    return (
        <>
            <tr key={props.Operation.operationNumber} className='text-center'>
                <td scope="row"><IconButton iconProps={Accept} /></td>
                <td></td>
                <td>Operation</td>
                <td style={{ textAlign: 'left' }}>{props.Operation.operationNumber}-{props.Operation.operationType}</td>
                <td className='text-nowrap text-start' style={{ overflow: 'hidden', maxWidth: 200 }}>
                    <p className='mb-0'>{props.Operation.operationDescription}</p>
                    <p className="mb-0 text-muted">{props.Operation.jobscopeDescription}</p>
                </td>
                <td>{props.Operation.skillLevel}</td>
                <td><IconButton iconProps={Note} /></td>
                <td>
                    {props.IsServiceProgramOperation ? (
                        //Service Program operation
                        props.Operation.snoozed ? (props.Operation.jobNumber) :
                            <input size={1} type='string' value={props.Operation.jobNumber?.toString()}
                                onFocus={(x) => { jobNumber = x.target.value; }}
                                onChange={(ev) => { props.updateJobNumber!({ vin: props.SelectedVehicle.vin, rowId: props.Operation.operationNumber, jobNumber: ev.target.value, contentType: ContentType.ServiceProgramOperations }) }}
                                onBlur={(ev) => {
                                    if (jobNumber != ev.target.value) {
                                        let action: IServiceOrderActionRequest = UpdateServiceProgram(props.SelectedVehicle.currentWorkList!.ServiceProgram!, props.Operation.operationNumber, "")
                                        props.SaveEngineCall(action)
                                    }
                                }}>
                            </input>)
                        //Operation
                        : <input size={1} type='string' value={props.Operation.jobNumber?.toString()}
                            onFocus={(x) => { jobNumber = x.target.value; }}
                            onChange={(ev) => { props.updateJobNumber!({ vin: props.SelectedVehicle.vin, rowId: props.Operation.rowId, jobNumber: ev.target.value, contentType: ContentType.Operations }) }}
                            onBlur={(ev) => {
                                if (jobNumber != ev.target.value) {
                                    let action: IServiceOrderActionRequest = actionCreators.UpdateOperation(props.Operation);
                                    props.SaveEngineCall(action)
                                }
                            }}>
                        </input>
                    }
                </td>
                <td>{props.Operation.vst}</td>
                <td></td>
                <td>{props.SelectedVehicle.currentWorkList!.IncludeTax ?
                    props.Operation.dmsPrice?.amountWithVAT?.toFixed(2) : props.Operation.dmsPrice?.amountWithoutVAT?.toFixed(2)} </td>

                <td>
                    {
                        props.IsServiceProgramOperation ? (
                            <span>{props.Operation.quantity?.toString()}</span>
                        ) : (<input
                            size={1}
                            type='string'
                            name="Quantity"
                            value={props.Operation.quantity?.toString()}
                            onFocus={(x) => {
                                qty = x.target.value;
                                console.log(qty);
                            }}
                            onChange={(ev) => {
                                if (props.Operation.maxquantity < parseFloat(ev.target.value) || parseFloat(ev.target.value) <= 0) {
                                    ev.preventDefault();
                                    props.setMessage({ messageText: 'Max quantity reached!', messageType: MessageType.warning });
                                    return;
                                }
                                props.updateQuantity({ vin: props.SelectedVehicle.vin, rowId: props.Operation.rowId, quantity: parseFloat(ev.target.value), contentType: ContentType.Operations });
                            }}
                            onBlur={(ev) => {
                                if (qty == ev.target.value) {
                                    console.log("No update!");
                                    return;
                                }
                                console.log("before saving!");
                                let action: IServiceOrderActionRequest = actionCreators.UpdateOperation(props.Operation);
                                props.SaveEngineCall(action);
                            }}>
                        </input>)
                    }
                </td>
                <td>{(Number(props.SelectedVehicle.currentWorkList!.IncludeTax ?
                    props.Operation.dmsPrice?.amountWithVAT : props.Operation.dmsPrice?.amountWithoutVAT) * props.Operation.quantity).toFixed(2)}</td>
                <td>
                    {props.IsServiceProgramOperation ?
                        (<Toggle checked={props.Operation.snoozed == false} onClick={() => {
                            try {
                                props.setLoading(true);
                                let snoozed: boolean = props.Operation.snoozed! == true ? false : true;
                                console.log(`Snooze/Unsnooze Operation number: ${props.Operation.operationNumber}`);
                                props.snoozeUnsnoozeServiceProgramOperations({ vin: props.SelectedVehicle.vin, itemNumber: props.Operation.operationNumber, snoozed: !!!props.Operation.snoozed });
                                let serviceProgram: IServiceProgram = {
                                    ...props.SelectedVehicle.currentWorkList!.ServiceProgram!,
                                    serviceProgramOperations: props.SelectedVehicle.currentWorkList!.ServiceProgram!.serviceProgramOperations.map(x => {
                                        return x.operationNumber == props.Operation.operationNumber ? { ...x, snooze: snoozed } : x
                                    })
                                }

                                let action: IServiceOrderActionRequest = UpdateServiceProgram(serviceProgram, props.Operation.operationNumber, "");
                                props.SaveEngineCall(action);
                            }
                            catch (error) {
                                props.setMessage({ messageText: "Error occured in Snooze Operation!", messageType: MessageType.error });
                            }
                            finally {
                                props.setLoading(false);
                            }
                        }} />) :
                        <IconButton iconProps={Cancel} onClick={() => {

                            let action: IServiceOrderActionRequest = actionCreators.RemoveOperation(props.Operation.rowId)
                            ///UpdateServiceProgram(serviceProgram, props.Operation.operationNumber, "");
                            props.SaveEngineCall(action);
                            props.removeOperation!({ rowId: props.Operation.rowId, vin: props.SelectedVehicle.vin });

                            // props.setLoading(true)
                            // Apis.ServiceOrder.RemoveServiceOrderItem(ActionsEnum.RemoveOperation, props.Operation.rowId, 
                            //     props.SelectedVehicle.currentWorkList!.ServiceOrderId, props.GetLatestServiceOrderVersion!())
                            //     .then((response) => {
                            //         props.updateVersion({ version: response.data.version, vin: props.SelectedVehicle.vin });
                            //         props.removeOperation!({ rowId: props.Operation.rowId, vin: props.SelectedVehicle.vin });
                            //         props.setMessage({ messageText: `Operation ${props.Operation.operationNumber} Removed Successfully!`, messageType: MessageType.success });
                            //     })
                            //     .catch((error) => {
                            //         props.setMessage({ messageText: "Error occured in Remove Operation!", messageType: MessageType.error });
                            //     })
                            //     .finally(() => props.setLoading(false));
                        }} />}
                </td>
            </tr>
        </>
    )
}

interface props extends PropsFromRedux {
    Operation: IOperationViewModel,
    SelectedVehicle: IProfiledVehicle,
    IsServiceProgramOperation: boolean,
    UpdateServiceProgramOpJobNumber?(id: string, jobNumber: string): void
    SaveEngineCall(data: IServiceOrderActionRequest): void,
    GetLatestServiceOrderVersion?(): number,
}

const mapStateToProps = (dataStore: IDataStore) => ({
})

const mapDispatchToProps = {
    setLoading,
    setMessage,
    removeOperation: profiledVehiclesActions.removeOperation,
    updateJobNumber: profiledVehiclesActions.updateJobNumber,
    updateVersion: profiledVehiclesActions.updateVersion,
    updateQuantity: profiledVehiclesActions.updateQuantity,
    snoozeUnsnoozeServiceProgramOperations: profiledVehiclesActions.snoozeUnsnoozeServiceProgramOperations,
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const OperationRow = connector(OperationRowFunction)

