import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { useBoolean } from '@fluentui/react-hooks';

const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
};
const modalPropsStyles = { main: { maxWidth: 450 } };

interface props {
    title: string,
    subText: string,
    show: boolean,
    toggleVisibility(): void
}

export const DialogMessage: React.FunctionComponent<props> = (props: props) => {
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: isDraggable ? dragOptions : undefined,
        }),
        [isDraggable],
    );

    return (
        <>
            <Dialog
                hidden={!props.show}
                onDismiss={props.toggleVisibility}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: props.title,
                    subText: props.subText,
                }}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton onClick={props.toggleVisibility} text="Ok" />
                    {/* <DefaultButton onClick={toggleHideDialog} text="Don't send" /> */}
                </DialogFooter>
            </Dialog>
        </>
    );
};