export class VidaCommunicationTypes {
    public static VtsToken = "VtsToken";
    public static PartnerGroupCid = "PartnerGroupCid";
    public static RecentVehicleText = "RecentVehicleText";
    public static FineTuneVehicleText = "FineTuneVehicleText";
    public static ProfileVehicle = "ProfileVehicle";
    public static VehicleViewDisplay = "VehicleViewDisplay";
    public static TranslationTexts = "TranslationTexts";
    public static SendVehicleInfoToDms = "SendVehicleInfoToDms";
    public static VidaLog = "VidaLog";
    public static RefreshRecentVehicle = "RefreshRecentVehicle";
    public static ProfileVehicleWithoutVin = "ProfileVehicleWithoutVin";
    public static SendNeededData = "SendNeededData";
    public static UserSettings = "UserSettings";
}