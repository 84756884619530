import { FontIcon, Link } from '@fluentui/react';
import React from 'react'

export class WarrantyClaims extends React.Component<any, any>{

    constructor(props: any) {
        super(props);

    }

    render() {
        return (
            <div className="row p-2 mt-2 border mb-4">
                <div className='bg-light p-2 border'>
                    <h5 className='mb-0'><FontIcon iconName="Certificate" /><span className='ms-3'>Warranty Claims - </span>
                        <span>0</span></h5>
                </div>
                <div className='row p-2' >
                    <Link>Add</Link>
                </div>
            </div>
        )
    }
}