import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IDropdownStyles, Dropdown, IDropdownOption, Link, PrimaryButton, Spinner, SpinnerSize, Label } from "@fluentui/react";

import { Apis } from "vidaplanninglogic/lib/services/apis/Apis";
import { Services } from "vidaplanninglogic/lib/services/apis/Services";
import { setMessage, setLoading, setSelectedTab } from 'vidaplanninglogic/lib/reduxx/slices/commonSlice'
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice'
import { MessageType } from "vidaplanninglogic/lib/data/constants/MessageType";
import { IAllFilters, IEngine, ITransmission } from "vidaplanninglogic/lib/data/entities/dtos/Filters/IAllFilters";
import { ISearchVehicleResult } from "vidaplanninglogic/lib/data/entities/ISearchVehicleResult";
import { AxiosResponse } from "axios";
import { IModelYearResponse, IVehicleModelResponse } from "vidaplanninglogic/lib/data/entities/dtos/Filters/IPartnerGroupResponse";
import { IModelYearMap, IModelYearMapItem } from "vidaplanninglogic/lib/data/entities/dtos/Filters/IModelYearMap";
import { IFilter } from "vidaplanninglogic/lib/data/entities/dtos/Filters/IFilter";
import { IVehicleModel } from "vidaplanninglogic/lib/data/entities/dtos/Filters/IVehicleModel";
import { IEngineTran } from "vidaplanninglogic/lib/data/entities/dtos/Filters/IEngineTran";
import { IProfiledVehicle } from "vidaplanninglogic/lib/data/entities/IProfiledVehicle";
import { VidaLogTypes } from "../../../common/VidaLogTypes";
import { RootState } from "vidaplanninglogic/lib/reduxx/store";

interface IDropdownItem {
    key: number;
    text: string;
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
    },
    dropdownItem: {
        fontSize: 13
    },
    root: {
    }
};

export class SearchVehicsTabClass extends Component<props, state> {
    constructor(props: any) {
        super(props);

        this.state = {
            vin: '',
            chassis: '',
            licensePlate: '',
            workOrder: '',
            customerId: '',
            firstName: '',
            lastName: '',

            defaultPartnerGroup: 0,

            partnerGroupOption: [],
            vehicleModelOptions: [],
            vehicleModel: [],
            modelYears: [],
            modelYearMaps: [],
            mappedYears: [],

            transmissions: [],
            transmissionOptions: [],
            engines: [],
            engineOptions: [],
            steerings: [],
            specialVehicles: [],
            bodyStyles: [],
            engineTranMap: [],

            searchResultList: [],

            selectedModel: 0,
            selectedYear: 0,
            selectedPartnerGroup: 0,
            selectedEngine: 0,
            selectedTransmission: 0,
            selectedSteering: 0,
            selectedSpecialVehicle: 0,
            selectedBodyStyle: 0,
        }
    }

    chassisInputRef = React.createRef<HTMLInputElement>();

    componentDidMount() {

        console.log("Component Did Mount")
        this.props.setLoading(true);
        //this.setState({ loading: true });
        this.populateData().then(() => {
            this.props.setLoading(false);
        }).catch(error => {
            console.log(error)
            this.props.setLoading(false);
            this.props.setMessage({ messageText: 'Filters Data could not be loaded!', messageType: MessageType.error });
        });
    }

    isStringEmptyOrUndefined(input: string): boolean {
        return input == 'undefined' || input == '';
    }

    setVinFromParrent = (vin: string, chassis: string, partnerGroupId: number) => {
        this.setState({
            vin,
            chassis,
            selectedPartnerGroup: partnerGroupId,
        }, this.sendRequest);
    }

    async populateData() {

        let partnerGroups = await Apis.AllFilters.PartnerGroupList();
        let defaultPartnerGroup = partnerGroups?.data?.partnerGrpitem?.find((item) => item.cid.toString() == (this.props.notifyVidaRecentVehicle ? this.props.partnerGroupCid! : this.props.userPartnerGroup?.cid!))?.id!;
        const partnerGroupOption: IDropdownItem[] = partnerGroups.data?.partnerGrpitem.map((x) => { return { key: x.id, text: x.description }; });
        this.setState({ partnerGroupOption, defaultPartnerGroup, selectedPartnerGroup: defaultPartnerGroup })

        var results = await Promise.all([
            Apis.AllFilters.ModelYearList(defaultPartnerGroup),
            Apis.AllFilters.VehicleModelList(defaultPartnerGroup, 'VCC,POLESTAR'),
            Apis.AllFilters.ModelYearMapList(defaultPartnerGroup)
        ]);
        const vehicleModelsOptions: IDropdownItem[] =
            results[1].data?.vehModelItem.map((x) => { return { key: x.id, text: x.description }; });
        this.setState({
            vehicleModelOptions: vehicleModelsOptions,
            modelYears: results[0].data?.modelYearItem,
            vehicleModel: results[1].data?.vehModelItem,
            modelYearMaps: results[2].data?.validMapping,
        })
        console.log("TTTT");
        console.log(this.props.partnerGroupCid);
        console.log(this.props.translationTexts);
    }

    handleVinChange = (ev: any) => {
        if (ev.target.value.length > 11) {
            this.setState({
                vin: ev.target.value.substring(0, 11).toUpperCase(),
                chassis: ev.target.value.substring(11, 17).toUpperCase(),
            }, this.sendRequest);
            this.chassisInputRef.current?.focus()
        }
        else {
            this.setState({ vin: ev.target.value.toUpperCase() }
                , this.sendRequest);
        }
    }

    handleChassisChange = (ev: any) => {
        this.setState({ chassis: ev.target.value.toUpperCase() }
            , this.sendRequest);
    }

    clearSearch = () => {
        this.setState({
            allFilters: undefined,
            vin: '',
            chassis: '',
            licensePlate: '',
            workOrder: '',
            customerId: '',
            firstName: '',
            lastName: '',

            searchResultList: [],
            selectedModel: 0,
            selectedYear: 0,
            selectedPartnerGroup: this.state.defaultPartnerGroup,
            selectedEngine: 0,
            selectedTransmission: 0,
            selectedSteering: 0,
            selectedSpecialVehicle: 0,
        });
        if (this.props.clearSelectedRecentVehicle)
            this.props.clearSelectedRecentVehicle();
    }
    searchingDms = async () => {
        if (this.state.firstName.length > 0 ||
            this.state.lastName.length > 0 ||
            this.state.licensePlate.length > 0 ||
            this.state.workOrder.length > 0 ||
            this.state.customerId.length > 0) {
            this.props.setLoading(true);
            try {
                let promiseArray: Promise<ISearchVehicleResult[]>[] = [];
                if (this.state.firstName.length > 0 || this.state.lastName.length > 0)
                    promiseArray.push(Apis.DMSApi.searchVehicleByCustomerName(this.state.firstName, this.state.lastName));
                if (this.state.licensePlate.length > 0)
                    promiseArray.push(Apis.DMSApi.searchVehicleByLicenseNumber(this.state.licensePlate));
                if (this.state.workOrder.length > 0)
                    promiseArray.push(Apis.DMSApi.searchVehicleByWorkOrderId(this.state.workOrder))
                if (this.state.customerId.length > 0)
                    promiseArray.push(Apis.DMSApi.searchVehicleByCustomerId(this.state.customerId))
                let results = await Promise.all(promiseArray)

                console.log(results)
                let finalList: ISearchVehicleResult[] = [];

                results[0].forEach(item => {
                    let isCommon = true;
                    for (let index = 1; index < results.length; index++) {
                        let exists = results[index].some(z => z.vin == item.vin)
                        if (!exists) {
                            isCommon = false;
                            break;
                        }
                    }
                    if (isCommon)
                        finalList.push(item)
                });

                this.setState({ searchResultList: finalList })
                if (finalList.length == 1) {
                    this.setState({
                        vin: finalList[0].vin.substring(0, 11),
                        chassis: finalList[0].vin.substring(11, 17),
                    }, this.sendRequest)
                }

                this.props.setLoading(false);
                this.props.setMessage({ messageText: '', messageType: MessageType.error });
            } catch (error) {
                console.log(error);
                this.props.setMessage({ messageText: 'Searching with DMS has problem!', messageType: MessageType.error });
                this.props.setLoading(false);
            }
        }
    }

    sendRequest = async () => {
        if (this.state.vin.length !== 11 || this.state.chassis.length !== 6) return;
        this.searchByVin(this.state.vin + this.state.chassis)
    }

    searchByVin = async (vin: string) => {
        this.props.setLoading(true);
        try {
            let allFilters = (await Apis.ProfileVehicle.ProfileVehicles(vin, this.state.selectedPartnerGroup, 0, 0)).data;

            let selectedModelValidYears = this.state.modelYearMaps.filter(x => x.modelId == allFilters?.vehicleModel?.id);
            let mappedYears: IDropdownItem[] = selectedModelValidYears.map(m => {
                let year = this.state.modelYears.find(x => x.id == m.yearId);
                return {
                    key: year?.id!,
                    text: year?.description!
                }
            });
            console.log('before findVehicleDetailsByVin');

            let engines = allFilters?.otherAttributes?.engineList?.engine;
            let engineOptions = allFilters?.otherAttributes?.engineList?.engine.map(x => { return { key: x.id, text: x.description } });
            let steerings = allFilters?.otherAttributes?.steeringList?.steering.map((x) => { return { key: x.id, text: x.description }; });
            let specialVehicles = allFilters?.otherAttributes?.specialVehicleList?.specialVehicle.map((x) => { return { key: x.id, text: x.description }; });
            let bodyStyles = allFilters?.otherAttributes?.bodyStyleList?.bodyStyle.map((x) => { return { key: x.id, text: x.description }; });
            let transmissionOptions = allFilters?.otherAttributes?.transmissionList?.transmission.map((x) => { return { key: x.id, text: x.description }; });
            let engineTranMap = allFilters?.otherAttributes?.validEngineTranMap.engineTran;
            this.setState({
                mappedYears,
                engineTranMap,
                engines,
                engineOptions,
                steerings,
                specialVehicles,
                bodyStyles,
                transmissionOptions,
                allFilters,
                selectedModel: allFilters?.vehicleModel?.id,
                selectedYear: allFilters?.modelYear?.id,
                selectedPartnerGroup: allFilters?.partnerGroup?.id,
                selectedEngine: allFilters?.otherAttributes?.engineList?.engine[0]?.id,
                selectedTransmission: allFilters?.otherAttributes?.transmissionList?.transmission[0]?.id,
                selectedSteering: allFilters?.otherAttributes?.steeringList?.steering[0]?.id,
                selectedSpecialVehicle: allFilters?.otherAttributes?.specialVehicleList?.specialVehicle[0]?.id,
                selectedBodyStyle: allFilters?.otherAttributes?.bodyStyleList?.bodyStyle[0]?.id,

            })
            this.props.setLoading(false);
            this.props.setMessage({ messageText: '', messageType: MessageType.error });
        } catch (error) {
            console.log(error);
            this.props.setLoading(false);
            this.props.setMessage({ messageText: 'VIDA can not decode the VIN!', messageType: MessageType.error });
        }
    }

    profileVehicleWithoutVin(): void {
        if (this.props.notifyProfileVehicleWithoutVinToVida) {
            this.props.notifyProfileVehicleWithoutVinToVida(this.state.selectedModel,
                this.state.selectedYear,
                this.state.selectedPartnerGroup,
                this.state.selectedEngine,
                this.state.selectedTransmission,
                this.state.selectedSteering,
                this.state.selectedSpecialVehicle,
                this.state.selectedBodyStyle);
        }
    }

    profileVehicle(vehicle: IProfiledVehicle): boolean {

        if (this.props.notifyVidaRecentVehicle) {
            this.props.notifyVidaRecentVehicle(vehicle.vin, vehicle.partnerGroupId);
            return true;
        }

        if (this.props.profiledVehicles.some(x => x.vin == vehicle.vin)) {
            this.props.setMessage({ messageText: 'You already profiled this Vehicle!', messageType: MessageType.warning });
            return false;
        }
        if (this.props.profiledVehicles.length == 5) {
            this.props.setMessage({ messageText: 'You already profiled 5 Vehicle, Please remove some of them!', messageType: MessageType.warning });
            return false;
        }

        this.props.addProfiledVehicleWithData(vehicle);
        this.props.setSelectedTab(vehicle.vin)
        return true;
    }

    profileDecodedVehicle = async () => {
        if (this.state.vin != '') {
            let selectedVehicle = await Services.vehicleProfileServices.GetProfiledVehicleByVin(this.state.vin + this.state.chassis, this.state.selectedPartnerGroup);
            this.profileVehicle(selectedVehicle);
            return;
        }
        this.profileVehicleWithoutVin();
    }

    profileDmsSearchResult = async (vin: string) => {
        try {
            this.props.setLoading(true);
            let selectedVehicle = await Services.vehicleProfileServices.GetProfiledVehicleByVin(vin, this.state.selectedPartnerGroup);

            let result = this.profileVehicle(selectedVehicle)
            this.props.setLoading(false);
            if (result == true) {
                this.props.setMessage({ messageText: '', messageType: MessageType.info });
            }

        } catch (error) {
            console.log(error);
            this.props.setLoading(false);
            this.props.setMessage({ messageText: 'VIDA can not decode the VIN!', messageType: MessageType.error });
        }
    }

    onPartnerGroupChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<IDropdownItem>) => {
        this.setState({
            selectedPartnerGroup: Number(option?.key!),

            mappedYears: [],
            transmissionOptions: [],
            engineOptions: [],
            steerings: [],
            specialVehicles: [],
            bodyStyles: [],

            selectedModel: 0,
            selectedYear: 0,
            selectedEngine: 0,
            selectedTransmission: 0,
            selectedSteering: 0,
            selectedSpecialVehicle: 0,
            selectedBodyStyle: 0,
        })
    };

    onVehicleModelChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<IDropdownItem>) => {
        let selectedModelValidYears = this.state.modelYearMaps.filter(x => x.modelId == option?.key!);
        let mappedYears: IDropdownItem[] = selectedModelValidYears.map(m => {
            let year = this.state.modelYears.find(x => x.id == m.yearId);
            return {
                key: year?.id!,
                text: year?.description!
            }
        });
        this.setState({
            selectedModel: Number(option?.key!),

            mappedYears,

            transmissionOptions: [],
            engineOptions: [],
            steerings: [],
            specialVehicles: [],
            bodyStyles: [],

            selectedYear: mappedYears.length == 1 ? mappedYears[0].key : 0,
            selectedEngine: 0,
            selectedTransmission: 0,
            selectedSteering: 0,
            selectedSpecialVehicle: 0,
            selectedBodyStyle: 0,
        })
    };

    onModelYearChange = async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<IDropdownItem>) => {

        if (option == undefined) {
            return;
        }
        try {
            this.props.setLoading(true);
            this.props.setMessage({ messageText: '', messageType: MessageType.info });

            let vehicleProfileResponse = await Apis.ProfileVehicle.ProfileVehicles(
                '',
                this.state.selectedPartnerGroup,
                this.state.selectedModel,
                Number(option?.key!))

            let engineTranMap = vehicleProfileResponse.data?.otherAttributes?.validEngineTranMap?.engineTran;
            let transmissions = vehicleProfileResponse.data?.otherAttributes?.transmissionList?.transmission;
            let transmissionOptions = vehicleProfileResponse.data?.otherAttributes?.transmissionList?.transmission.map((x) => { return { key: x.id, text: x.description }; });
            let engines = vehicleProfileResponse.data?.otherAttributes?.engineList?.engine;
            let engineOptions = vehicleProfileResponse.data?.otherAttributes?.engineList?.engine.map((x) => { return { key: x.id, text: x.description }; });
            let steerings = vehicleProfileResponse.data?.otherAttributes?.steeringList?.steering.map((x) => { return { key: x.id, text: x.description }; });
            let specialVehicles = vehicleProfileResponse.data?.otherAttributes?.specialVehicleList?.specialVehicle.map((x) => { return { key: x.id, text: x.description }; });
            let bodyStyles = vehicleProfileResponse.data?.otherAttributes?.bodyStyleList?.bodyStyle.map((x) => { return { key: x.id, text: x.description }; });


            this.setState({
                selectedYear: Number(option?.key!),
                engines,
                engineOptions,
                transmissions,
                transmissionOptions,
                steerings,
                specialVehicles,
                bodyStyles,
                engineTranMap,

                selectedEngine: engineOptions.length == 1 ? engineOptions[0].key : 0,
                selectedTransmission: transmissionOptions.length == 1 ? transmissionOptions[0].key : 0,
                selectedSteering: steerings.length == 1 ? steerings[0].key : 0,
                selectedSpecialVehicle: specialVehicles.length == 1 ? specialVehicles[0].key : 0,
                selectedBodyStyle: bodyStyles.length == 1 ? bodyStyles[0].key : 0,
            })
            this.props.setLoading(false);
            this.props.setMessage({ messageText: '', messageType: MessageType.info });
        } catch (error) {
            console.log(error);
            this.props.setLoading(false);
            this.props.setMessage({ messageText: 'Can not load the filter attributes!', messageType: MessageType.error });
        }
    };

    onEngineChange = async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<IDropdownItem>) => {

        if (option?.key! == 0) {
            this.setState({
                selectedTransmission: null,
                selectedEngine: null,
                transmissionOptions: this.state.transmissions.map(x => { return { key: x.id, text: x.description } }),
                engineOptions: this.state.engines.map(x => { return { key: x.id, text: x.description } }),
            })
            return;
        }
        let engineTranMap = this.state.engineTranMap.filter(x => x.engineId == option?.key!);
        let transmissionOptions: IDropdownItem[] = this.state.transmissions
            .filter(x => engineTranMap.some(et => et.transmissionId == x.id))
            .map(x => { return { key: x.id, text: x.description }; })
        if (transmissionOptions[0].key != 0)
            transmissionOptions = [{ key: 0, text: ' ' }, ...transmissionOptions]

        let engineOptions = this.state.engineOptions[0].key != 0 ?
            [{ key: 0, text: ' ' }, ...this.state.engineOptions] :
            [...this.state.engineOptions]

        this.setState({
            selectedEngine: Number(option?.key!),
            engineOptions,
            transmissionOptions,
            selectedTransmission: transmissionOptions.length == 2 ? transmissionOptions[1].key : 0,
        })
    };

    onTransmissionChange = async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<IDropdownItem>) => {
        if (option?.key! == 0) {
            this.setState({
                selectedTransmission: null,
                selectedEngine: this.state.selectedEngine == 0 ? null : this.state.selectedEngine,
                transmissionOptions: this.state.transmissions.map(x => { return { key: x.id, text: x.description } }),
                engineOptions: this.state.selectedEngine == 0 ? this.state.engines.map(x => { return { key: x.id, text: x.description } }) : this.state.engineOptions,
            })
            return;
        }
        let engineTranMap = this.state.engineTranMap.filter(x => x.transmissionId == option?.key!);
        let engineOptions: IDropdownItem[] = this.state.engines
            .filter(x => engineTranMap.some(et => et.engineId == x.id))
            .map(x => { return { key: x.id, text: x.description }; })
        console.log('engineOptions');
        console.log(engineOptions);
        if (engineOptions[0].key != 0)
            engineOptions = [{ key: 0, text: ' ' }, ...engineOptions]

        let transmissionOptions = this.state.transmissionOptions[0].key != 0 ?
            [{ key: 0, text: ' ' }, ...this.state.transmissionOptions] :
            [...this.state.transmissionOptions]


        this.setState({
            selectedTransmission: Number(option?.key!),
            engineOptions,
            transmissionOptions,
            selectedEngine: engineOptions.length == 2 ? engineOptions[1].key : this.state.selectedEngine,
        })
    };

    imageSourceHandler = (): string => {
        if (this.state.allFilters == undefined) {
            if (this.state.selectedModel != 0) {
                let modleItem = this.state.vehicleModel.find(x => x.id == this.state.selectedModel);
                return modleItem?.imagePath!;
            }
        }
        return this.state.allFilters?.vehicleModel?.imagePath!
    }

    renderSearchedVehiclesTable() {

        return (
            <div className='row p-3'>
                <div>
                    <label className="form-label">Customer Details - {this.state.searchResultList.length} results found</label>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>VIN</th>
                            <th>Model</th>
                            <th>Year</th>
                            <th>License Plate</th>
                            <th>Customer Type</th>
                            <th>Source</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.searchResultList?.map((item) => (
                                <tr key={item.vin}>
                                    <td>{item.customerName}</td>
                                    <td>
                                        <Link>
                                            {item.vin}
                                        </Link>
                                    </td>
                                    <td>{item.vehicleModel}</td>
                                    <td>{item.modelYear}</td>
                                    <td>{item.licensePlateNumber}</td>
                                    <td>{item.customerType}</td>
                                    <td>{item.source}</td>
                                    <td><PrimaryButton text='Select' onClick={() => this.profileDmsSearchResult(item.vin)} /></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        let loading = this.props.Common.Loading && (
            <Spinner style={{ display: 'inline-block' }} size={SpinnerSize.small} />
        );
        return (
            <div>
                <div className="px-3 pt-3" >
                    {!this.props.isRecentVehicle ?
                        <h4>Search Customer Vehicle Profile in DMS</h4>
                        : null}
                    <div>{!this.props.isRecentVehicle ?
                        <div className="row px-3 pb-3 border-bottom">
                            <div className='col-6'>
                                <div className='row mt-3'>
                                    <div className="row">
                                        <div className="col-4">
                                            <label htmlFor="formGroupExampleInput" className="form-label">License Plate:</label>
                                            <input type="text" className="form-control" id="formGroupExampleInput"
                                                value={this.state.licensePlate}
                                                onChange={(x) => this.setState({ licensePlate: x.target.value })} />
                                        </div>
                                        <div className="col-4">
                                            <label htmlFor="formGroupExampleInput2" className="form-label">Work Order:</label>
                                            <input type="text" className="form-control" id="formGroupExampleInput2"
                                                value={this.state.workOrder}
                                                onChange={(x) => this.setState({ workOrder: x.target.value })} />
                                        </div>
                                        <div className="col-4">
                                            <label htmlFor="formGroupExampleInput2" className="form-label">Customer ID:</label>
                                            <input type="text" className="form-control" id="formGroupExampleInput2"
                                                value={this.state.customerId}
                                                onChange={(x) => this.setState({ customerId: x.target.value })} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <label htmlFor="formGroupExampleInput" className="form-label">First Name:</label>
                                            <input type="text" className="form-control" id="formGroupExampleInput"
                                                value={this.state.firstName}
                                                onChange={(x) => this.setState({ firstName: x.target.value })} />
                                        </div>
                                        <div className="col-4">
                                            <label htmlFor="formGroupExampleInput2" className="form-label">Last Name:</label>
                                            <input type="text" className="form-control" id="formGroupExampleInput2"
                                                value={this.state.lastName}
                                                onChange={(x) => this.setState({ lastName: x.target.value })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <img src={this.imageSourceHandler()} />
                            </div>
                        </div>
                        : null
                    }
                        <div className="row p-1 border-bottom">
                            <div className="row">
                                <div style={{width: 250}}>
                                    <label htmlFor="formGroupExampleInput" className="form-label">{this.props.translationTexts["vida.lbl.vin"]}:</label>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <input type="text" className="form-control" style={{ fontSize: 13, height: 32 }} name='vin' onChange={this.handleVinChange} value={this.state.vin} />
                                        </div>
                                        <div className='col-5 p-0'>
                                            <input type="text" ref={this.chassisInputRef} className="form-control" style={{ fontSize: 13, height: 32 }} name='chassis' onChange={this.handleChassisChange} value={this.state.chassis} placeholder={this.props.translationTexts["vida.txt.chassisno"]} maxLength={6} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col-2">
                                    <Label>
                                        <span>{this.props.translationTexts["vida.lbl.model"] + ":"}</span>
                                        <span className="mx-1" style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Dropdown
                                        options={this.state.vehicleModelOptions}
                                        selectedKey={this.state.selectedModel}
                                        disabled={!this.isStringEmptyOrUndefined(this.state.vin)}
                                        onChange={this.onVehicleModelChange}
                                        styles={dropdownStyles}
                                        className='dropdown-sm'
                                    />
                                </div>
                                <div className="col-2">
                                    <Label>
                                        <span>{this.props.translationTexts["vida.lbl.year"] + ":"}</span>
                                        <span className="mx-1" style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Dropdown
                                        options={this.state.mappedYears}
                                        selectedKey={this.state.selectedYear}
                                        onChange={this.onModelYearChange}
                                        disabled={!this.isStringEmptyOrUndefined(this.state.vin) || this.state.selectedModel == 0}
                                        styles={dropdownStyles}
                                    />
                                </div>
                                <div className="col-2">
                                </div>
                                <div className="col-4">
                                    <Label>
                                        <span>{this.props.translationTexts["vida.lbl.partnergroup"] + ":"}</span>
                                        <span className="mx-1" style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Dropdown
                                        options={this.state.partnerGroupOption}
                                        selectedKey={this.state.selectedPartnerGroup}
                                        onChange={this.onPartnerGroupChange}
                                        styles={dropdownStyles}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <Dropdown
                                        label={this.props.translationTexts["vida.lbl.engine"] + ":"}
                                        options={this.state.engineOptions}
                                        selectedKey={this.state.selectedEngine}
                                        onChange={this.onEngineChange}
                                        disabled={this.state.selectedYear == 0}
                                        styles={dropdownStyles}
                                    />
                                </div>
                                <div className="col-2">
                                    <Dropdown
                                        label={this.props.translationTexts["vida.lbl.transmission"] + ":"}
                                        options={this.state.transmissionOptions}
                                        selectedKey={this.state.selectedTransmission}
                                        onChange={this.onTransmissionChange}
                                        disabled={this.state.selectedYear == 0}
                                        styles={dropdownStyles} />
                                </div>
                                <div className="col-2">
                                    <Dropdown
                                        label={this.props.translationTexts["vida.lbl.steering"] + ":"}
                                        options={this.state.steerings}
                                        selectedKey={this.state.selectedSteering}
                                        onChange={(event, option) => {
                                            this.setState({ selectedSteering: Number(option?.key!) })
                                        }}
                                        disabled={!(this.state.selectedYear != 0 && this.state.steerings.length >= 1)}
                                        styles={dropdownStyles}
                                    />
                                </div>
                                <div className="col-2">
                                    <Dropdown
                                        label={this.props.translationTexts["vida.lbl.bodystyle"] + ":"}
                                        options={this.state.bodyStyles}
                                        selectedKey={this.state.selectedBodyStyle}
                                        onChange={(event, option) => {
                                            this.setState({ selectedBodyStyle: Number(option?.key!) })
                                        }}
                                        disabled={!(this.state.selectedYear != 0 && this.state.bodyStyles?.length >= 1)}
                                        styles={dropdownStyles}
                                    />
                                </div>
                                <div className="col-2">
                                    <Dropdown
                                        label={this.props.translationTexts["vida.lbl.specialvehicle"] + ":"}
                                        options={this.state.specialVehicles}
                                        selectedKey={this.state.selectedSpecialVehicle}
                                        onChange={(event, option) => {
                                            this.setState({ selectedSpecialVehicle: Number(option?.key!) })
                                        }}
                                        disabled={!(this.state.selectedYear != 0 && this.state.specialVehicles?.length >= 1)}
                                        styles={dropdownStyles}
                                    />
                                </div>
                                {/* <div className="col-2 gap-2 d-grid" style={{ paddingTop: 30 }}>
                                    <button className="btn btn-secondary btn-sm" id="formGroupExampleInput" disabled={this.state.selectedYear == 0} onClick={() => this.profileDecodedVehicle()}
                                        style={{ maxWidth: 150, maxHeight: 32, fontSize: 13 }}>{this.props.translationTexts["vida.btn.select"]}</button>
                                </div> */}
                            </div>

                            <div className="row pt-1 px-3">
                                {!this.props.isRecentVehicle ?
                                    <div className="col-1 p-1 gap-2 d-grid">
                                        <button onClick={this.searchingDms} type="button" className="btn btn-primary btn-sm"
                                            style={{ minWidth: 100, maxHeight: 32 }}>Search {loading}</button>
                                    </div>
                                    : null}
                                <div className="col-1 p-1 mx-1 d-grid">
                                    <button onClick={this.clearSearch} type="button" className="btn btn-outline-primary btn-sm" id="formGroupExampleInput"
                                        style={{ minWidth: 100, maxHeight: 32, fontSize: 13 }}>{this.props.translationTexts["vidal.lbl.clearall"]}</button>
                                </div>
                                <div className="col-3 p-1 mx-4 d-grid ">
                                    <button className="btn btn-outline-primary btn-sm" id="formGroupExampleInput" disabled={this.state.selectedYear == 0} onClick={() => this.profileDecodedVehicle()}
                                        style={{ maxWidth: 150, maxHeight: 32, fontSize: 13 }}>{this.props.translationTexts["vida.btn.select"]}</button>
                                </div>
                            </div>
                            {
                                (this.state.searchResultList.length > 0) && (this.renderSearchedVehiclesTable())
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

interface props extends PropsFromRedux {
    isRecentVehicle?: boolean,
    partnerGroupCid?: string,
    vin?: string,
    chassis?: string,
    partnerGroupId?: number,
    translationTexts?: any,
    clearSelectedRecentVehicle?(): void,
    notifyVidaRecentVehicle?(vin: string, partnerGroupId: number): void,
    notifySendVehicleInfoToDms?(vin: string, partnerGroupId: number): void,
    notifyWebLogstoVida?(logType: VidaLogTypes, message: string): void,
    notifyProfileVehicleWithoutVinToVida?(selectedModel: number,
        selectedYear: number,
        selectedPartnerGroup: number,
        selectedEngine: number | null,
        selectedTransmission: number | null,
        selectedSteering: number,
        selectedSpecialVehicle: number,
        selectedBodyStyle: number): void
}

interface state {

    vin: string,
    chassis: string,
    licensePlate: string,
    workOrder: string,
    customerId: string,
    firstName: string,
    lastName: string,
    partnerGroupOption: IDropdownItem[],
    vehicleModelOptions: IDropdownItem[],
    vehicleModel: IVehicleModel[],
    mappedYears: IDropdownItem[],
    modelYears: IFilter[],
    modelYearMaps: IModelYearMapItem[],
    transmissions: ITransmission[],
    engines: IEngine[],

    transmissionOptions: IDropdownItem[],
    engineOptions: IDropdownItem[],
    steerings: IDropdownItem[],
    specialVehicles: IDropdownItem[],
    bodyStyles: IDropdownItem[],
    engineTranMap: IEngineTran[],

    defaultPartnerGroup: number,
    allFilters?: IAllFilters,
    searchResultList: ISearchVehicleResult[],

    selectedModel: number,
    selectedYear: number,
    selectedPartnerGroup: number,
    selectedEngine: number | null,
    selectedTransmission: number | null,
    selectedSteering: number,
    selectedSpecialVehicle: number,
    selectedBodyStyle: number,
}

const mapStateToProps = (store: RootState) => ({
    profiledVehicles: store.profiledVehicles.Vehicles,
    userPartnerGroup: store.subscription.Subscription?.organization?.partnerGroup,
    Common: store.common,
})
const mapDispatchToProps = {
    addProfiledVehicleWithData: profiledVehiclesActions.addProfiledVehicleWithData,
    setLoading,
    setMessage,
    setSelectedTab
}
const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;
export const SearchVehiclesTab = connector(SearchVehicsTabClass);