import * as React from 'react';
import { Nav, INavStyles, INavLinkGroup } from '@fluentui/react/lib/Nav';

const navStyles: Partial<INavStyles> = { root: { width: 250 } };

const navLinkGroups: INavLinkGroup[] = [
  {
    name: 'Planning',
    expandAriaLabel: 'Expand Basic components section',
    collapseAriaLabel: 'Collapse Basic components section',
    links: [
      {
        key: 'ActivityItemList',
        name: 'List',
        url: '#/examples/activityitem',
      },
      {
        key: 'ActivityItem',
        name: 'Customer Symptom Codes',
        url: '#/examples/activityitem',
      },
      {
        key: 'Breadcrumb',
        name: 'Claim Types',
        url: '#/examples/breadcrumb',
      },
      {
        key: 'Button',
        name: 'Technical Journals',
        url: '#/examples/button',
      },
      {
        key: 'Button3',
        name: 'Operations and Packages',
        url: '#/examples/button',
      },
      {
        key: 'Button4',
        name: 'Parts',
        url: '#/examples/button',
      },
      {
        key: 'Button5',
        name: 'Software',
        url: '#/examples/button',
      },
    ],
  },
  // {
  //   name: 'Diagnostics',
  //   expandAriaLabel: 'Expand Extended components section',
  //   collapseAriaLabel: 'Collapse Extended components section',
  //   links: [
  //     {
  //       key: 'ColorPicker',
  //       name: 'Components',
  //       url: '#/examples/colorpicker',
  //     },
  //     {
  //       key: 'ExtendedPeoplePicker',
  //       name: 'Service Functions',
  //       url: '#/examples/extendedpeoplepicker',
  //     },
  //   ],
  // },
];

export const PlanningNavMenu: React.FunctionComponent = () => {
  return (
    <Nav styles={navStyles} selectedKey="ActivityItemList" ariaLabel="Nav example similar to one found in this demo page" groups={navLinkGroups} />
  );
};
