import { FontIcon, IconButton } from '@fluentui/react';
import { forwardRef, useState } from 'react';
import { Book, Medical } from "../../common/IconProps";

import { IVehicleContext } from 'vidaplanninglogic/lib/data/entities/dtos/IVehicleContext';
// import './SuggestionRow.css'
import { IPartSearchResult } from 'vidaplanninglogic/lib/data/entities/dtos/IPartSearchResult';
import { ICsc } from 'vidaplanninglogic/lib/data/entities/dtos/ICsc';

function SuggestionRow(props: props) {
    return (
        <div className='bg-white'>
            <div className='row p-1 text-nowrap text-start hover-item' style={{ width: 1100 }}
                onClick={(e) => {
                    props.addSelectedCsc(props.csc)
                    e.stopPropagation();
                }}
            >
                <div className='col-1'>{props.csc.code}</div>
                <div className='col-2'>{props.csc.componentFunction.desc}</div>
                <div className='col-7'>
                    <span>
                        {props.csc.deviation}
                    </span>
                    <span className='text-muted'> - </span>
                    <span className='text-muted'>
                        {props.csc.condition1}
                    </span>
                </div>
                <div className='col-1'>
                    {
                        props.csc.isFaultTracingAvailable == true && 
                        (<IconButton styles={{ root: [{ color: "orange" }] }} iconProps={Medical} />)
                    }
                    {props.csc.isInformationVerificationAvailable == true && (
                        <IconButton styles={{ root: [{ color: "orange" }] }} iconProps={Book} />
                    )}
                </div>
            </div>
        </div>
    )
}

interface props {
    csc: ICsc;
    vehicleContext: IVehicleContext;
    addSelectedCsc(csc: ICsc): void
}

export default SuggestionRow