import React, { Component } from "react";
import { Pivot, PivotItem } from '@fluentui/react';
import { ShimmerComponent } from "../../common/Simmer";
import { RecentVehiclesTab } from "./RecentVehiclesTab";
import { SearchVehiclesTab } from "./SearchVehiclesTab";
import { MyListTab } from "./MyListTab";

interface state {
    translationTexts: any
}

interface props {

}

export default class extends Component<props, state>{

    constructor(props: props) {
        super(props);
        this.state = {
            translationTexts: {}
        }
    }

    componentDidMount() {
        this.defaultTranslation();
    }

    defaultTranslation = () => {
        let texts = {
            "lblCustomerType": "Customer type",
            "vida.btn.search": "Search",
            "vida.btn.select": "Select",
            "vida.btn.sendvehicleinfotodms": "Send Vehicle Info to XDMS1",
            "vida.lbl.SeeVehicleDetails": "See Vehicle Details",
            "vida.lbl.bodystyle": "Body Style",
            "vida.lbl.customername": "Customer Name",
            "vida.lbl.engine": "Engine",
            "vida.lbl.finetunevehicle": "Fine-tune Vehicle",
            "vida.lbl.licenseplate": "License Plate",
            "vida.lbl.model": "Model",
            "vida.lbl.partnergroup": "Partner Group",
            "vida.lbl.recentvehicletitle": "Recently Identified Vehicles",
            "vida.lbl.specialvehicle": "Special Vehicle",
            "vida.lbl.steering": "Steering",
            "vida.lbl.transmission": "Transmission",
            "vida.lbl.vin": "VIN",
            "vida.lbl.vindecodingisoff": "VIN Decoding is Off",
            "vida.lbl.year": "Year",
            "vida.tbl.row.btn.connect": "Connect",
            "vida.txt.chassisno": "Chassis No.",
            "vidal.lbl.clearall": "Clear All"
        }
        this.setState({ translationTexts: texts })
    }

    render() {
        return (
            <Pivot aria-label="Basic Pivot Example">
                <PivotItem
                    headerText="Search Vehicle"
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'My Files Title',
                    }}
                >
                    <SearchVehiclesTab translationTexts={this.state.translationTexts} />
                </PivotItem>
                <PivotItem headerText="Recent Vehicle">
                    <RecentVehiclesTab translationTexts={this.state.translationTexts} />
                </PivotItem>
                <PivotItem headerText="Connected Vehicles">
                    <ShimmerComponent />
                </PivotItem>
                <PivotItem headerText="My List">
                    <MyListTab />
                </PivotItem>
                <PivotItem headerText="Workshop Customers">
                    <ShimmerComponent />
                </PivotItem>
                <PivotItem headerText="Appointments Inbox">
                    <ShimmerComponent />
                </PivotItem>
            </Pivot>
        );
    }
}