import React from 'react';
import { LoginForm } from './LoginForm'
import './Login.css';
import { Nav } from '../common/Nav';
import background01 from "./1.jpg";
import background02 from "./2.jpg";
import background03 from "./3.jpg";
import background04 from "./4.jpg";
import background05 from "./5.jpg";

const backgroundImage = background();
interface props {

}

export const Login: React.FunctionComponent<props> = () => {
  return (
    <div className="App">
      <Nav />
      <header className="App-header" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="p-3 login-panel col-lg-4">
          <LoginForm />
        </div>
      </header>
    </div>
  );
}

function background() {
  let imageNumber = randomInteger(1, 5);
  switch (imageNumber) {
    case 1:
      return background01;
      break;
    case 2:
      return background02;
      break;
    case 3:
      return background03;
      break;
    case 4:
      return background04;
      break;
    case 5:
      return background05;
      break;

    default:
      return background01;
  }

}
function randomInteger(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}