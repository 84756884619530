import React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { Checkbox, DatePicker, Dropdown, FontIcon, FontWeights, getTheme, IButtonStyles, IconButton, IDropdown, IDropdownStyles, IStackProps, Link, mergeStyleSets, Modal, Toggle, TooltipHost } from '@fluentui/react';

import { Apis } from 'vidaplanninglogic/lib/services/apis/Apis';
import { IProfiledVehicle } from 'vidaplanninglogic/lib/data/entities/IProfiledVehicle';
import { IServicePlan } from 'vidaplanninglogic/lib/data/entities/dtos/IServicePlan';
import { IDropDownOptions } from 'vidaplanninglogic/lib/data/entities/IDropDownOptions';
import { IServiceOrderRequest } from 'vidaplanninglogic/lib/data/entities/dtos/IServiceOrderRequest';
import { IOperationViewModel } from "vidaplanninglogic/lib/data/entities/IOperationViewModel";
import { IServiceOrderActionRequest } from 'vidaplanninglogic/lib/data/entities/IServiceOrderActionRequest';
import { IServiceOrderVehicle } from 'vidaplanninglogic/lib/data/entities/dtos/IServiceOrderVehicle';
import { MessageType } from 'vidaplanninglogic/lib/data/constants/MessageType';
import { IAddServiceChecklistItem } from 'vidaplanninglogic/lib/data/entities/IAddServiceChecklistItemsRequest'


import * as Icons from '../../common/IconProps';
import { OperationRow } from '../OperationsPackages/OperationRow';
import { PackageRow } from '../OperationsPackages/PackageRow';
import { MapIOperationToIOperationViewModel } from 'vidaplanninglogic/lib/services/services/mapper/IOperationToIOperationViewModel';
import { IServiceOrderPrint, IServicePrintTaskListItem } from 'vidaplanninglogic/lib/data/entities/dtos/IServiceOrderPrint';
import { Services } from 'vidaplanninglogic/lib/services/apis/Services';
import { ContentType } from 'vidaplanninglogic/lib/data/entities/ContentType';
import { RootState } from 'vidaplanninglogic/lib/reduxx/store';
import { setLoading, setMessage } from 'vidaplanninglogic/lib/reduxx/slices/commonSlice';
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice';
import { DialogMessage } from '../../common/DialogMessage';
import TableFooter from './TableFooter';
import { actionCreators } from 'vidaplanninglogic/lib/services/services/worklist/actionCreators/actionCreators';
import moment from 'moment';

interface IServicePrintTaskListItemViewModel extends IServicePrintTaskListItem {
    technician: string;
    ok: boolean;
    rectified: boolean;
    remark: string | null;
    dataValues: any;
}

interface servicePlanOptions extends IDropDownOptions, IServicePlan {
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        //width: 300
    },
    root: {
        height: 50
    }
};

class ServiceProgramClass extends React.Component<props, state>{


    dropdownRef = React.createRef<IDropdown>();

    constructor(props: props) {
        super(props);

        let momentRegisterDate = moment(props.selectedVehicle.vehicleDetails.extendedVehicle?.registrationDate, 'YYYYMMDD', true);
        let registrationDate = momentRegisterDate.isValid() ? momentRegisterDate.toDate() : new Date();

        this.state = {
            showSearchTools: true,
            showServicePlanDropdown: false,
            showResultPanel: false,
            servicePlans: [],
            servicePlansDto: [],
            showServiceProgramDetails: true,

            taskList: [],
            checkListCategories: [],
            changedChecklistItems: [],

            selectedServicePlan: '',
            licensePlate: '',
            mileage: null,
            registrationDate,
            registrationDateReadOnly: momentRegisterDate.isValid(),
            serviceDate: moment().add(props.OffsetForDefaultServiceDate, 'days').toDate(),
            showCheckListModal: false,
            showMaxQuantityPopup: false,
        }
    }

    onSetFocus = () => this.dropdownRef.current!.focus(true);

    searchServicePlan = async () => {
        try {
            if (this.state.mileage == null) return;
            this.props.setLoading(true);

            let data: IServiceOrderVehicle = {
                vin: this.props.selectedVehicle.vin,
                vehicleModel: this.props.selectedVehicle?.allFilters?.vehicleModel,
                modelYear: this.props.selectedVehicle?.allFilters?.modelYear,
                engine: this.props.selectedVehicle?.allFilters?.otherAttributes?.engineList?.engine[0],
                transmission: this.props.selectedVehicle?.allFilters?.otherAttributes?.transmissionList?.transmission[0],
                licensePlate: this.state.licensePlate,
                mileage: this.state.mileage!.toString(),
                registrationDate: this.state.registrationDate,
                serviceDate: this.state.serviceDate,

                steering: this.props.selectedVehicle.allFilters?.otherAttributes?.steeringList?.steering[0],
                bodyStyle: this.props.selectedVehicle.allFilters?.otherAttributes?.bodyStyleList?.bodyStyle[0],
                profileId: this.props.selectedVehicle.vehicleContext?.selectedProfiles,
                profileDescription: null,
                partnerGroup: null,
                specialVehicle: null,
                extendedVehicle: null,
                chassisNumber: null,
                registrationNumber: null,
                result: null
            }

            let servicePlans = await Apis.ServicePlan.ServicePlansList("SE", data);
            const servicePlansOptions: servicePlanOptions[] = servicePlans?.data.map((x: IServicePlan) => {
                return { key: x.id, text: x.shortDescription + `    ` + x.description, ...x };
            });
            this.setState({
                servicePlans: servicePlansOptions,
                servicePlansDto: servicePlans?.data,
                showServicePlanDropdown: true
            }, () => {
                this.dropdownRef.current!.focus(true);
            })
        } catch (error) {
            this.props.setMessage({ messageText: 'Service Plan could not be loaded!', messageType: MessageType.error })
        } finally {
            this.props.setLoading(false);
        }
    }

    onRenderOption = (item: any) => {
        return (
            <table>
                <tbody>
                    <tr>
                        <td style={{ width: 250 }}>{item.shortDescription}</td>
                        <td style={{ width: '*' }}>
                            {item.description}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    onRenderTitle(item: any): JSX.Element | null {
        return (
            <div>{item[0].shortDescription}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item[0].description}</div>
        );
    }

    onSelectServicePlan = async (item: any): Promise<void> => {
        let data: IServiceOrderRequest = {
            servicePlan: this.state.servicePlansDto.find((x) => x.id == item.id)!,
            countryDetails: {
                useMiles: false,
                countryToken: null,
                countryCode: "SE"
            },
            siaLanguage: {
                id: 16,
                isServiceTaskTextEnabled: true
            },
            vehicleContext: this.props.selectedVehicle.vehicleContext,
            vehicle: {
                vin: this.props.selectedVehicle.vin,
                licensePlate: "",
                profileId: this.props.selectedVehicle.vehicleContext.selectedProfiles,
                profileDescription: '',
                partnerGroup: this.props.selectedVehicle.allFilters.partnerGroup,
                vehicleModel: this.props.selectedVehicle.allFilters.vehicleModel,
                modelYear: this.props.selectedVehicle.allFilters.modelYear,
                engine: this.props.selectedVehicle.allFilters.otherAttributes.engineList?.engine[0],
                transmission: this.props.selectedVehicle.allFilters.otherAttributes.transmissionList?.transmission[0],
                steering: this.props.selectedVehicle.allFilters.otherAttributes.steeringList?.steering[0],
                bodyStyle: this.props.selectedVehicle.allFilters.otherAttributes.bodyStyleList?.bodyStyle[0],
                specialVehicle: this.props.selectedVehicle.allFilters.otherAttributes.specialVehicleList?.specialVehicle?.[0],
                extendedVehicle: null,
                chassisNumber: null,
                registrationNumber: null,
                mileage: this.state.mileage!.toString(),
                registrationDate: this.state.registrationDate,
                serviceDate: this.state.serviceDate,
                result: null
            }
        }
        try {
            this.props.setLoading(true);
            let nextServiceProgramJobNumber = Services.WorklistServices.GetNextJobNumber(this.props.selectedVehicle.currentWorkList!, ContentType.ServiceProgram)
            let serviceOrderPayload = await Apis.ServiceOrder.OperationPackagesList("en-GB", data);

            console.log("serviceOrderPayload?.data?.operationList");
            console.log(serviceOrderPayload?.data?.operationList);


            let operations: IOperationViewModel[] = MapIOperationToIOperationViewModel(serviceOrderPayload?.data?.operationList);
            operations.forEach(x => {
                x.jobNumber = nextServiceProgramJobNumber
                x.package?.package?.forEach(pk => {
                    pk.packageNumber = `${pk.packageKey.mainOperationNumber}-${pk.packageKey.sequenceGroup}`
                    pk.jobNumber = nextServiceProgramJobNumber;
                    pk.quantity = pk.snoozed == false ? 1 : 0;
                    pk.includedOperations.operationitem[0].quantity = pk.includedOperations.operationitem[0].snoozed == false ? 1 : 0;
                    pk.parts.part.forEach(p => {
                        p.jobNumber = nextServiceProgramJobNumber
                        p.quantity = pk.snoozed == false ? 1 : 0
                    })
                })
                x.quantity = x.snoozed == false ? 1 : 0
                if (x.jobscopeDescription != undefined)
                    x.package!.package[0].includedOperations.operationitem[0].jobscopeDescription = x.jobscopeDescription;
            })

            await Apis.DMSApi.getServiceProgramDetails(operations, this.props.selectedVehicle);

            this.setState({
                selectedServicePlan: item?.key,
                showResultPanel: true
            })
            this.props.addServiceProgramOperations({
                serviceProgramOperations: operations,
                vin: this.props.selectedVehicle.vin
            })

            // let updated = await Apis.ServiceOrder.PutServicesOperations(serviceOrderPayload?.data.id, operations.filter(x => x.included));

            // let rowId = 0;
            // let _operations: IServiceOrderOperation[] = [];
            // let _packages: IServiceOrderPackages[] = [];
            // operations.forEach(op => {
            //     rowId++;
            //     let operationItem: IServiceOrderOperation = {
            //         rowId: rowId,
            //         quantity: op.serviceTaskList ? (!op.serviceTaskList!.some(p => p.isMileageDependend ?? true) == false ? 1 : 0) : 1,
            //         jobTime: Number(op.vst),
            //         document: op.documentHeader ? {
            //             chronicleId: op.documentHeader?.[0].chronicleId,
            //             id: op.documentHeader?.[0].id ?? null,
            //             title: op.documentHeader?.[0].title
            //         } : null,
            //         labourRate: op.labourCost,
            //         maxQuantity: op.maxquantity,
            //         operationCategory: op.mpCategory ?? MenuPricingCategory.MECHANICAL,
            //         jobScopeDescription: op.jobscopeDescription ? op.jobscopeDescription : null,
            //         variantGroupId: op.variantGroupId,
            //         skillLevel: op.skillLevel,
            //         remedyCode: op.remedycode,
            //         operationType: op.operationType,
            //         operationNumber: op.operationNumber,
            //         variantDescription: op.variantDescription,
            //         operationDescription: op.operationDescription,
            //         included: op.included,
            //         snooze: op.snoozed,
            //         // included: op.serviceTaskList ? op.serviceTaskList!.some(p => p.isMileageDependend) : false,
            //         // snooze: op.serviceTaskList ? !op.serviceTaskList!.some(p => p.isMileageDependend) : true,

            //         //Ignored Part of properties
            //         complete: false,
            //         jobNumber: op.jobNumber,
            //         relatedCscAvailable: false,
            //         notes: null,
            //         sentToDMS: false,
            //         multipleOperationAvailable: false,
            //         new: false,
            //         sequenceId: 0,
            //         tjDetails: null,
            //     }

            //     if (!op.hasPackage) {
            //         _operations.push(operationItem);
            //     }
            //     else {
            //         let itmPackage = op.package!.package?.[0];
            //         let parts: IServiceOrderParts[] = op.package!.package?.[0].parts.part.map((part) => {
            //             return {
            //                 rowId: rowId,
            //                 document: [],
            //                 fictive: part.isFictive,
            //                 jobNumber: part.jobNumber,
            //                 partDescription: part.partItem.description,
            //                 partNumber: part.partItem.partNumber,
            //                 quantity: op.serviceTaskList ? (!op.serviceTaskList!.some(p => p.isMileageDependend ?? true) == false ? 1 : 0) : 1,

            //                 notes: null,
            //                 new: false,
            //                 complete: false,
            //                 sentToDMS: false,
            //                 sequenceId: 0,
            //                 footNote: null,
            //                 software: false,
            //                 userComments: null,
            //                 vehicle: null
            //             }
            //         });
            //         let packageItem: IServiceOrderPackages = {
            //             packageItemNumber: "GGGGGGG",
            //             packageComments: op.package!.package[0].comment ?? null,
            //             packageDescription: itmPackage.includedOperations.operationitem[0].operationDescription,
            //             quantity: op.serviceTaskList ? (!op.serviceTaskList!.some(p => p.isMileageDependend ?? true) == false ? 1 : 0) : 1,
            //             included: op.serviceTaskList ? op.serviceTaskList!.some(p => p.isMileageDependend) : false,
            //             operationList: [operationItem],
            //             partList: parts,
            //             snooze: op.serviceTaskList ? !op.serviceTaskList!.some(p => p.isMileageDependend) : true,
            //             // multiplePackageAvailable: false,
            //             //Ignored part
            //             useFixedPrice: false,
            //             sentToDMS: false,
            //             sequenceId: 0,
            //             rowId: rowId,
            //             complete: false,
            //             jobNumber: op.jobNumber,
            //             notes: null,
            //             // new: false,
            //         }

            //         _packages.push(packageItem);
            //     }
            // })

            // console.log("_operations");
            // console.log(_operations);
            // console.log("_packages");
            // console.log(_packages);





            let spDataToSave: IServiceOrderActionRequest =
                actionCreators.AddServiceProgramAction(operations,
                    Number(this.state.mileage),
                    this.state.registrationDate,
                    this.state.serviceDate,
                    serviceOrderPayload?.data?.id.toString(),
                    data.servicePlan.shortDescription,
                    data.servicePlan.description
                );

            this.SaveEngineCall(spDataToSave);

            // this.props.addServiceProgram({
            //     serviceProgram: sp,
            //     vin: this.props.selectedVehicle.vin
            // })

            let isOperationExistInSP: boolean = false;
            this.props.selectedVehicle.currentWorkList!.Operations.forEach(op => {
                isOperationExistInSP = this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations
                    .filter(x => x.snoozed == false && x.operationNumber == op.operationNumber).length > 0;

                if (isOperationExistInSP) {
                    let action: IServiceOrderActionRequest = actionCreators.RemoveOperation(op.rowId);
                    this.SaveEngineCall(action);

                    // this.props.setLoading(true)
                    // Apis.ServiceOrder.RemoveServiceOrderItem(ActionsEnum.RemoveOperation, op.rowId, this.props.selectedVehicle.currentWorkList!.ServiceOrderId, this.props.selectedVehicle.currentWorkList!.Version)
                    //     .then((response) => {
                    //         this.props.updateVersion({ version: response.data.version, vin: this.props.selectedVehicle.vin });
                    //         console.log("op.rowId");

                    //         console.log(op.rowId);
                    //         console.log(this.props.removeOperation);

                    //         this.props.removeOperation!({
                    //             rowId: op.rowId,
                    //             vin: this.props.selectedVehicle.vin
                    //         });
                    //         this.props.setMessage({
                    //             messageText: `Operation ${op.operationNumber} Removed Successfully!`,
                    //             messageType: MessageType.success
                    //         });
                    //     })
                    //     .catch((error) => {
                    //         this.props.setMessage({
                    //             messageText: "Error occured in Remove Operation!",
                    //             messageType: MessageType.error
                    //         });
                    //     })
                    //     .finally(() => this.props.setLoading(false));
                }
            })

            if (isOperationExistInSP) {
                console.log("required operations have been removed!!!");
            }

            console.log("Remove Package");
            console.log(this.props.selectedVehicle.currentWorkList!);

            let isPackageExistInSP: boolean = false;
            this.props.selectedVehicle.currentWorkList!.Packages.forEach(pk => {
                isPackageExistInSP = this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations
                    .filter(x => x.package?.package?.[0].snoozed == false && x.package?.package?.[0].packageNumber == pk.packageNumber).length > 0;

                console.log("isPackageExistInSP: " + isPackageExistInSP);

                if (isPackageExistInSP) {
                    let action: IServiceOrderActionRequest = actionCreators.RemovePackage(pk.rowId);
                    this.SaveEngineCall(action);
                    this.props.removePackage!({ rowId: pk.rowId, vin: this.props.selectedVehicle.vin });

                    // this.props.setLoading(true)
                    // Apis.ServiceOrder.RemoveServiceOrderItem(ActionsEnum.RemovePackage, pk.rowId, this.props.selectedVehicle.currentWorkList!.ServiceOrderId, this.props.selectedVehicle.currentWorkList!.Version)
                    //     .then((response) => {
                    //         this.props.updateVersion({
                    //             version: response.data.version,
                    //             vin: this.props.selectedVehicle.vin
                    //         });
                    //         console.log("pk.rowId");

                    //         console.log(pk.rowId);
                    //         console.log(this.props.removePackage);

                    //         this.props.removePackage!({ rowId: pk.rowId, vin: this.props.selectedVehicle.vin });
                    //         this.props.setMessage({ messageText: `Package ${pk.packageNumber} Removed Successfully!`, messageType: MessageType.success });
                    //     })
                    //     .catch((error) => {
                    //         this.props.setMessage({ messageText: "Error occured in Remove Package!", messageType: MessageType.error });
                    //     })
                    //     .finally(() => this.props.setLoading(false));
                }
            })

            if (isPackageExistInSP) {
                console.log("required package have been removed!!!");
            }

            this.setState({ showMaxQuantityPopup: isOperationExistInSP || isPackageExistInSP })

            console.log("Service program added");
            console.log(this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations);
        } catch (error) {
            console.log(error)
            this.props.setMessage({ messageText: 'Can not load the Service Program!', messageType: MessageType.error })
        } finally {
            this.props.setLoading(false)
        }
    }

    calculateTotalUnitCost(): string {
        let unitCostTotal = 0;
        this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations.forEach(op => {
            if (op.snoozed == false) {
                if (op.hasPackage != true)
                    unitCostTotal += this.props.selectedVehicle.currentWorkList!.IncludeTax ?
                        op.dmsPrice.amountWithVAT! : op.dmsPrice.amountWithoutVAT!;
                else unitCostTotal += this.props.selectedVehicle.currentWorkList!.IncludeTax ?
                    op.package!.package[0].dmsPrice.amountWithVAT! : op.package!.package[0].dmsPrice.amountWithoutVAT!;
            }
        })

        return unitCostTotal.toFixed(2);
    }

    calculateTotalJobTime(): number {
        let jobTimeTotal = 0;
        this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations.forEach(op => {
            if (op.snoozed == false) {
                jobTimeTotal += Number(op.vst);
            }
        })

        return jobTimeTotal;
    }

    toggleServiceProgramDetails(): void {
        this.setState({
            showServiceProgramDetails: !this.state.showServiceProgramDetails
        })
    }

    SaveEngineCall = async (data: IServiceOrderActionRequest) => {

        this.props.addAction({ vin: this.props.selectedVehicle.vin, action: data.actions[0] })
        // let item = await Apis.ServiceOrder.SaveEngineCall(this.props.selectedVehicle.currentWorkList!.ServiceOrderId, this.props.selectedVehicle.currentWorkList!.Version, data)
        // this.props.updateVersion({ version: item.data.version, vin: this.props.selectedVehicle.vin })

        // return item.data!.serviceProgram!;

    }

    UpdateOperationQuantity = (id: number, quantity: number) => {
    }
    UpdatePackageQuantity = (id: number, quantity: number) => {
    }
    // React.ChangeEvent<HTMLInputElement>

    onMileageChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        let { value, min, max } = ev.target;
        if (isNaN(parseInt(value))) {
            this.setState({ mileage: null })
            return;
        };
        let convertedValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
        if (convertedValue > 200) {
            this.setState({ mileage: convertedValue, registrationDate: new Date() })
        }
        else
            this.setState({ mileage: convertedValue })
    };
    onRegistrationDateChange = (date: Date | null | undefined) => {
        if (date! > this.state.serviceDate)
            this.setState({ registrationDate: date!, serviceDate: date! })
        else
            this.setState({ registrationDate: date! })
    };
    onServiceDateChange = (date: Date | null | undefined) => {
        this.setState({ serviceDate: date! })
    };
    onLicensePlateChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ licensePlate: ev.target.value })
    };

    onFormatDate = (date?: Date): string => {
        // return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100);
        return !date ? '' : date.toLocaleDateString();
    };

    onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.searchServicePlan();
        }
    }

    renderSearchPanel = () => {
        return (
            <React.Fragment>
                {
                    this.state.showSearchTools == false ? (<Link onClick={() => this.setState({ showSearchTools: true })}>Enter Vehicle Details</Link>) : (
                        <div className='row'>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <FontIcon iconName="car" />
                                    <label className='ms-2'>Mileage:</label>
                                    <span className='text-danger'>*</span>
                                    <input
                                        type='number'
                                        className='form-control'
                                        name="mileage"
                                        value={this.state.mileage?.toString()}
                                        onChange={this.onMileageChange}
                                        onKeyDown={this.onKeyDown}
                                        min={0}
                                        max={99999999}>
                                    </input>
                                </div>
                                <div className='col-sm-3'>
                                    {
                                        this.state.registrationDateReadOnly == false && (
                                            <TooltipHost
                                                content="Could not retrieve registration date from the source system. Please enter manually."
                                                // This id is used on the tooltip itself, not the host
                                                // (so an element with this id only exists when the tooltip is shown)
                                                id={'tooltip'}
                                            //calloutProps={calloutProps}
                                            // styles={hostStyles}
                                            >
                                                <FontIcon iconName="warning" className='text-warning' />
                                            </TooltipHost>

                                        )
                                    }
                                    <label className='ms-2'>Registration Date:</label>
                                    <span className='text-danger'>*</span>

                                    <DatePicker
                                        placeholder="Select a date..."
                                        ariaLabel="Select a date"
                                        showWeekNumbers={true}
                                        onSelectDate={this.onRegistrationDateChange}
                                        value={this.state.registrationDate}
                                        formatDate={this.onFormatDate}
                                        maxDate={this.state.mileage !== null && this.state.mileage > 200 ? new Date() : undefined}
                                        disabled={this.state.registrationDateReadOnly}
                                    />
                                </div>
                                <div className='col-sm-3'>

                                    <label className='ms-2'>Service Date:</label>
                                    <span className='text-danger'>*</span>
                                    <DatePicker
                                        placeholder="Select a date..."
                                        ariaLabel="Select a date"
                                        showWeekNumbers={true}
                                        onSelectDate={this.onServiceDateChange}
                                        value={this.state.serviceDate}
                                        formatDate={this.onFormatDate}
                                        minDate={this.state.registrationDate}
                                    />
                                </div>
                                <div className='col-sm-3'>
                                    <label className='ms-2'>License Plate:</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name="licensePlate"
                                        value={this.state.licensePlate}
                                        onChange={this.onLicensePlateChange}>
                                    </input>
                                </div>
                            </div>
                            <div className='row'>
                                <div>
                                    <Link onClick={this.searchServicePlan} className='pe-2' disabled={this.state.mileage == null}>Continue</Link>
                                    <Link onClick={() => this.setState({ showSearchTools: false, mileage: null })}>Cancel</Link>
                                </div>
                                {(this.state.showServicePlanDropdown == true) && (<div>
                                    <Dropdown
                                        componentRef={this.dropdownRef}
                                        placeholder="Select a Service Plan"
                                        options={this.state.servicePlans as IDropDownOptions[]}
                                        selectedKey={this.state.selectedServicePlan}
                                        onChange={(x, item) => this.onSelectServicePlan(item)}
                                        styles={dropdownStyles}
                                        onRenderOption={(item: any) => this.onRenderOption(item)}
                                        onRenderTitle={(item: any) => this.onRenderTitle(item)}

                                    />
                                </div>)}
                            </div>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }

    renderResultPanel = () => {
        return (
            <React.Fragment>
                <div className='row'>
                    <div className='col-sm-3'>
                        <FontIcon iconName="car" />
                        <label className='ms-2'>Mileage:</label>
                        <span className='text-danger'>*</span>
                        <label className='ms-2'>{this.state.mileage}</label>
                    </div>
                    <div className='col-sm-3'>
                        <FontIcon iconName="warning" className='text-warning' />
                        <label className='ms-2'>Registration Date:</label>
                        <span className='text-danger'>*</span>
                        <label className='ms-2'>{this.state.registrationDate.toLocaleDateString()}</label>
                    </div>
                    <div className='col-sm-3'>
                        <FontIcon iconName="warning" className='text-warning' />
                        <label className='ms-2'>Service Date:</label>
                        <span className='text-danger'>*</span>
                        <label className='ms-2'>{this.state.serviceDate.toLocaleDateString()}</label>
                    </div>
                    <div className='col-sm-3'>
                        <label className='ms-2'>License Plate:</label>
                        <label className='ms-2'>{this.state.licensePlate}</label>
                    </div>
                </div>
                <div className='row'>
                    <table className="mt-3 mb-0 table table-hover table-sm">
                        <thead className='table-light'>
                            <tr className='text-center'>
                                <th scope="col"><IconButton iconProps={Icons.Accept} /></th>
                                <th scope="col"></th>
                                <th scope="col">Service Program</th>
                                <th className="text-start" scope="col">Description</th>
                                <th scope="col">Checklist</th>
                                <th scope="col">Skill Level</th>
                                <th scope="col">Notes</th>
                                <th scope="col">Job Time</th>
                                <th scope="col">Unit Cost</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Cost</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='text-center'>
                                <td scope="row"><IconButton iconProps={Icons.Accept} /></td>
                                <td><IconButton iconProps={this.state.showServiceProgramDetails ? Icons.Down : Icons.Right} onClick={() => this.toggleServiceProgramDetails()} /></td>
                                <td>{this.props.selectedVehicle.currentWorkList!.ServiceProgram?.serviceProgramType}</td>
                                <td className="text-start">{this.props.selectedVehicle.currentWorkList!.ServiceProgram?.serviceProgramDescription}</td>
                                <td><IconButton iconProps={Icons.Print} onClick={() => {
                                    this.props.setLoading(true);
                                    Apis.ServiceOrder.ServiceOrderPrint('en-GB', this.props.selectedVehicle.currentWorkList!.ServiceProgram!.serviceProgramId).then((response) => {
                                        console.log(response.data);
                                        let categories: string[] = [];

                                        let taskList = response.data?.serviceTaskList.map(x => {
                                            if (!categories.some(c => c == x.servicePlanSectionDescription))
                                                categories.push(x.servicePlanSectionDescription);
                                            return {
                                                ...x,
                                                technician: this.props.User.FirstName + ' ' + this.props.User.LastName,
                                                ok: false,
                                                rectified: false,
                                                remark: '',
                                                dataValues: null
                                            }

                                        })

                                        this.setState({
                                            checkListCategories: categories,
                                            checkList: response.data,
                                            taskList,
                                            showCheckListModal: true,

                                        }, () => {
                                            console.log(this.state);
                                        });

                                    }).catch(error => {
                                        console.log(error.message);
                                        this.props.setMessage({ messageText: error.message, messageType: MessageType.error })
                                    }).finally(() => {
                                        this.props.setLoading(false);
                                    })

                                }} /></td>
                                <td>1,2</td>
                                <td><IconButton iconProps={Icons.Note} /></td>
                                <td>{this.calculateTotalJobTime()}</td>
                                <td>{this.calculateTotalUnitCost()}</td>
                                <td>1</td>
                                <td>{this.calculateTotalUnitCost()}</td>
                                <td><IconButton iconProps={Icons.Cancel} onClick={() => {
                                    let action = actionCreators.RemoveServiceProgram(Number(this.props.selectedVehicle.currentWorkList!.ServiceProgram!.serviceProgramId));
                                    this.SaveEngineCall(action);
                                    this.setState({
                                        showResultPanel: false,
                                        showSearchTools: false,
                                        showServicePlanDropdown: false,
                                        mileage: null,
                                        servicePlans: []
                                    })
                                    this.props.removeServiceProgram(this.props.selectedVehicle.vin);

                                    // this.props.setLoading(true);

                                    // Apis.ServiceOrder.RemoveServiceOrderItem(ActionsEnum.RemoveServiceProgram, Number(this.props.selectedVehicle.currentWorkList!.ServiceProgram!.serviceProgramId),
                                    //     this.props.selectedVehicle.currentWorkList!.ServiceOrderId!, this.props.selectedVehicle.currentWorkList!.Version).then((response) => {
                                    //         this.setState({
                                    //             showResultPanel: false,
                                    //             showSearchTools: false,
                                    //             showServicePlanDropdown: false,
                                    //             mileage: null,
                                    //             servicePlans: []
                                    //         })
                                    //         this.props.updateVersion({ version: response.data.version, vin: this.props.selectedVehicle.vin });
                                    //         this.props.removeServiceProgram(this.props.selectedVehicle.vin);
                                    //         this.props.setMessage({ messageText: 'Service Program Removed Successfully!', messageType: MessageType.success })
                                    //     }).catch((error) => {
                                    //         this.props.setMessage({ messageText: 'Remove Service Program Failed!', messageType: MessageType.error });
                                    //     }).finally(() => this.props.setLoading(false))
                                }} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='row'>
                    {(this.state.showServiceProgramDetails) && (
                        <table className="table table-hover table-sm">
                            <thead className='table-light'>
                                <tr className='text-center'>
                                    <th style={{ width: 20 }} scope="col"></th>
                                    <th style={{ width: 10 }} scope="col"></th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Item No.</th>
                                    <th className="text-start" scope="col">Description</th>
                                    <th scope="col">Skill level</th>
                                    <th scope="col">Notes</th>
                                    <th scope="col">Job No</th>
                                    <th scope="col">Job Time</th>
                                    <th scope="col">Fixed Price</th>
                                    <th scope="col">Unit Cost</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='table-light'>
                                    <td colSpan={14}>Included Operations</td>
                                </tr>
                                {
                                    this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations.filter((x) => x.included).map((item) => {


                                        return (
                                            <>
                                                {
                                                    (item.hasPackage == true) ?
                                                        (<PackageRow
                                                            Package={item.package!.package[0]}
                                                            ShowIncludeButton={true}
                                                            SelectedVehicle={this.props.selectedVehicle}
                                                            SaveEngineCall={this.SaveEngineCall} />) :
                                                        (<OperationRow
                                                            Operation={item}
                                                            IsServiceProgramOperation={true}
                                                            SelectedVehicle={this.props.selectedVehicle}
                                                            SaveEngineCall={this.SaveEngineCall} />)
                                                }
                                            </>
                                        )
                                    })
                                }
                                <tr className='table-light'>
                                    <td colSpan={14}>Additional Operations</td>
                                </tr>
                                {
                                    this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations.filter(x => !x.included).map((item) => {
                                        return (
                                            <>
                                                {
                                                    (item.hasPackage == true) ?
                                                        (<PackageRow
                                                            Package={item.package!.package[0]}
                                                            ShowIncludeButton={true}
                                                            SelectedVehicle={this.props.selectedVehicle}
                                                            SaveEngineCall={this.SaveEngineCall} />) :
                                                        (<OperationRow
                                                            Operation={item}
                                                            IsServiceProgramOperation={true}
                                                            SelectedVehicle={this.props.selectedVehicle}
                                                            SaveEngineCall={this.SaveEngineCall} />)
                                                }
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    )}
                </div>
            </React.Fragment>
        )
    }

    saveChages = async () => {
        console.log(this.state.changedChecklistItems);

        let checklistItems: IAddServiceChecklistItem[] = this.state.changedChecklistItems.map(n => {
            let item = this.state.taskList.find(x => x.id == n)!;
            return {
                id: item.id,
                ok: item.ok,
                rectified: item.rectified,
                remark: item.remark == '' ? null : item.remark,
                technician: item.technician,
                dataValues: item.dataValues,
            }
        })
        console.log(checklistItems);

        let data = actionCreators.AddServiceChecklistItems(checklistItems)
        console.log(data);
        this.SaveEngineCall(data);
        // await Apis.ServiceOrder.SaveEngineCall(this.props.selectedVehicle.currentWorkList!.ServiceOrderId,
        //     this.props.selectedVehicle.currentWorkList!.Version, data)
    }

    onCloseModal = () => {
        this.props.setLoading(true)

        this.saveChages().then(() => {
            this.setState({ showCheckListModal: false })
        }).catch((error) => {
            this.props.setMessage({ messageText: error, messageType: MessageType.error })
        }).finally(() => {
            this.props.setLoading(false)
        })
    }

    render() {
        return (
            <>
                <DialogMessage show={this.state.showMaxQuantityPopup}
                    toggleVisibility={() => { this.setState({ showMaxQuantityPopup: !this.state.showMaxQuantityPopup }) }}
                    title={'Maximum allowed quantity reached!'}
                    subText={'Some Operations and Packages have reached the maximum allowed quantity. Quantity has been adjusted accordingly.'} />
                <div className="row p-2 mt-2 border mb-4">
                    <div className='bg-light p-2 border'>
                        <h5 className='mb-0'><FontIcon iconName="DeveloperTools" />
                            <span className='ms-3'>Service Program - </span>
                            <span>{this.props.selectedVehicle.currentWorkList!.ServiceProgram?.serviceProgramType}</span>
                        </h5>
                    </div>
                    <div className='row p-2' >
                        {
                            (this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations.length > 0) ? (this.renderResultPanel()) : (this.renderSearchPanel())
                        }

                    </div>
                </div>
                <Modal
                    titleAriaId={'titleId'}
                    isOpen={this.state.showCheckListModal}
                    onDismiss={this.onCloseModal}


                    isBlocking={false}
                    containerClassName={contentStyles.container}

                >
                    <div className={contentStyles.header} >
                        <span id={'titleId'}>Service CheckList</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={Icons.Cancel}
                            ariaLabel="Close popup modal"
                            onClick={this.onCloseModal}
                        />
                    </div>
                    <table style={{ maxWidth: 800 }} className={'table table-striped table-responsive p-5 ml-5 mr-5'}>
                        <thead>
                            <th>Item</th>
                            <th>Service Task</th>
                            <th>Ok</th>
                            <th>Rectified</th>
                            <th></th>
                        </thead>
                        <tbody>
                            {
                                this.state.checkListCategories.map(category => {
                                    return (
                                        <>
                                            <tr>
                                                <td colSpan={5}>{category}</td>
                                            </tr>
                                            {
                                                this.state.taskList.filter(x => x.servicePlanSectionDescription == category).map((task, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{(index + 1)}</td>
                                                            <td style={{ maxWidth: 300 }}>{task.serviceTaskDescription}</td>
                                                            <td><Toggle onChange={(e, value) => {
                                                                console.log(value);

                                                                if (!this.state.changedChecklistItems.some(n => n == task.id)) {
                                                                    this.setState({
                                                                        changedChecklistItems: [...this.state.changedChecklistItems, task.id]
                                                                    });
                                                                }
                                                                this.setState({
                                                                    taskList: this.state.taskList.map(taskItem => {
                                                                        return taskItem.id == task.id ? {
                                                                            ...task,
                                                                            ok: value == true ? true : false,
                                                                        } : taskItem;
                                                                    })
                                                                })
                                                            }} /></td>
                                                            <td><Checkbox onChange={(e, value) => {
                                                                console.log(value);

                                                                if (!this.state.changedChecklistItems.some(n => n == task.id)) {
                                                                    this.setState({
                                                                        changedChecklistItems: [...this.state.changedChecklistItems, task.id]
                                                                    });
                                                                }
                                                                this.setState({
                                                                    taskList: this.state.taskList.map(taskItem => {
                                                                        return taskItem.id == task.id ? {
                                                                            ...task,
                                                                            rectified: value == true ? true : false,
                                                                        } : taskItem;
                                                                    })
                                                                })
                                                            }} /></td>
                                                            <td><input onChange={(e) => {
                                                                if (!this.state.changedChecklistItems.some(n => n == task.id)) {
                                                                    this.setState({
                                                                        changedChecklistItems: [...this.state.changedChecklistItems, task.id]
                                                                    });
                                                                }
                                                                this.setState({
                                                                    taskList: this.state.taskList.map(taskItem => {
                                                                        return taskItem.id == task.id ? {
                                                                            ...task,
                                                                            remark: e.target.value,
                                                                        } : taskItem;
                                                                    })
                                                                })
                                                            }} /></td>
                                                        </tr>
                                                    )

                                                })
                                            }
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Modal>
                <TableFooter WorkList={this.props.selectedVehicle.currentWorkList!} />
            </>
        )
    }
}

interface props extends PropsFromRedux {
    selectedVehicle: IProfiledVehicle,
}

interface state {
    licensePlate: string,
    mileage: number | null,
    registrationDate: Date,
    registrationDateReadOnly: boolean,
    serviceDate: Date,

    showSearchTools: boolean,
    showServicePlanDropdown: boolean,
    showResultPanel: boolean,
    showServiceProgramDetails: boolean,

    servicePlans: servicePlanOptions[],
    servicePlansDto: IServicePlan[],
    selectedServicePlan: string,

    checkList?: IServiceOrderPrint,
    taskList: IServicePrintTaskListItemViewModel[],
    checkListCategories: string[],
    changedChecklistItems: number[],
    showCheckListModal: boolean,

    showMaxQuantityPopup: boolean,
}


const mapStateToProps = (store: RootState) => ({
    User: store.user,
    OffsetForDefaultServiceDate: store.userSettings.OffsetForDefaultServiceDate
})
const mapDispatchToProps = {
    setLoading,
    setMessage,
    addServiceProgram: profiledVehiclesActions.addServiceProgram,
    addServiceProgramOperations: profiledVehiclesActions.addServiceProgramOperations,
    removeServiceProgram: profiledVehiclesActions.removeServiceProgram,
    updateVersion: profiledVehiclesActions.updateVersion,
    removeOperation: profiledVehiclesActions.removeOperation,
    removePackage: profiledVehiclesActions.removePackage,
    addAction: profiledVehiclesActions.addAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const ServiceProgram = connector(ServiceProgramClass);

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {

        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const stackProps: Partial<IStackProps> = {
    horizontal: true,
    tokens: { childrenGap: 40 },
    styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
