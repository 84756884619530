import React from "react";
import { Shimmer } from "@fluentui/react";


export function ShimmerComponent() {
    return(
        <div>
            <Shimmer className="p-2" />
            <Shimmer className="p-2" width="75%" />
            <Shimmer className="p-2" width="50%" />
            <Shimmer className="p-2" />
            <Shimmer className="p-2" width="75%" />
            <Shimmer className="p-2" width="50%" />
            <Shimmer className="p-2" width="50%" />
        </div>
    )
}