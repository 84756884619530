import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Shimmer, Link, PrimaryButton, Checkbox } from "@fluentui/react";

import { Apis } from "vidaplanninglogic/lib/services/apis/Apis";
import { RootState } from "vidaplanninglogic/lib/reduxx/store";
import { login, logout } from "vidaplanninglogic/lib/reduxx/slices/userSlice";
import { ILoadable } from "vidaplanninglogic/lib/data/entities/ILoader";
import { IMyList } from "vidaplanninglogic/lib/data/entities/dtos/IMyList";

class MyListTabClass extends Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            items: [],
            showOtherUsersList: false,


            loading: false,
            failed: false,
            error: '',
        }
    }

    componentDidMount() {
        console.log("Component Did Mount")
        this.populateData(false);
    }

    populateData(showOtherUsersList: boolean) {
        this.setState({ loading: true });
        Apis.MyList.MyListVehicles(showOtherUsersList)
            .then((result: any) => {
                const response = result.data;
                console.log(response);
                this.setState({
                    items: response,
                    loading: false,
                    failed: false,
                    error: '',
                })
            }).catch(error => {
                console.log(error)
                this.setState({
                    items: [],
                    loading: false,
                    failed: true,
                    error: 'Customers could not be loaded!!!',
                })
            })
    }

    toggleShowOtherUsersLists = () => {
        console.log("toggle!!!")
        let showOthers = !this.state.showOtherUsersList;
        this.setState({ showOtherUsersList: showOthers })
        this.populateData(showOthers);
    }

    renderStatusText(status: any): React.ReactNode {
        switch (status) {
            case 'COMPLETE':
                return 'Complete';
            case 'IN_PROGRESS':
                return 'Draft';
            case 'WITH_DMS':
                return 'Pre-planned';
            default:
                return;
        }
    }

    renderTable(items: any) {
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th><Checkbox /></th>
                        <th>List Name</th>
                        <th>Status</th>
                        <th>Customer Name</th>
                        <th>VIN</th>
                        <th>Model</th>
                        <th>Year</th>
                        <th>Customer Type</th>
                        <th>Created By</th>
                        <th>Last Modified By</th>
                        <th>Last Modified</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.items.map((item: any) => (
                            <tr key={item.serviceOrderId}>
                                <td><Checkbox /></td>
                                <td>{item.serviceOrderName}</td>
                                <td>{this.renderStatusText(item.status)}</td>
                                <td>{item.customer?.customerName}</td>
                                <td>
                                    <Link>
                                        {item.vehicle.vin}
                                    </Link>
                                </td>

                                <td>{item.vehicle?.modelId}</td>
                                <td>{item.vehicle?.modelYearId}</td>
                                <td>{item.customer?.customerType}</td>
                                <td>{item.createdBy?.firstName} {item.createdBy.firstName}</td>
                                <td>{item.modifiedBy?.firstName} {item.modifiedBy.firstName}</td>
                                <td>{new Date(item.modifyDate).toLocaleDateString()}</td>
                                <td><PrimaryButton text='Select' onClick={() => this.profileVehicle(item)} /></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )
    }

    profileVehicle(item: any): void {
        Apis.ProfileVehicle.ProfileVehiclesByVin(item.vehicle.vin, item.vehicle.partnerGroupId).then(
            (response: any) => {
                console.log(response.data)
                let allFilters = response.data;
                console.log(allFilters)
                let headerText =
                    allFilters.vehicleModel.description + ', ' +
                    allFilters.modelYear.description + ', ' +
                    allFilters.otherAttributes.engineList.engine[0].description + ', ' +
                    allFilters.otherAttributes.transmissionList.transmission[0].description;
                // this.props.addProfiledVehicle(item.vehicle.vin, headerText, item.vehicle.partnerGroupId);
            }
        )
    }

    render() {
        let loading = this.state.loading && (
            <div>
                <Shimmer className="p-2" />
                <Shimmer className="p-2" width="75%" />
                <Shimmer className="p-2" width="50%" />
                <Shimmer className="p-2" />
                <Shimmer className="p-2" width="75%" />
            </div>
        )
        let errors = this.state.failed && (
            <div className='alert alert-danger'>
                <em >{this.state.error}</em>
            </div>
        );

        return (
            <div>
                <div className="p-3" >
                    <Checkbox className='m-3' label="Show Other Users' lists" checked={this.state.showOtherUsersList} onChange={() => { this.toggleShowOtherUsersLists() }} />
                    <h4>My List</h4>
                    {errors}
                    {
                        !this.state.loading ? (this.renderTable(this.state.items)) : (loading)
                    }
                </div>
            </div>
        )
    }
}

interface props extends PropsFromRedux {

}

interface state extends ILoadable {
    items: IMyList[],
    showOtherUsersList: boolean,
}

const mapStateToProps = (store: RootState) => ({
    // profiledVehicles: store.profiledVehicles.Vehicles,
})
const mapDispatchToProps = {
    login, logout
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const MyListTab = connector(MyListTabClass);