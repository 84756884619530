import { Component } from "react";
import { Link } from '@fluentui/react';

import { ILoadable } from "vidaplanninglogic/lib/data/entities/ILoader";
import { IProfiledVehicle } from "vidaplanninglogic/lib/data/entities/IProfiledVehicle";

import { WorkListForm } from "../../serviceOrder/WorkListForm";
import WorkListTab from "../../workList/WorkListTab";
import { PlanningNavMenu } from "./PlanningNavMenu";

interface props {
    selectedVehicle: IProfiledVehicle
}

interface state extends ILoadable {
    showWorkListForm: boolean,
    // worklist?: IWorkList,
}

export default class extends Component<props, state>{

    constructor(props: props) {
        super(props);

        this.state = {
            showWorkListForm: false,
            loading: true,
            failed: false,
            error: '',
        }
        //imagePath
    }

    componentDidMount() {
        console.log("Component Did Mount")
        this.populateData();
    }


    populateData = () => {

    }

    // closeCreatedWorklist = () => {
    //     this.setState({ showWorkListForm: false })
    // }

    // showCreatedWorklist = () => {
    //     this.setState({
    //         showWorkListForm: true,
    //     })
    // }

    render() {
        return (
            <div>
                <div className='row p-2' >
                    <div className='col-2'>
                        <img src={this.props.selectedVehicle.allFilters?.vehicleModel.imagePath!} style={{ maxHeight: '70px' }} />
                    </div>
                    <div className='col-3 bg-light'>
                        <div className='row'>
                            <div className='col-6'>
                                <label>VIN:</label>
                            </div>
                            <div className='col-6'>
                                <Link>
                                    {this.props.selectedVehicle.vin}
                                </Link>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-5'>
                                <label>Model/Year/Chassis:</label>
                            </div>
                            <div className='col-7'>
                                {
                                    this.props.selectedVehicle.allFilters?.vehicleModel.description + '/' +
                                    this.props.selectedVehicle.allFilters?.modelYear.description + '/' +
                                    this.props.selectedVehicle.vin.substring(11, 17)
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-1'></div>

                    <div className='col-3 bg-light p-2'>
                        <label>Customer Name: </label>
                        {/* <label> {(this.props.selectedVehicle.allFilters?.customerName ?? '__')}</label> */}
                        <label><Link>{this.props.selectedVehicle?.customer?.Owner?.firstName} {this.props.selectedVehicle?.customer?.Owner?.surname}</Link></label>
                    </div>
                    <div className='col-1'></div>

                    <div className='col-1 bg-light'>
                        <label>List:</label>
                        <label>__</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <PlanningNavMenu />
                    </div>
                    <div className='col-10'>
                        {/* <Switch>
                            <Route exact path="/ServiceOrder" render={(routeProps: any) => <WrokListFrom {...this.props} {...routeProps} />} />
                            <Route exact path="/" render={(routeProps: any) => <WorkListTab {...this.props} {...routeProps} />} />
                        </Switch> */}

                        {
                            (this.props.selectedVehicle.currentWorkList) ?
                                (<WorkListForm selectedVehicle={this.props.selectedVehicle} />) :
                                (<WorkListTab selectedVehicle={this.props.selectedVehicle} />)
                        }

                    </div>
                </div>
            </div>
        );
    }
}