import { IIconProps, IStackTokens, Link, PrimaryButton, Shimmer, Stack, IconButton, Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react'

import { ILoadable } from 'vidaplanninglogic/lib/data/entities/ILoader';
import { IProfiledVehicle } from 'vidaplanninglogic/lib/data/entities/IProfiledVehicle';
import { IServiceOrderInputRequest } from 'vidaplanninglogic/lib/data/entities/dtos/IServiceOrderInputRequest';
import { Apis } from 'vidaplanninglogic/lib/services/apis/Apis';
import { IServiceOrder } from 'vidaplanninglogic/lib/data/entities/dtos/IServiceOrder';
import IWorkListCreateRequest from 'vidaplanninglogic/lib/data/entities/IWorkListCreateRequest';
import { setLoading, setMessage } from 'vidaplanninglogic/lib/reduxx/slices/commonSlice'
import { connect, ConnectedProps } from 'react-redux';
import { MessageType } from 'vidaplanninglogic/lib/data/constants/MessageType';
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice'
import { IWorkListToIWorkListViewModel } from 'vidaplanninglogic/lib/services/services/mapper/IWorkListToIWorkListViewModel'
import { RootState } from 'vidaplanninglogic/lib/reduxx/store';
import { ConfirmationMessage } from '../workList/ConfirmationMessage';


const stackTokens: IStackTokens = { childrenGap: 10 };
const noIcon: IIconProps = { iconName: '' };
const listIcon: IIconProps = { iconName: 'BulletedList' };
const editIcon: IIconProps = { iconName: 'Edit' };
const syncIcon: IIconProps = { iconName: 'Sync' };
const noteIcon: IIconProps = { iconName: 'QuickNote' };
const acceptIcon: IIconProps = { iconName: 'Accept' };
const prePlanedIcon: IIconProps = { iconName: 'More' };

class WorkListClass extends React.Component<props, state>{
    /**
     *
     */
    constructor(props: props) {
        super(props);

        this.state = {
            loadingWorkListCreation: false,
            workLists: [],
            loading: true,
            failed: false,
            error: '',
        }
    }

    componentDidMount() {
        this.populateData();
    }

    openWorkList = async (serviceOrder: IServiceOrder) => {
        this.setState({ loadingWorkListCreation: true })
        Apis.WorkListApi.OpenWorkList(serviceOrder.serviceOrderId)
            .then(async (response) => {
                let mappedWorkList = IWorkListToIWorkListViewModel(response.data)
                await Apis.DMSApi.getWorkListDetails(mappedWorkList, this.props.selectedVehicle);
                this.props.updateWorkList({ wl: mappedWorkList, vin: this.props.selectedVehicle.vin });
            }).catch(error => {
                console.log(error.messgae);
            }).finally(() => {
                this.setState({ loadingWorkListCreation: false })
            })
    }

    createWorkList = async () => {
        this.setState({ loadingWorkListCreation: true })
        let filters = this.props.selectedVehicle.allFilters!;
        let data: IWorkListCreateRequest = {
            brand: "VCC",
            language: "en-GB",
            sourceSystem: "VIDA",
            vehicle: {
                profileId: this.props.selectedVehicle.vehicleContext.selectedProfiles,
                bodyStyleId: filters.otherAttributes.bodyStyleList?.bodyStyle[0].id,
                engineId: filters.otherAttributes.engineList.engine[0].id,
                modelId: filters.vehicleModel.id,
                modelYearId: filters.modelYear.id,
                specialVehicle: filters.otherAttributes.specialVehicleList?.specialVehicle?.[0]?.id,
                steeringId: filters.otherAttributes.steeringList.steering[0].id,
                transmissionId: filters.otherAttributes.transmissionList.transmission[0].id,
                partnerGroupId: filters.partnerGroup.id,
                vin: this.props.selectedVehicle.vin
            }
        }
        console.log('this is the props before worklist creation!!!')
        console.log(data)
        Apis.WorkListApi.CreateWorkList(data).then((response) => {
            console.log("this is worklist creation confirmaiton");
            this.setState({
                loadingWorkListCreation: false,
            })

            let workListViewModel = IWorkListToIWorkListViewModel(response.data);

            console.log(workListViewModel);
            console.log(this.props.selectedVehicle.vin);

            this.props.addWorkList({ wl: workListViewModel, vin: this.props.selectedVehicle.vin });
            console.log(this.props.selectedVehicle.currentWorkList);

        }).catch(error => {
            console.log(error.messgae);
        });
    }

    populateData() {
        let allFilters = this.props.selectedVehicle?.allFilters;

        let params: IServiceOrderInputRequest = {
            engineid: allFilters?.otherAttributes?.engineList?.engine[0].id,
            modelid: allFilters?.vehicleModel?.id,
            modelyearid: allFilters?.modelYear?.id,
            partnergroupid: allFilters?.partnerGroup?.id,
            transmissionid: allFilters?.otherAttributes.transmissionList?.transmission[0].id,
            vin: this.props.selectedVehicle?.vin,
            vwpsystemaccess: false
        }

        Apis.WorkListApi.GetWorkLists(params)
            .then((result) => {
                const response = result?.data;
                this.setState({
                    workLists: response,
                    loading: false,
                    failed: false,
                    error: '',
                })
            }).catch(error => {
                console.log(error)
                this.setState({
                    workLists: [],
                    loading: false,
                    failed: true,
                    error: 'Customers could not be loaded!!!',
                })
            })
    }

    deleteWorklist = async (serviceOrderId: string) => {
        this.props.setLoading(true);
        Apis.ServiceOrder.DeleteServiceOrder(serviceOrderId).then((response) => {
            this.setState({
                workLists: this.state.workLists.filter(x =>
                    x.serviceOrderId != serviceOrderId
                )
            })
            this.props.setMessage({ messageText: 'WorkList Deleted successfully!', messageType: MessageType.success })
        }).catch((error) => {
            console.log(error);
            this.props.setMessage({ messageText: 'Delete WorkList is failed!', messageType: MessageType.error })

        }).finally(() => {
            this.props.setLoading(false);
        })
    }

    renderTable() {
        return (
            <div>
                <div className='row'>
                    <Stack horizontal tokens={stackTokens} className='m-3'>
                        <PrimaryButton iconProps={this.state.loadingWorkListCreation ? noIcon : editIcon} onClick={this.createWorkList} >
                            {this.state.loadingWorkListCreation && <Spinner style={{ display: 'inline-block' }} size={SpinnerSize.small} />}<span className='ps-2'> Create work list</span>
                        </PrimaryButton>
                        <PrimaryButton iconProps={listIcon} text='View Service Book' />
                    </Stack>
                </div>
                <div className='row'>
                    <div className='row'>
                        <h5>Requested</h5>
                    </div>
                    <div className='row'>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Received</th>
                                    <th>Customer</th>
                                    <th>License Plate</th>
                                    <th>Note</th>
                                    <th>Request</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={6} className='text-center'>There is no requested booking for this vehicle</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='row'>
                    <h5>Work</h5>

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Work List Name</th>
                                <th>Work Order in DMS</th>
                                <th>Note</th>
                                <th>Created By</th>
                                <th>Last Modified by</th>
                                <th>Last Modified</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.workLists.length < 1 &&
                                <tr>
                                    <td colSpan={9} className='text-center'>There is no Work List for this vehicle</td>
                                </tr>}
                            {
                                this.state.workLists.map((item: IServiceOrder) => (
                                    <tr key={item.serviceOrderId}>
                                        <td>
                                            {this.renderStatusIcon(item.status)}
                                        </td>
                                        <td>
                                            <Link onClick={() => this.openWorkList(item)}>
                                                {item.serviceOrderName}
                                            </Link>
                                        </td>
                                        <td>{item.workOrderNumber}</td>
                                        <td>
                                            <IconButton iconProps={noteIcon} />
                                        </td>
                                        <td>{item.createdBy?.firstName} {item.createdBy.firstName}</td>
                                        <td>{item.modifiedBy?.firstName} {item.modifiedBy.firstName}</td>
                                        <td>{new Date(item.modifyDate).toLocaleDateString()}</td>
                                        <td>{this.renderStatusText(item.status)}</td>
                                        <td><ConfirmationMessage deleteWorklist={() => this.deleteWorklist(item.serviceOrderId)} /></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        )
    }
    renderStatusIcon(status: any): React.ReactNode {
        switch (status) {
            case 'COMPLETE':
                return <IconButton styles={{ root: [{ color: 'green' }] }} iconProps={acceptIcon} />;
            case 'IN_PROGRESS':
                return <IconButton styles={{ root: [{ color: 'orange' }] }} iconProps={editIcon} />;
            case 'WITH_DMS':
                return <IconButton iconProps={prePlanedIcon} />;
            default:
                return;
        }
    }

    renderStatusText(status: any): React.ReactNode {
        switch (status) {
            case 'COMPLETE':
                return 'Complete';
            case 'IN_PROGRESS':
                return 'Draft';
            case 'WITH_DMS':
                return 'Pre-planned';
            default:
                return;
        }
    }

    render() {
        let loading = this.state.loading && (
            <div>
                <Shimmer className="p-2" />
                <Shimmer className="p-2" width="75%" />
                <Shimmer className="p-2" width="50%" />
                <Shimmer className="p-2" />
                <Shimmer className="p-2" width="75%" />
            </div>
        )
        let errors = this.state.failed && (
            <div className='alert alert-danger'>
                <em >{this.state.error}</em>
            </div>
        );

        return (
            <div>
                <div className="p-3" >
                    {errors}
                    {
                        !this.state.loading ? (this.renderTable()) : (loading)
                    }
                </div>
            </div>
        )
    }
}

interface props extends PropsFromRedux {
    selectedVehicle: IProfiledVehicle
}

interface state extends ILoadable {
    workLists: IServiceOrder[],
    loadingWorkListCreation: boolean
}

const mapStateToProps = (store: RootState) => ({
})

const mapDispatchToProps = {
    setLoading,
    setMessage,
    addWorkList: profiledVehiclesActions.addWorkList,
    updateWorkList: profiledVehiclesActions.updateWorkList
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export const WorkList = connector(WorkListClass)