import React, { Component } from "react";
import { Pivot, PivotItem } from '@fluentui/react';
import { connect } from "react-redux";

import { IProfiledVehicle } from "vidaplanninglogic/lib/data/entities/IProfiledVehicle";
import { IDataStore } from "vidaplanninglogic/lib/data/entities/IDataStore";

import PlanningDiagnosticsTab from "./PlanningDiagnosticsTab";
import { ShimmerComponent } from "../../common/Simmer";

interface props {
    selectedVehicle: IProfiledVehicle
}
interface state{
    iFrameUrl: string,
}

class ProfileVehicleTabClass extends Component<props, state>{

    constructor(props: props) {
        super(props);
        this.state = {
            iFrameUrl: '',
        }
    }

    render() {
        return (
            <Pivot aria-label="Basic Pivot Example">
                <PivotItem
                    headerText="Planning & Diagnostics"
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'My Files Title',
                    }}
                >
                    <PlanningDiagnosticsTab selectedVehicle={this.props.selectedVehicle} />
                </PivotItem>
                {/* <PivotItem headerText="Software Installation">
                    <ShimmerComponent />
                </PivotItem> */}
                <PivotItem headerText="Information">
                    <ShimmerComponent />
                </PivotItem>
                <PivotItem headerText="Service Journals">
                    <button onClick={
                        () => {
                            // console.log('log button Clicked !!!!!!!!!!!!!!!!!!!!!!!11')
                            // var xhr = new XMLHttpRequest();

                            // xhr.open('GET', 'https://tie.smcpstaging.volvocars.biz/service-journals/search');
                            // //xhr.onreadystatechange = handler;
                            // xhr.responseType = 'blob';
                            // xhr.withCredentials = true;
                            // xhr.setRequestHeader('Cookie', 'LtpaToken2=' + 'token');
                            // xhr.send();

                            // function handler() {
                            //     if (this.readyState === this.DONE) {
                            //         if (this.status === 200) {
                            //             // this.response is a Blob, because we set responseType above
                            //             var data_url = URL.createObjectURL(this.response);
                            //             document.querySelector('#output-frame-id').src = data_url;
                            //         } else {
                            //             console.error('no pdf :(');
                            //         }
                            //     }
                            // }
                            let tieIframe: any = document.getElementById('tieIframe');
                            console.log(tieIframe);
                            let tieIframeDocument = tieIframe.contentWindow.document;
                            console.log(tieIframeDocument);
                            
                            tieIframeDocument.cookie = 'Cookie: vts_tiestaging=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiQmVhcmVyIiwiYXVkIjoidGllc3RhZ2luZyIsInN1YiI6Ik1aT0hSRVZBIiwibWFpbCI6Im1vaGFtbWFkLnpvaHJlaHZhbmRAdm9sdm9jYXJzLmNvbSIsImZuIjoiTW9oYW1tYWQiLCJncm91cHMiOltdLCJjb3VudHJ5TWFuYWdpbmdIdWIiOiJFTUVBIEhVQl8yIiwiZGVhbGVyQ0lTIjoiNlNFVElFTkdURVNUIiwidnRpIjoiNGIxOWFmZGEtOGY4Ni00MjkxLTljMTktZTQ1N2NiMjU2YmY1IiwiY28iOiJTV0UiLCJhdXRob3JpdGllcyI6WyJST0xFX01JR1JBVEUiLCJUSUVfU0pfUkVBRCIsIlRJRV9DQVNFX0NSRUFURSJdLCJjZHNpZCI6Ik1aT0hSRVZBIiwicGFyZW50Q29tcGFueUlkIjoidmNjIiwiY291bnRyeUNvZGUiOjc1MiwiZ2l2ZW5uYW1lIjoiTW9oYW1tYWQiLCJzbiI6IlpvaHJlaHZhbmQiLCJpc3MiOiJ2b2x2b2NhcnMiLCJleHAiOjE2Mjc5NTI3NzQsImlhdCI6MTYyNzkyMzk3NH0.vwlMGtE04q-nIWwQGCD-e-YoyLF_EWoFJuHTdySZlZgnXM0443R6nddkgZ5fvxE3nbq2GETVgcX078xDcKPc-M2-lQxJMUW74uIcPH2TkojxrJl7t2FVaDIaRaHv-p0fKpWoJB6klNCs_t9hDXw-IrlBn4yGyRl_W26w00eblvW91V_jbNeYMy4DmX3fi5O_iMrOIAvSjoF2mffTTfuf_3jcP1KJgGXZ1QCy3jGKlYFCmwy3rK-MI4Z56bN2ty904HXrYkz5-qT402of9UQsbqY91PVRZ0gv8iaey3Y_SjqIoN2R21B0vfTJck8OYWeGj7DP5ET41g29L3teBcYa46AXpjRSUr1R3qCYsqCKZz_nKqNSGmtGgyA4PvFTwXcX8EyTd-05-K00WhgRlIawvuSElRsgbq8Qk91ubP4rPGxcACaW5_8m0Q4yrrnSNsn8lwEMaeJnl4bGxyGP3bce5_Q1NKjDwF3CFQYj04z-WdorB8zDsL0cUxZsapqnOxaI4BkkwtPdIEwzaK12sfhoTznAldWBgIa4Q5rSrIgR-TELvmSGUdL5M9F_tVo7rUhb-SWQ8sfTgtynQCPqJ7ZMc5zpY5xCXs520FbKrhTc44nafVCJCmne9oTx93JH0UryVOZNfwFUADcjbao5GIf1xOTIWkZKQIW7Ap_Lc3uM5vU';
                            this.setState({ iFrameUrl: 'https://tie.smcpstaging.volvocars.biz/authsuccess?url=/service-journals/search' })
                        }
                    }>Log</button>
                    <iframe id='tieIframe' className="w-100 vh-100" src={this.state.iFrameUrl}></iframe>
                </PivotItem>
            </Pivot>
        );
    }
}

const mapStateToProps = (dataStore: IDataStore) => ({})

const mapDispatchToProps = {}

export const ProfileVehicleTab = connect(mapStateToProps, mapDispatchToProps)(ProfileVehicleTabClass)