import { Checkbox, IconButton, Link, PrimaryButton } from '@fluentui/react';
import React from 'react'
import { connect, ConnectedProps } from 'react-redux';

import { IProfiledVehicle } from 'vidaplanninglogic/lib/data/entities/IProfiledVehicle';
import { ILoadable } from 'vidaplanninglogic/lib/data/entities/ILoader';
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice'

import { PartsSoftware } from './PartsSoftwares/PartsSoftware';
import { Send, Cancel, Print, Edit } from '../common/IconProps'
import { ServiceProgram } from './ServiceProgram/ServiceProgram';
import { CustomerSymptomCode } from './CustomerSymptomCode/CustomerSymptomCode';
import { OperationPackages } from './OperationsPackages/OperationPackages';
import { WarrantyClaims } from './WarrantyClaims';
import { Notes } from './Notes';
import { RootState } from 'vidaplanninglogic/lib/reduxx/store';
import moment from 'moment';
import { Services } from 'vidaplanninglogic/lib/services/apis/Services';
import { ReloadMessage } from '../workList/ReloadMessage';

class WorkListFormClass extends React.Component<props, state>{
    constructor(props: props) {
        super(props);

        this.state = {
            showReloadPopup: false,
            loading: true,
            failed: false,
            error: ''
        }
    }

    componentDidUpdate(prevProps: props) {
        if (prevProps.selectedVehicle.currentWorkList?.Reload !== this.props.selectedVehicle.currentWorkList?.Reload &&
            this.props.selectedVehicle.currentWorkList!.Reload) {
                this.setState({showReloadPopup: true})
        }
    }

    calculateTotalVstAndTime = (): string => {
        let totalVst: number = 0
        this.props.selectedVehicle.currentWorkList!.Operations.forEach(op => {
            totalVst += Number(op.vst) * op.quantity
        })
        this.props.selectedVehicle.currentWorkList!.Packages.forEach(pk => {
            totalVst += Number(pk.includedOperations.operationitem[0].vst) * pk.includedOperations.operationitem[0].quantity
        })
        this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations.filter(x => x.snoozed == false).forEach(spo => {
            totalVst += Number(spo.vst) * spo.quantity
        })

        let totalEstimatedTime: number = totalVst * this.props.conversionFactor;
        let hm = moment.duration(totalEstimatedTime, 'hours')

        return `VST ${totalVst} (${hm.hours()} Hours ${hm.minutes()} Minutes)`;
    }

    calculateTotalCost = (): string => {
        let totalCost: number = 0;
        this.props.selectedVehicle.currentWorkList!.Operations.forEach(op => {
            totalCost += (Number(this.props.selectedVehicle.currentWorkList!.IncludeTax
                ? op.dmsPrice?.amountWithVAT : op.dmsPrice?.amountWithoutVAT) * op.quantity)
        })

        this.props.selectedVehicle.currentWorkList!.Packages.forEach(pk => {
            totalCost += (Number(this.props.selectedVehicle.currentWorkList!.IncludeTax ?
                pk.dmsPrice?.amountWithVAT : pk.dmsPrice?.amountWithoutVAT) * pk.quantity)
        })

        this.props.selectedVehicle.currentWorkList!.Parts.forEach(p => {
            totalCost += (Number(this.props.selectedVehicle.currentWorkList!.IncludeTax ?
                p.dmsPrice?.amountWithVAT : p.dmsPrice?.amountWithoutVAT) * p.quantity)
        })

        this.props.selectedVehicle.currentWorkList!.ServiceProgramOperations.filter(x => x.snoozed == false).forEach(spo => {
            totalCost += spo.hasPackage == true ? (Number(this.props.selectedVehicle.currentWorkList!.IncludeTax ?
                spo.package!.package[0].dmsPrice.amountWithVAT : spo.package!.package[0].dmsPrice.amountWithoutVAT) * spo.quantity)
                :
                (Number(this.props.selectedVehicle.currentWorkList!.IncludeTax ?
                    spo.dmsPrice?.amountWithVAT : spo.dmsPrice?.amountWithoutVAT) * spo.quantity)
        })

        return totalCost.toFixed(2);
    }

    reloadWorklist = () => {
        Services.WorklistServices.OpenWorkList(this.props.selectedVehicle.currentWorkList!.ServiceOrderId,
            this.props.selectedVehicle);
            this.setState({showReloadPopup: false});
    }

    render() {
        return (
            <>
                <ReloadMessage isVisible={this.state.showReloadPopup}  onConfirmation={this.reloadWorklist} onDissmiss={this.reloadWorklist} />
                <div className='p-3'>
                    <div className='row'>
                        <div className="d-flex justify-content-between">
                            <div className=''>
                                <IconButton styles={{ root: [{ color: 'orange' }] }} iconProps={Edit} />
                                Draft
                            </div>
                            <div className='' >
                                <IconButton styles={{ root: [{ color: 'green' }] }} iconProps={Cancel}
                                    onClick={() => {
                                        this.props.removeWorkList(this.props.selectedVehicle.vin);
                                    }
                                    } />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <h5 className='mb-0'>Work List: </h5>
                                <h5 className='mb-0'>{this.props.selectedVehicle.currentWorkList!.ServiceOrderName}</h5>
                                <div style={{ marginLeft: '20px' }}><Link>Rename</Link></div>
                            </div>
                            <div className='d-flex'>
                                <PrimaryButton iconProps={Send} text='Submit to DMS' />
                                <PrimaryButton style={{ marginLeft: '5px' }} iconProps={Print} />
                            </div>
                        </div>
                    </div>
                    <div className="row bg-light p-2 mt-2">
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <span className='mb-0'>View By: </span>
                                <div className="form-check" style={{ marginLeft: '5px' }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Type
                                    </label>
                                </div>
                                <div className="form-check" style={{ marginLeft: '5px' }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Job No
                                    </label>
                                </div>
                                <span className='mb-0' style={{ marginLeft: '10px' }}>|</span>
                                <div className='ml-3' style={{ marginLeft: '10px', marginTop: '5px' }}>
                                    <Checkbox label="Show fixed price" checked={this.props.selectedVehicle!.currentWorkList!.ShowFixedPrice}
                                        onChange={(ev, checked) => {
                                            this.props.setShowFixedPrice({ flag: checked!, vin: this.props.selectedVehicle.vin });
                                        }} />
                                </div>
                                <div className='ml-3' style={{ marginLeft: '10px', marginTop: '5px' }}>
                                    <Checkbox label="Include Taxes/Fees" checked={this.props.selectedVehicle!.currentWorkList!.IncludeTax}
                                        onChange={(ev, checked) => {
                                            this.props.setIncludeTax({ flag: checked!, vin: this.props.selectedVehicle.vin });
                                        }} />
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className='border-start ms-3 ps-3'>
                                    <div className='row small '>Estimated time:</div>
                                    <div className='row small '>{this.calculateTotalVstAndTime()}</div>
                                </div>
                                <div className='border-start ms-3 ps-3'>
                                    <div className='row small '>Total Cost Incl. VAT:</div>
                                    <div className='row small '>{this.calculateTotalCost()}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ServiceProgram selectedVehicle={this.props.selectedVehicle} />
                    <CustomerSymptomCode selectedVehicle={this.props.selectedVehicle} />
                    <OperationPackages selectedVehicle={this.props.selectedVehicle} />
                    <PartsSoftware selectedVehicle={this.props.selectedVehicle} />
                    <WarrantyClaims />
                    <Notes selectedVehicle={this.props.selectedVehicle} />
                </div>
            </>
        )
    }
}

interface props extends PropsFromRedux {
    selectedVehicle: IProfiledVehicle,
}

interface state extends ILoadable {
    showReloadPopup: boolean
}

const mapStateToProps = (store: RootState) => ({
    conversionFactor: store.userSettings.ConversionFactor,

})
const mapDispatchToProps = {
    setShowFixedPrice: profiledVehiclesActions.setShowFixedPrice,
    setIncludeTax: profiledVehiclesActions.setIncludeTax,
    removeWorkList: profiledVehiclesActions.removeWorkList
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const WorkListForm = connector(WorkListFormClass)