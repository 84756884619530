import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { useId } from '@fluentui/react-hooks';

const dialogStyles = { main: { maxWidth: 600 } };

const dialogContentProps = {
    type: DialogType.normal,
    // title: 'Missing Subject',
    closeButtonAriaLabel: 'Close',
    subText: 'An error occurred while saving the list. The list will be reloaded.',
};

interface IProps {
    isVisible: boolean
    onConfirmation(): void,
    onDissmiss(): void,
}

export const ReloadMessage: React.FunctionComponent<IProps> = (props: IProps) => {
    // const [hidden, changeVisibility] = React.useState(true);
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,

            isBlocking: false,
            styles: dialogStyles,
            dragOptions: undefined,
        }),
        [labelId, subTextId],
    );

    return (
        <>
            <Dialog
                hidden={!props.isVisible}
                onDismiss={props.onDissmiss}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    {/* <PrimaryButton onClick={toggleHideDialog} text="Send" /> */}
                    <PrimaryButton onClick={props.onConfirmation} text="Ok" />
                </DialogFooter>
            </Dialog>
        </>
    );
};