import { FontIcon, IBasePicker, IBasePickerSuggestionsProps, IDropdownStyles, IInputProps, ISuggestionItemProps, ITag, IToggleStyles, Link, mergeStyles, TagPicker } from '@fluentui/react';
import React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { ContentType } from 'vidaplanninglogic/lib/data/entities/ContentType';

import { IPartSearchResult } from 'vidaplanninglogic/lib/data/entities/dtos/IPartSearchResult';
import { IServiceOrderActionRequest } from 'vidaplanninglogic/lib/data/entities/IServiceOrderActionRequest';
import { IPartsViewModel } from 'vidaplanninglogic/lib/data/entities/IPackage';
import { IProfiledVehicle } from 'vidaplanninglogic/lib/data/entities/IProfiledVehicle';
import { Apis } from 'vidaplanninglogic/lib/services/apis/Apis';
import { Services } from 'vidaplanninglogic/lib/services/apis/Services';
import { PartRow } from './PartRow';
import TableFooter from './TableFooter';
import { RootState } from 'vidaplanninglogic/lib/reduxx/store';
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice';
import SuggestionRow from './SuggestionRow';
import { actionCreators } from 'vidaplanninglogic/lib/services/services/worklist/actionCreators/actionCreators';



interface IPartOptionss extends ITag, IPartSearchResult {

}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        //width: 300
    },
    root: {
        height: 50
    }
};

class PartsSoftwareClass extends React.Component<props, state>{

    dropdownRef = React.createRef<IBasePicker<ITag>>();

    constructor(props: any) {
        super(props);
        this.state = {
            showDropdown: false,
            showSearchTools: false,
            showResultPanel: false,
            partOptions: [],
            // selectedParts: [],
            selectedKey: '',
            searchText: '',
        }
    }

    rootClass = mergeStyles({
        maxWidth: 500,
    });

    toggleStyles: Partial<IToggleStyles> = { root: { margin: '10px 0' } };

    inputProps: IInputProps = {
        onBlur: (ev: React.FocusEvent<HTMLInputElement>) => {
            console.log('=====>>>>>>   onBlur called');
            // // something wrong with TagPicker control
            // setTimeout(() => {
            //     this.setState({ showSearchTools: false })
            // }, 100)
        },
        onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onFocus called'),
    };

    pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested Parts and Software',
        noResultsFoundText: 'No Parts found',
    };

    listContainsTagList = (part: IPartSearchResult): boolean => {
        // if (!tagList || !tagList.length || tagList.length === 0) {
        //     return false;
        // }
        // return tagList.some(compareTag => compareTag.key === tag.key);

        return this.props.selectedVehicle.currentWorkList!.Parts.some(x => x.partItem.partNumber == part.section.content.partsConstruct[0].partItem.id.toString())
    };

    filterSuggestedTags = async (filterText: string, tagList?: ITag[]): Promise<ITag[]> => {

        let result: ITag[] = []
        if (filterText) {
            if (filterText.length > 3) {
                let partsSearchResult = await Apis.PartSoftwareApi.GetParts('en-GB', this.props.selectedVehicle.vehicleContext,
                    // filterText + '*', 'DESCRIPTION', 'ALL', 'True', '', 'True');
                    filterText);

                this.setState({ partOptions: [...partsSearchResult] })
                let filteredList = partsSearchResult.filter(part => !this.listContainsTagList(part));

                result = filteredList.map(x => {
                    let part = x.section.content.partsConstruct[0].partItem.part;
                    return { key: x.section.content.partsConstruct[0].partItem.id, name: part.partNumber + `    ` + part.description, ...x }
                })
            }
        }
        return result;
    };

    GetLatestServiceOrderVersion = (): number => {
        return this.props.selectedVehicle.currentWorkList!.Version
    }

    RemovePart = (id: number, partNumber: string, version: number) => {
        this.props.selectedVehicle.currentWorkList!.Parts = this.props.selectedVehicle.currentWorkList!.Parts.filter(x => x.partItem.partNumber != partNumber);
        this.props.selectedVehicle.currentWorkList!.Version = version
    }

    SaveEngineCall = (data: IServiceOrderActionRequest) => {
        this.props.addAction({ vin: this.props.selectedVehicle.vin, action: data.actions[0] })
        // Apis.ServiceOrder.SaveEngineCall(this.props.selectedVehicle.currentWorkList!.ServiceOrderId, this.props.selectedVehicle.currentWorkList!.Version, data).
        //     then((response) => {
        //         this.props.updateVersion({ version: response.data.version, vin: this.props.selectedVehicle.vin });
        //     }).catch((error) => {
        //         console.log(error);
        //     })
    }

    getTextFromItem = (item: ITag) => item.name;

    onRenderOption = (item: IPartSearchResult) => {
        return (
            <table>
                <tbody>
                    <tr>
                        <td style={{ width: 250 }}>{item.section.content.partsConstruct[0].partItem.part.partNumber}</td>
                        <td style={{ width: '*' }}>
                            {item.section.content.partsConstruct[0].partItem.part.description}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    onRenderTitle(item: any): JSX.Element | null {
        return (
            <div>{item[0].section.content.partsConstruct[0].partItem.part.partNumber}&nbsp;&nbsp;{item[0].section.content.partsConstruct[0].partItem.part.description}</div>
        );
    }

    addSelectedPart = async (partSearchResult: IPartSearchResult) => {
        this.dropdownRef.current?.completeSuggestion(true);
        console.log(partSearchResult);

        // let partSearchResult = this.state.partOptions.filter(x => x.section.content.partsConstruct[0].partItem.id == items?.[0].key);
        let partItem = partSearchResult.section.content.partsConstruct[0].partItem.part;
        let part: IPartsViewModel = {
            partItem: {
                partNumber: partItem.partNumber,
                description: partItem.description,
                isSoftware: partItem.isSoftware,
                //showIfPartsFilterApplied: partItem.showIfPartsFilterApplied,
                //suppressionIndicator: partItem.suppressionIndicator,
            },
            dmsPrice: { currency: '', amountWithoutVAT: 0, amountWithVAT: 0 },
            isFictive: false,
            rowId: Services.WorklistServices.GetMaxRowId(this.props.selectedVehicle.currentWorkList!.Parts),
            jobNumber: Services.WorklistServices.GetNextJobNumber(this.props.selectedVehicle.currentWorkList!, ContentType.Parts),
            quantity: 1,
            //lubricant: false,
            //operationNumber: 1,
            //prefix: '',
        }

        let action: IServiceOrderActionRequest = actionCreators.AddPart(part);
        this.SaveEngineCall(action);

        await Apis.DMSApi.getPartDetails(part, this.props.selectedVehicle);

        this.props.addPart({ part, vin: this.props.selectedVehicle.vin });
    }

    onRenderSuggestionsItem = (props: ITag, itemProps: ISuggestionItemProps<ITag>) => {
        let part = this.state.partOptions.filter(x => x.section.content.partsConstruct[0].partItem.id == props.key);
        // let op = this.state.opOptions.filter(x => x.operationNumber == props.key);
        return (
            <SuggestionRow
                addSelectedPart={this.addSelectedPart}
                part={part[0]}
                vehicleContext={this.props.selectedVehicle.vehicleContext} />
        )
    };

    onPartSoftwareSelect = async (items?: ITag[]) => {
        console.log('onTagPickerChange');

        if (items && items.length > 0) {
            let partSearchResult = this.state.partOptions.filter(x => x.section.content.partsConstruct[0].partItem.id == items?.[0].key);
            let partItem = partSearchResult[0].section.content.partsConstruct[0].partItem.part;
            let part: IPartsViewModel = {
                partItem: {
                    partNumber: partItem.partNumber,
                    description: partItem.description,
                    isSoftware: partItem.isSoftware,
                    //showIfPartsFilterApplied: partItem.showIfPartsFilterApplied,
                    //suppressionIndicator: partItem.suppressionIndicator,
                },
                dmsPrice: { currency: '', amountWithoutVAT: 0, amountWithVAT: 0 },
                isFictive: false,
                rowId: Services.WorklistServices.GetMaxRowId(this.props.selectedVehicle.currentWorkList!.Parts),
                jobNumber: Services.WorklistServices.GetNextJobNumber(this.props.selectedVehicle.currentWorkList!, ContentType.Parts),
                quantity: 1,
                //lubricant: false,
                //operationNumber: 1,
                //prefix: '',
            }

            let action: IServiceOrderActionRequest = actionCreators.AddPart(part);
            this.SaveEngineCall(action);

            await Apis.DMSApi.getPartDetails(part, this.props.selectedVehicle);

            this.props.addPart({ part, vin: this.props.selectedVehicle.vin });


            // this.setState({
            //     selectedParts: [...this.state.selectedParts, {
            //         ...partSearchResult[0],
            //         rowId: part.rowId,
            //         jobNumber: part.jobNumber,
            //         quantity: 1,
            //         dmsPrice: {
            //             amountWithVAT: part.dmsPrice.amountWithVAT,
            //             amountWithoutVAT: part.dmsPrice.amountWithoutVAT,
            //             currency: part.dmsPrice.currency
            //         },
            //         partItem: {
            //             partNumber: part.partItem.partNumber,
            //             description: part.partItem.description,
            //             isSoftware: part.partItem.isSoftware
            //         },
            //         isFictive: false
            //     }]
            // }, () => {
            //     console.log('this is this!')
            //     console.log(this.state)
            // }
            // )

        }
    }

    renderSearchPanel = () => {
        return (
            <React.Fragment>
                <div className={'row p-2 ' + ((this.state.showSearchTools == false) ? 'd-inline' : 'd-none')}>
                    <Link onClick={() => this.setState({ showSearchTools: true }, () => {
                        this.dropdownRef.current!.focus();
                    })}>Add</Link>
                </div>
                <div className={'row p-2 ' + ((this.state.showSearchTools == true) ? 'd-inline' : 'd-none')}>
                    <TagPicker
                        removeButtonAriaLabel="Remove"
                        selectionAriaLabel="Selected parts"
                        onResolveSuggestions={this.filterSuggestedTags}
                        getTextFromItem={this.getTextFromItem}
                        pickerSuggestionsProps={this.pickerSuggestionsProps}
                        itemLimit={1}
                        componentRef={this.dropdownRef}
                        onRenderSuggestionsItem={this.onRenderSuggestionsItem}
                        resolveDelay={500}
                        // onChange={this.onPartSoftwareSelect}
                        selectedItems={[]}
                    />
                </div>
            </React.Fragment>
        )
    }

    renderResultPanel = () => {
        return (
            <React.Fragment>
                <div className='row'>
                    {(this.props.selectedVehicle.currentWorkList!.Parts.length > 0) && (
                        <table className="table table-hover table-sm">
                            <thead className='table-light'>
                                <tr className='text-center'>
                                    <th style={{ width: 20 }} scope="col"></th>
                                    <th style={{ width: 20 }} scope="col"></th>
                                    <th style={{ width: 100 }} scope="col">Part No.</th>
                                    <th className="text-start" scope="col">Description</th>
                                    <th style={{ width: 100 }} scope="col">Notes</th>
                                    <th scope="col">Job No</th>
                                    <th scope="col">Unit Cost</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.selectedVehicle.currentWorkList!.Parts.map((item: IPartsViewModel) => {
                                        return (<PartRow SelectedVehicle={this.props.selectedVehicle} SaveEngineCall={this.SaveEngineCall} ServiceOrderId={this.props.selectedVehicle.currentWorkList!.ServiceOrderId} Part={item} />)
                                    })
                                }
                            </tbody>
                        </table>
                    )}
                </div>
            </React.Fragment>
        )
    }

    render() {
        return (
            <>
                <div className="row p-2 mt-2 border mb-4">
                    <div className='bg-light p-2 border'>
                        <h5 className='mb-0'><FontIcon iconName="Settings" /><span className='ms-3'>Parts and Software - </span>
                            <span>{this.props.selectedVehicle.currentWorkList!.Parts.length}</span></h5>
                    </div>
                    <div className='row p-2' >
                        {
                            this.renderResultPanel()
                        }
                    </div>
                    {
                        this.renderSearchPanel()
                    }
                </div>
                <TableFooter SelectedParts={this.props.selectedVehicle.currentWorkList!.Parts}
                    IncludeTax={this.props.selectedVehicle.currentWorkList!.IncludeTax}
                    ShowFixedPrice={this.props.selectedVehicle.currentWorkList!.ShowFixedPrice} />
            </>
        )
    }
}

interface props extends PropsFromRedux {
    selectedVehicle: IProfiledVehicle,
}

interface state {
    showSearchTools: boolean,
    showDropdown: boolean,
    showResultPanel: boolean,
    selectedKey: string,
    searchText: string,

    partOptions: IPartSearchResult[],
    // selectedParts: IPartsViewModel[],
}

const mapStateToProps = (store: RootState) => ({

})
const mapDispatchToProps = {
    addPart: profiledVehiclesActions.addPart,
    updateVersion: profiledVehiclesActions.updateVersion,
    addAction: profiledVehiclesActions.addAction
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const PartsSoftware = connector(PartsSoftwareClass);