import React, { Component } from "react";
import packageJson from '../../../package.json';

interface props {

}

function Footer(props: props) {
    return (
        <footer className="footer p-2 fixed-bottom" style={{ backgroundColor: '#284E80' }}>
            <div className="container">
                <div className="row">
                    <div className="col-2">
                        <span className="text-muted ">Environment: </span>
                        <span className="text-white">QA</span>
                    </div>
                    <div className="col-2">
                        <span className="text-muted">Client Version: </span>
                        <span className="text-white">{packageJson.version}</span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer