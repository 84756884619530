import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Route, Switch } from 'react-router';
import { BrowserRouter } from "react-router-dom";

import { IDataStore, IUser } from "vidaplanninglogic/lib/data/entities/IDataStore";

import { Main } from "../main/Main";
import { Login } from "../login/Login";
import { PageNotFound } from "./PageNotFount";
import { RecentVehicle } from "../main/recentVehicle/RecentVehicle";
import { RootState } from "vidaplanninglogic/lib/reduxx/store";

class RouterClass extends Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            loading: true,
            failed: false,
            error: '',
        }
    }

    componentDidMount() {
        this.populateData();
    }

    populateData() {
        setTimeout(() => {
            this.setState({
                loading: false,
            })
        }, 1000)
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={(routeProps: any) => {
                        console.log(this.props)
                        return this.props.User.LoggedIn ?
                            <Main {...this.props} {...routeProps} /> : <Login />
                    }} />
                    <Route exact path="/Login" render={(routeProps: any) => <Login  {...this.props} {...routeProps} />} />
                    <Route exact path="/RecentVehicle" render={(routeProps: any) => <RecentVehicle />} />
                    <Route exact path="/ServiceOrder" render={(routeProps: any) => <Main {...this.props} {...routeProps} />} />
                    <Route exact path="*" render={(routeProps: any) => <PageNotFound  {...this.props} {...routeProps} />} />
                </Switch>
            </BrowserRouter>
        )
    }
}

interface props extends PropsFromRedux {
}

interface state {

}

const mapStateToProps = (state: RootState) => ({
    User: state.user
})
const mapDispatchToProps = {
}


const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export const Router = connector(RouterClass)