import { Pivot, PivotItem } from "@fluentui/react";
import React from "react";
import { IWorkList } from "vidaplanninglogic/lib/data/entities/dtos/IWorkList";

import { IProfiledVehicle } from "vidaplanninglogic/lib/data/entities/IProfiledVehicle";

import { ShimmerComponent } from "../common/Simmer";
import { WorkList } from "./WorkList";

interface props {
    selectedVehicle: IProfiledVehicle
}

export default function WrokListTab(props: props) {
    return (
        <Pivot aria-label="Basic Pivot Example">
            <PivotItem
                headerText="Work Lists"
                headerButtonProps={{
                    'data-order': 1,
                    'data-title': 'My Files Title',
                }}
            >
                <WorkList selectedVehicle={props.selectedVehicle} />
            </PivotItem>
            <PivotItem headerText="Parts List">
                <ShimmerComponent />
            </PivotItem>
        </Pivot>
    )
}