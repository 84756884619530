import { FontIcon } from '@fluentui/react';
import React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { IServiceOrderActionRequest } from 'vidaplanninglogic/lib/data/entities/IServiceOrderActionRequest';
import { IProfiledVehicle } from 'vidaplanninglogic/lib/data/entities/IProfiledVehicle';
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice';
import { RootState } from 'vidaplanninglogic/lib/reduxx/store';
import { Apis } from 'vidaplanninglogic/lib/services/apis/Apis';
import { actionCreators } from 'vidaplanninglogic/lib/services/services/worklist/actionCreators/actionCreators';

class NotesClass extends React.Component<props, state>{
    timer: NodeJS.Timeout | undefined;
    /**
     *
     */
    constructor(props: props) {
        super(props);

        this.state = {
            notes: '',
            checked: false,
        }
    }

    componentDidUpdate(prevProps: props, prevState: state) {
        if (prevProps.selectedVehicle.currentWorkList?.notes !== this.props.selectedVehicle.currentWorkList?.notes) {
            this.handleCheck();
        }
    }

    handleCheck = () => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer!);
        this.timer = setTimeout(() => {
            console.log('timer tick')
            let action = actionCreators.UpdateNotes(this.props.selectedVehicle.currentWorkList!.notes);
            this.SaveEngineCall(action)
            this.toggleCheck();
        }, 1000);
    }

    SaveEngineCall = (data: IServiceOrderActionRequest) => {
        this.props.addAction({ vin: this.props.selectedVehicle.vin, action: data.actions[0] })
        // Apis.ServiceOrder.SaveEngineCall(this.props.selectedVehicle.currentWorkList!.ServiceOrderId, this.props.selectedVehicle.currentWorkList!.Version, data).
        //     then((response) => {
        //         this.props.updateVersion({ version: response.data.version, vin: this.props.selectedVehicle.vin });
        //         console.log("Notes is Updated!!!");
        //     }).catch((error) => {
        //         console.log(error);
        //     })
    }


    toggleCheck = () => {
        this.setState(prevState => ({ checked: !prevState.checked }));
    }

    setIconColor = () => {
        return this.props.selectedVehicle.currentWorkList?.notes == '' || 
        this.props.selectedVehicle.currentWorkList?.notes == undefined  ? '' : '#0d6efd'
    }
    render() {
        return (
            <div className="row p-2 mt-2 border mb-4">
                <div className='bg-light p-2 border'>
                    <h5 className='mb-0'>
                        <FontIcon iconName="QuickNote" style={{ color: this.setIconColor() }} />
                        <span className='ms-3'>Notes</span>
                    </h5>
                </div>
                <div className='row p-2' >
                    <textarea
                        className='form-control'
                        rows={3}
                        value={this.props.selectedVehicle.currentWorkList?.notes}
                        onChange={(ev) => {
                            // this.setState({ notes: ev.target.value })
                            this.props.setNotes({ vin: this.props.selectedVehicle.vin, notes: ev.target.value });
                        }}
                    >
                    </textarea>
                </div>
            </div>
        )
    }
}

interface props extends PropsFromRedux {
    selectedVehicle: IProfiledVehicle;
}

interface state {

    notes: string,
    checked: boolean,
}


const mapStateToProps = (store: RootState) => ({
    // profiledVehicles: store.profiledVehicles.Vehicles,
});
const mapDispatchToProps = {
    setNotes: profiledVehiclesActions.setNotes,
    updateVersion: profiledVehiclesActions.updateVersion,
    addAction: profiledVehiclesActions.addAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const Notes = connector(NotesClass)
