import { IOperationViewModel } from 'vidaplanninglogic/lib/data/entities/IOperationViewModel';
import { IPackageViewModel } from 'vidaplanninglogic/lib/data/entities/IPackageViewModel';

function TableFooter(props: props) {

    const anyItemSelected = (): boolean => {
        return props.SelectedOperations?.length > 0 || props.SelectedPackages.length > 0;
    }

    const calculateSubTotalTime = (): number => {
        let subTotalTime = 0;
        props.SelectedPackages.forEach(x => subTotalTime += (Number(x.includedOperations.operationitem[0].vst) * x.includedOperations.operationitem[0].quantity))
        props.SelectedOperations.forEach(x => subTotalTime += (Number(x.vst) * x.quantity));
        return subTotalTime;
    }
    const calculateSubTotalCost = (): number => {
        let subTotalCost = 0;
        props.SelectedPackages.forEach(x => subTotalCost += (Number(props.IncludeTax ? x.dmsPrice?.amountWithVAT : x.dmsPrice?.amountWithoutVAT) * x.quantity))
        props.SelectedOperations.forEach(x => subTotalCost += (Number(props.IncludeTax ? x.dmsPrice?.amountWithVAT : x.dmsPrice?.amountWithoutVAT) * x.quantity));
        return subTotalCost;
    }


    return (
        <>
            {
                (anyItemSelected()) && (
                    <div className='row justify-content-sm-end mb-4'>
                        <div className='col-sm-3'>
                            <div className='row'>
                                <div className='col-sm-8'>Job Subtotal Time:</div>
                                <div className='col-sm-4'>{calculateSubTotalTime()}</div>
                            </div>
                        </div>
                        <div className='col-sm-3'>
                            <div className='row'>
                                <div className='col-sm-8'>Subtotal Cost:</div>
                                <div className='col-sm-4'>{calculateSubTotalCost().toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

interface props {
    SelectedOperations: IOperationViewModel[],
    SelectedPackages: IPackageViewModel[],
    IncludeTax: boolean,
    ShowFixedPrice: boolean
}

export default TableFooter

