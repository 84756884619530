import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FontIcon, IconButton } from "@fluentui/react";
import { logout } from 'vidaplanninglogic/lib/reduxx/slices/userSlice'
import { Apis } from "vidaplanninglogic/lib/services/apis/Apis";
import { IDataStore, IUser } from "vidaplanninglogic/lib/data/entities/IDataStore"
import { RootState } from "vidaplanninglogic/lib/reduxx/store";

class NavClass extends Component<props, state>{
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: '#284E80' }}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={process.env.PUBLIC_URL + '/vida_logo_white.png'} style={{ maxHeight: "35px" }} />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                            {
                                (this.props.User.LoggedIn) && (
                                    <>
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" href="#">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Search</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Actions</a>
                                        </li>
                                    </>
                                )
                            }
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/About">About</a>
                            </li>
                        </ul>
                        <div className="d-flex">
                            {(this.props.User.LoggedIn) && (<div className="nav-item text-white p-2">
                                {this.props.User.FirstName} {this.props.User.LastName}
                            </div>
                            )}

                            <ul className="navbar-nav">
                                {
                                    (this.props.User.LoggedIn) && (<li className="nav-item">
                                        <a className="nav-link" href='#' onClick={() => this.logout()}>
                                            <FontIcon iconName="SignOut" />
                                        </a>
                                    </li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
    logout = () => {
        Apis.AuthServices.logout();
        this.props.logout();
    }
}



interface props extends PropsFromRedux {
}

interface state {

}

const mapStateToProps = (state: RootState) => ({
    User: state.user
})
const mapDispatchToProps = {
    logout
}


const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export const Nav = connector(NavClass)