import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IconButton, Pivot, PivotItem } from '@fluentui/react';

import { ILoadable } from "vidaplanninglogic/lib/data/entities/ILoader";

import HomeTab from "./home/HomeTab";
import { ShimmerComponent } from "../common/Simmer";
import Footer from "../common/Footer";
import { ProfileVehicleTab } from "./profileVehicleTab/ProfileVehicleTab";
import { Cancel } from "../common/IconProps"
import { Header } from "../common/Header";
import { IProfiledVehicle } from "vidaplanninglogic/lib/data/entities/IProfiledVehicle";
import { profiledVehiclesActions } from "vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice"
import { setSelectedTab } from "vidaplanninglogic/lib/reduxx/slices/commonSlice";
import { RootState } from "vidaplanninglogic/lib/reduxx/store";


class MainClass extends Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            loading: false,
            failed: false,
            error: '',
        }
    }

    setSelectedTab = (item?: PivotItem) => {
        let selectedKey = item?.props.itemKey!;
        this.props.setSelectedTab(selectedKey);

    }
    render() {
        let loading = this.state.loading && (
            <ShimmerComponent />
        )
        let errors = this.state.failed && (
            <div className='alert alert-danger'>
                <em >{this.state.error}</em>
            </div>
        );

        return (
            <div>
                <Header />
                <div className="p-2" style={{ marginTop: 80 }} >
                    {errors}
                    {
                        !this.state.loading ? (
                            <Pivot aria-label="Basic Pivot Example" selectedKey={this.props.selectedMainTab}
                                onLinkClick={this.setSelectedTab}>
                                <PivotItem
                                    alwaysRender
                                    headerText="Home"
                                    key={'Home'}
                                    itemKey={'Home'}

                                    headerButtonProps={{
                                        'data-order': 1,
                                        'data-title': 'My Files Title',
                                    }}
                                >
                                    <HomeTab />
                                </PivotItem>
                                {
                                    this.props.profiledVehicles.map((item: IProfiledVehicle) => {


                                        return (
                                            <PivotItem
                                                onRenderItemLink={() => this.onRenderItemLink(item.title, item.vin)}
                                                alwaysRender
                                                key={item.vin}
                                                itemKey={item.vin}>
                                                <ProfileVehicleTab selectedVehicle={item} />
                                            </PivotItem>)
                                    })
                                }
                            </Pivot>
                        ) : (loading)
                    }
                </div>
                <Footer />
            </div>
        )
    }
    onRenderItemLink(headerText: string, vin: string): JSX.Element | null {
        return (
            <div>
                {headerText}
                <IconButton onClick={() => this.props.removeProfiledVehicle(vin)} iconProps={Cancel} />
            </div>
        )
    }
}

interface props extends PropsFromRedux {
}

interface state extends ILoadable {
}

const mapStateToProps = (store: RootState) => ({
    profiledVehicles: store.profiledVehicles.Vehicles,
    selectedMainTab: store.common.SelectedMainTab,
})
const mapDispatchToProps = {
    removeProfiledVehicle: profiledVehiclesActions.removeProfiledVehicle,
    setSelectedTab
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const Main = connector(MainClass);