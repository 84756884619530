import { IconButton } from '@fluentui/react';
import { connect, ConnectedProps } from 'react-redux';
import { Accept, Note, Cancel } from '../../common/IconProps';
import { Apis } from 'vidaplanninglogic/lib/services/apis/Apis';
import { ActionsEnum } from 'vidaplanninglogic/lib/data/entities/WorkListActions';
import { MessageType } from 'vidaplanninglogic/lib/data/constants/MessageType';
import { IServiceOrderActionRequest } from 'vidaplanninglogic/lib/data/entities/IServiceOrderActionRequest';
import { IPartsViewModel } from 'vidaplanninglogic/lib/data/entities/IPackage';
import { IProfiledVehicle } from 'vidaplanninglogic/lib/data/entities/IProfiledVehicle';
import { ContentType } from 'vidaplanninglogic/lib/data/entities/ContentType';
import { RootState } from 'vidaplanninglogic/lib/reduxx/store';
import { setLoading, setMessage } from 'vidaplanninglogic/lib/reduxx/slices/commonSlice';
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice';
import { actionCreators } from 'vidaplanninglogic/lib/services/services/worklist/actionCreators/actionCreators';


function PartRowFunction(props: props) {
    let jobNumber = '';
    let qty = '';
    let costWithVAT: number = 0;
    if (props.Part.dmsPrice.amountWithVAT)
        costWithVAT = props.Part.dmsPrice.amountWithVAT! * props.Part.quantity
    let costWithoutVAT: number = 0;
    if (props.Part.dmsPrice.amountWithoutVAT)
        costWithoutVAT = props.Part.dmsPrice.amountWithoutVAT! * props.Part.quantity
    return (
        <tr key={props.Part.rowId} className='text-center'>
            <td scope="row"><IconButton iconProps={Accept} /></td>
            <td></td>
            <td>{props.Part.partItem.partNumber}</td>
            <td className="text-start">{props.Part.partItem.description}</td>
            <td><IconButton iconProps={Note} /></td>
            <td>
                <input
                    size={1}
                    type='string'
                    name="JobNumber"
                    value={props.Part.jobNumber?.toString()}
                    onFocus={(x) => {
                        jobNumber = x.target.value;
                        console.log(jobNumber);
                    }}
                    onChange={(ev) => {
                        props.updateJobNumber({
                            vin: props.SelectedVehicle.vin,
                            rowId: props.Part.rowId,
                            jobNumber: ev.target.value,
                            contentType: ContentType.Parts
                        })
                    }}
                    onBlur={(ev) => {
                        if (jobNumber != ev.target.value) {
                            console.log("before saving!");
                            let action: IServiceOrderActionRequest = actionCreators.UpdatePart(props.Part);
                            props.SaveEngineCall(action)
                        }
                    }}>
                </input></td>
            <td>{props.SelectedVehicle.currentWorkList!.IncludeTax ? props.Part.dmsPrice.amountWithVAT : props.Part.dmsPrice.amountWithoutVAT}</td>
            <td>
                <input
                    size={1}
                    type='string'
                    //className='form-control'
                    name="Quantity"
                    value={props.Part.quantity?.toString()}
                    onFocus={(x) => {
                        qty = x.target.value;
                        console.log(qty);
                    }}
                    onChange={(ev) => {
                        props.updateQuantity({
                            vin: props.SelectedVehicle.vin,
                            rowId: props.Part.rowId,
                            quantity: Number(ev.target.value),
                            contentType: ContentType.Parts
                        })
                    }}
                    onBlur={(ev) => {
                        if (qty != ev.target.value) {
                            console.log("before saving!");
                            let action: IServiceOrderActionRequest = actionCreators.UpdatePart(props.Part);
                            props.SaveEngineCall(action)

                        }
                    }}>
                </input></td>
            <td>{props.SelectedVehicle.currentWorkList!.IncludeTax ? costWithVAT : costWithoutVAT}</td>
            <td><IconButton iconProps={Cancel} onClick={() => {
                let action: IServiceOrderActionRequest = actionCreators.RemovePart(props.Part.rowId);
                props.SaveEngineCall(action);
                props.removePart({ rowId: props.Part.rowId, vin: props.SelectedVehicle.vin });
                // props.setLoading(true);
                // Apis.ServiceOrder.RemoveServiceOrderItem(ActionsEnum.RemovePart, props.Part.rowId,
                //     props.ServiceOrderId, props.SelectedVehicle.currentWorkList!.Version).then((response) => {
                //         props.removePart({ rowId: props.Part.rowId, vin: props.SelectedVehicle.vin })
                //         props.setMessage({ messageText: 'Part Removed Successfully!', messageType: MessageType.success })
                //     }).catch((error) => {
                //         props.setMessage({ messageText: 'Remove Part Failed!', messageType: MessageType.error });
                //     }).finally(() => props.setLoading(false))
            }} /></td>
        </tr >
    )
}

interface props extends PropsFromRedux {
    Part: IPartsViewModel,
    SelectedVehicle: IProfiledVehicle,
    ServiceOrderId: string,
    SaveEngineCall(data: IServiceOrderActionRequest): void,
}


const mapStateToProps = (store: RootState) => ({

})
const mapDispatchToProps = {
    setLoading,
    setMessage,
    updateJobNumber: profiledVehiclesActions.updateJobNumber,
    updateQuantity: profiledVehiclesActions.updateQuantity,
    removePart: profiledVehiclesActions.removePart,
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const PartRow = connector(PartRowFunction);

