import React from 'react'
import { Nav } from './Nav'
import { LoadingBar } from './LoadingBar'
import { Message } from './MessagBar'
import { ICommonSlice, IDataStore } from 'vidaplanninglogic/lib/data/entities/IDataStore'
import { setMessage } from 'vidaplanninglogic/lib/reduxx/slices/commonSlice'
import { connect, ConnectedProps } from 'react-redux'
import { MessageType } from 'vidaplanninglogic/lib/data/constants/MessageType'
import { RootState } from 'vidaplanninglogic/lib/reduxx/store'

function HeaderFunction(props: props) {
    const removeMessage = () => {
        props.setMessage({ messageText: '', messageType: MessageType.info });
    }
    return (
        <>
            <div className='fixed-top'>
                <Nav />
                <LoadingBar Show={props.Common.Loading} />
                <Message
                    MessageType={props.Common.MessageType}
                    MessageText={props.Common.MessageText}
                    MessageId={props.Common.MessageId}
                    ClearMessage={removeMessage} />
            </div>
        </>
    )
}

interface props extends PropsFromRedux {
}

const mapStateToProps = (state: RootState) => ({
    Common: state.common,
})
const mapDispatchToProps = {
    setMessage
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export const Header = connector(HeaderFunction)



