import { IPartsViewModel } from 'vidaplanninglogic/lib/data/entities/IPackage';


function TableFooter(props: props) {

    const anyItemSelected = (): boolean => {
        return props.SelectedParts.length > 0;
    }

    const calculateSubTotalCost = (): number => {
        let subTotalCost = 0;
        props.SelectedParts.forEach(x => {
            let amount = props.IncludeTax ? x.dmsPrice.amountWithVAT : x.dmsPrice.amountWithoutVAT;
            subTotalCost += (Number(amount) * x.quantity)
        })
        return subTotalCost;
    }


    return (
        <>
            {
                (anyItemSelected()) && (
                    <div className='row justify-content-sm-end mb-4'>
                        <div className='col-sm-3'>
                            <div className='row'>
                                <div className='col-sm-8'>Subtotal Cost:</div>
                                <div className='col-sm-4'>{calculateSubTotalCost().toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

interface props {
    SelectedParts: IPartsViewModel[],
    IncludeTax: boolean,
    ShowFixedPrice: boolean
}

export default TableFooter

