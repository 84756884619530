import { FontIcon, IconButton } from '@fluentui/react';
import { forwardRef, useState } from 'react';

import { IVehicleContext } from 'vidaplanninglogic/lib/data/entities/dtos/IVehicleContext';
// import './SuggestionRow.css'
import { IPartSearchResult } from 'vidaplanninglogic/lib/data/entities/dtos/IPartSearchResult';

function SuggestionRow(props: props) {
    return (
        <div className='bg-white'>
            <div className='row p-1 text-nowrap text-start hover-item' style={{ width: 1100 }}
                onClick={(e) => {
                    props.addSelectedPart(props.part)
                    e.stopPropagation();
                }}
            >
                <div className='col-2'>Part</div>
                <div className='col-2'>{props.part.section.content.partsConstruct[0].partItem.part.partNumber}</div>
                <div className='col-4'>
                    <span>
                        {props.part.section.content.partsConstruct[0].partItem.part.description}
                    </span>
                    {
                        props.part.section.content.partsConstruct[0].partItem.descriptions.description[0].value &&
                        <span className='text-muted'> - </span>
                    }
                    <span className='text-muted'>
                        {props.part.section.content.partsConstruct[0].partItem.descriptions.description[0].value}
                    </span>
                </div>
                <div className='col-4'>{props.part.section.parentFunctionGroups.vehiclefunction[0].functiongroup} - {props.part.section.title}</div>
            </div>
        </div>
    )
}

interface props {
    part: IPartSearchResult;
    vehicleContext: IVehicleContext;
    addSelectedPart(op: IPartSearchResult): void
}

export default SuggestionRow