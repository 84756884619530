import { FontIcon, IBasePicker, IBasePickerSuggestionsProps, IconButton, IDropdownStyles, IInputProps, ISuggestionItemProps, ITag, IToggleStyles, Link, mergeStyles, TagPicker } from '@fluentui/react';
import React from 'react'
import { connect, ConnectedProps } from 'react-redux';

import { ICsc } from 'vidaplanninglogic/lib/data/entities/dtos/ICsc';
import { IServiceOrderActionRequest } from 'vidaplanninglogic/lib/data/entities/IServiceOrderActionRequest';
import { IProfiledVehicle } from 'vidaplanninglogic/lib/data/entities/IProfiledVehicle';
import { Apis } from 'vidaplanninglogic/lib/services/apis/Apis';
import { actionCreators } from 'vidaplanninglogic/lib/services/services/worklist/actionCreators/actionCreators';
import { setMessage, setLoading } from "vidaplanninglogic/lib/reduxx/slices/commonSlice";

import { Accept, Book, Medical, Note, Cancel } from "../../common/IconProps";
import { ActionsEnum } from "vidaplanninglogic/lib/data/entities/WorkListActions";
import { MessageType } from "vidaplanninglogic/lib/data/constants/MessageType";
import { Services } from 'vidaplanninglogic/lib/services/apis/Services';
import { ContentType } from 'vidaplanninglogic/lib/data/entities/ContentType';
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice'
import { RootState } from 'vidaplanninglogic/lib/reduxx/store';
import { SaveEngineServices } from 'vidaplanninglogic/lib/services/services/serviceOrder/SaveEngine.services'
import SuggestionRow from './SuggestionRow';

interface ICscDropDownOptions extends ITag, ICsc { }

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
  },
  root: {
    height: 50
  }
};

class CustomerSymptomCodeClass extends React.Component<props, state>{
  dropdownRef = React.createRef<IBasePicker<ITag>>();
  saveEngineService: SaveEngineServices = new SaveEngineServices();

  constructor(props: props) {
    super(props);
    this.state = {
      showDropdown: false,
      showSearchTools: false,
      showResultPanel: false,
      cscOptions: [],
      selectedKey: "",
      searchText: "",
      tagPickerValue: [],
    }

  }

  rootClass = mergeStyles({
    maxWidth: 500,
  });

  toggleStyles: Partial<IToggleStyles> = { root: { margin: "10px 0" } };

  inputProps: IInputProps = {
    onBlur: (ev: React.FocusEvent<HTMLInputElement>) => {
    },
    onFocus: (ev: React.FocusEvent<HTMLInputElement>) => {
    },
  };

  pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Suggested CSCs",
    noResultsFoundText: "No CSC found",
  };

  listContainsTagList = (csc: ICsc): boolean => {
    // if (!tagList || !tagList.length || tagList.length === 0) {
    //     return false;
    // }
    // return tagList.some(compareTag => compareTag.key === tag.key);

    return this.props.selectedVehicle.currentWorkList!.Cscs.some((x) => x.id == csc.id);
  };

  filterSuggestedTags = (filterText: string, tagList?: ITag[]): ITag[] => {
    let result: ITag[] = [];
    if (filterText) {
      if (filterText.length < 3) {
        result = this.state.cscOptions.filter(
          (csc) => csc.name.toLowerCase().startsWith(filterText.toLowerCase()) && !this.listContainsTagList(csc));
      }
      else {
        result = this.state.cscOptions.filter(
          (csc) => csc.name.toLowerCase().includes(filterText.toLowerCase()) && !this.listContainsTagList(csc));
      }
    }
    return result;
  };

  // filterSelectedTags = (filterText: string, tagList: ITag[]): ITag[] => {
  //     return filterText ? this.testTags.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0) : [];
  // };

  getTextFromItem = (item: ITag) => item.name;

  componentDidMount() {
    this.searchServicePlan();
    this.saveEngineService.start(this.props.selectedVehicle.vin);
  }

  componentWillUnmount() {
    this.saveEngineService.stop();
  }

  searchServicePlan = async () => {
    let cscOptions: ICscDropDownOptions[] = this.props.selectedVehicle.cscs.map((x: ICsc) => {
      return { key: x.id.toString(), name: x.code + `    ` + x.componentFunction.desc, ...x };
    });

    this.setState({
      cscOptions: cscOptions,
      showDropdown: true
    })
  }

  onRenderOption = (item: ICsc) => {
    return (
      <table>
        <tbody>
          <tr>
            <td style={{ width: 250 }}>{item.code}</td>
            <td style={{ width: "*" }}>{item.componentFunction.desc}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  onRenderTitle(item: any): JSX.Element | null {
    return (
      <div>
        {item[0].code}&nbsp;&nbsp;{item[0].componentFunction.desc}
      </div>
    );
  }

  addSelectedCsc = (csc: ICsc) => {
    console.log("this.props.selectedVehicle.currentWorkList!.Cscs");
    console.log(this.props.selectedVehicle.currentWorkList!.Cscs);
    console.log(Services.WorklistServices.GetMaxRowId(this.props.selectedVehicle.currentWorkList!.Cscs));
    console.log(csc.rowId);
    csc.rowId = Services.WorklistServices.GetMaxRowId(this.props.selectedVehicle.currentWorkList!.Cscs)
    csc.jobNumber = Services.WorklistServices.GetNextJobNumber(this.props.selectedVehicle.currentWorkList!, ContentType.Cscs)

    let spDataToSave: IServiceOrderActionRequest = actionCreators.AddCSC(csc);
    this.SaveEngineCall(spDataToSave)
    this.props.addCsc({ csc, vin: this.props.selectedVehicle.vin });
  };


  onCscItemSelect = (items?: ITag[]) => {
    if (items && items.length > 0) {
      let csc = this.state.cscOptions.filter((x) => x.id == items?.[0].key);
      csc[0].rowId = Services.WorklistServices.GetMaxRowId(this.props.selectedVehicle.currentWorkList!.Cscs)
      csc[0].jobNumber = Services.WorklistServices.GetNextJobNumber(this.props.selectedVehicle.currentWorkList!, ContentType.Cscs)

      let spDataToSave: IServiceOrderActionRequest = actionCreators.AddCSC(csc[0]);
      this.SaveEngineCall(spDataToSave)
      this.props.addCsc({ csc: csc[0], vin: this.props.selectedVehicle.vin });
    }
  };

  SaveEngineCall = (data: IServiceOrderActionRequest) => {
    this.props.addAction({ vin: this.props.selectedVehicle.vin, action: data.actions[0] })
    // Apis.ServiceOrder.SaveEngineCall(this.props.selectedVehicle.currentWorkList!.ServiceOrderId, this.props.selectedVehicle.currentWorkList!.Version, data).
    //   then((response) => {
    //     this.props.updateVersion({ version: response.data.version, vin: this.props.selectedVehicle.vin });
    //     console.log("Csc row Saved!!!");
    //   }).catch((error) => {
    //     console.log(error);
    //   })
  }

  onRenderSuggestionsItem = (props: ITag, itemProps: ISuggestionItemProps<ITag>) => {
    let csc = this.state.cscOptions.filter(x => x.id == props.key);
    // let op = this.state.opOptions.filter(x => x.operationNumber == props.key);
    return (
      <SuggestionRow
        addSelectedCsc={this.addSelectedCsc}
        csc={csc[0]}
        vehicleContext={this.props.selectedVehicle.vehicleContext} />
    )
  };

  renderSearchPanel = () => {
    return (
      <React.Fragment>
        <div className={"row p-2 " + (this.state.showSearchTools == false ? "d-inline" : "d-none")}>
          <Link onClick={() => this.setState({ showSearchTools: true }, () => {
            this.dropdownRef.current!.focus();
          })}>Add</Link>
        </div>
        <div className={"row p-2 " + (this.state.showSearchTools == true ? "d-inline" : "d-none")}>
          <TagPicker
            removeButtonAriaLabel="Remove"
            selectionAriaLabel="Selected CSCs"
            onResolveSuggestions={this.filterSuggestedTags}
            getTextFromItem={this.getTextFromItem}
            pickerSuggestionsProps={this.pickerSuggestionsProps}
            itemLimit={1}
            componentRef={this.dropdownRef}
            onRenderSuggestionsItem={this.onRenderSuggestionsItem}
            resolveDelay={500}
            // onChange={this.onCscItemSelect}
            selectedItems={[]}
          // inputProps={{
          //   ...this.inputProps,
          //   id: "picker1",
          // }}
          />
          {/* <div className='row'>
                                <div className='col-sm-3'>
                                    <FontIcon iconName="car" />
                                    <label className='ms-2'>Csc Search:</label>
                                    <span className='text-danger'>*</span>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name="searchText"
                                        value={this.state.searchText}
                                        onChange={this.handleChange}>
                                    </input>
                                </div>
                            </div> */}
          {/* <div className='row'>
                                <div>
                                    <Link onClick={this.searchServicePlan} className='pe-2'>Continue</Link>
                                    <Link onClick={() => this.setState({ showSearchTools: false })}>Cancel</Link>
                                </div>
                                {(this.state.showDropdown == true) && (<div>
                                    <Dropdown
                                        componentRef={this.dropdownRef}
                                        placeholder="Select a Service Plan"
                                        options={this.state.cscOptions as IDropDownOptions[]}
                                        selectedKey={this.state.selectedKey}
                                        onChange={(x, item) => this.onChange(item)}
                                        //errorMessage={this.state.failed ? 'This dropdown has an error' : undefined}
                                        styles={dropdownStyles}
                                        onRenderOption={(item: any) => this.onRenderOption(item)}
                                        onRenderTitle={(item: any) => this.onRenderTitle(item)}

                                    />
                                </div>)}
                            </div> */}
        </div>
      </React.Fragment>
    )
  }

  renderResultPanel = () => {
    let jobNumber = '';
    return (
      <React.Fragment>
        <div className='row'>
          {(this.props.selectedVehicle.currentWorkList!.Cscs.length > 0) && (
            <table className="table table-hover table-sm">
              <thead className='table-light'>
                <tr className='text-center'>
                  <th style={{ width: 20 }} scope="col"></th>
                  <th style={{ width: 100 }} scope="col">CSC</th>
                  <th className="text-start" scope="col">Description</th>
                  <th style={{ width: 100 }} scope="col">Guidance</th>
                  <th style={{ width: 100 }} scope="col">Notes</th>
                  <th style={{ width: 100 }} scope="col">Job No</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {this.props.selectedVehicle.currentWorkList!.Cscs.map((item: ICsc) => (
                  <tr key={item.id} className="text-center">
                    <td scope="row"><IconButton iconProps={Accept} /></td>
                    <td>{item.code}</td>
                    <td className="text-start" style={{ maxWidth: 500 }}>{item.deviation}</td>
                    <td>{
                      item.isFaultTracingAvailable == true && (<IconButton styles={{ root: [{ color: "orange" }] }} iconProps={Medical} />)
                    }
                      {item.isInformationVerificationAvailable == true && (
                        <IconButton styles={{ root: [{ color: "orange" }] }} iconProps={Book} />
                      )}
                    </td>
                    <td><IconButton iconProps={Note} /></td>
                    <td>
                      <input
                        size={1}
                        type='string'
                        name="JobNumber"
                        value={item.jobNumber?.toString()}
                        onFocus={(x) => {
                          jobNumber = x.target.value;
                        }}
                        onChange={(ev) => {
                          this.props.updateJobNumber({ vin: this.props.selectedVehicle.vin, rowId: item.rowId, jobNumber: ev.target.value, contentType: ContentType.Cscs });
                        }}
                        onBlur={(ev) => {
                          if (jobNumber == ev.target.value) {
                            return;
                          }

                          let action: IServiceOrderActionRequest = actionCreators.UpdateCSC(item);
                          this.SaveEngineCall(action)
                        }}>
                      </input></td>
                    <td>
                      <IconButton iconProps={Cancel} onClick={() => {
                        this.props.removeCsc({ rowId: item.rowId, vin: this.props.selectedVehicle.vin });
                        let action: IServiceOrderActionRequest = actionCreators.RemoveCSC(item.rowId);
                        this.SaveEngineCall(action)
                      }}
                      />
                    </td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className="row p-2 mt-2 border mb-4">
        <div className="bg-light p-2 border">
          <h5 className="mb-0">
            <FontIcon iconName="Bug" />
            <span className="ms-3">Customer Symptom Codes - </span>
            <span>{this.props.selectedVehicle.currentWorkList!.Cscs.length}</span>
          </h5>
        </div>
        <div className="row p-2">{this.renderResultPanel()}</div>
        {this.renderSearchPanel()}
      </div>
    );
  }
}

interface props extends PropsFromRedux {
  selectedVehicle: IProfiledVehicle;
}

interface state {
  showSearchTools: boolean,
  showDropdown: boolean,
  showResultPanel: boolean,
  selectedKey: string,
  searchText: string,
  cscOptions: ICscDropDownOptions[],
  tagPickerValue: any,
}

const mapStateToProps = (store: RootState) => ({
  profiledVehicles: store.profiledVehicles.Vehicles,
});
const mapDispatchToProps = {
  setLoading,
  setMessage,
  updateWorkList: profiledVehiclesActions.updateWorkList,
  addCsc: profiledVehiclesActions.addCsc,
  removeCsc: profiledVehiclesActions.removeCsc,
  updateJobNumber: profiledVehiclesActions.updateJobNumber,
  updateVersion: profiledVehiclesActions.updateVersion,
  addAction: profiledVehiclesActions.addAction
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const CustomerSymptomCode = connector(CustomerSymptomCodeClass)
