import React from 'react';
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { AppSetting, VtsToken } from 'vidaplanninglogic/lib/data/constants/AppSetting';
import { setUserSettings } from 'vidaplanninglogic/lib/reduxx/slices/userSettingsSlice';
import { RootState } from 'vidaplanninglogic/lib/reduxx/store';
import { TranslationSourceTypes } from '../../../common/TranslationSourceTypes';
import { VidaCommunicationTypes } from '../../../common/VidaCommunicationTypes';
import { VidaLogTypes } from '../../../common/VidaLogTypes';
import { RecentVehiclesTabClass, RecentVehiclesTab } from '../home/RecentVehiclesTab'
import { IUserSettingArrayToIUserSettingList } from 'vidaplanninglogic/lib/services/services/mapper/IUserSettingArrayToIUserSettingList'

class RecentVehicleClass extends Component<props, state>{
    recentVehicleRef;
    constructor(props: props) {
        super(props);

        this.state = {
            isReady: false,
            partnerGroupCid: "",
            translationTexts: {},
        }
        this.recentVehicleRef = React.createRef<RecentVehiclesTabClass>();
    }

    componentDidMount() {
        document.addEventListener('messageReceiveEvent', this.receiveMessage);
        this.notifyVidaToSendNeededData();
    }
    componentWillUnmount() {
        document.removeEventListener('messageReceiveEvent', this.receiveMessage);
    }

    notifyVidaToSendNeededData = () => {
        let event = new CustomEvent('messageSentEvent', { detail: { Type: VidaCommunicationTypes.SendNeededData, Payload: `{ }` } });
        document.dispatchEvent(event);
    }

    notifyVidaRecentVehicle = (vin: string, partnerGroupId: number) => {
        let event = new CustomEvent('messageSentEvent', { detail: { Type: VidaCommunicationTypes.ProfileVehicle, Payload: `{ VIN: "${vin}", PartnerGroupId: ${partnerGroupId} }` } });
        document.dispatchEvent(event);
    }

    notifyVidaVehicleViewDisplay = (vin: string, partnerGroupId: number) => {
        let event = new CustomEvent('messageSentEvent', { detail: { Type: VidaCommunicationTypes.VehicleViewDisplay, Payload: `{ VIN: "${vin}", PartnerGroupId: ${partnerGroupId} }` } });
        document.dispatchEvent(event);
    }

    notifySendVehicleInfoToDms = (vin: string, partnerGroupId: number) => {
        let event = new CustomEvent('messageSentEvent', { detail: { Type: VidaCommunicationTypes.SendVehicleInfoToDms, Payload: `{ VIN: "${vin}", PartnerGroupId: ${partnerGroupId} }` } });
        document.dispatchEvent(event);
    }

    notifyWebLogstoVida = (logType: VidaLogTypes, message: string) => {
        let event = new CustomEvent('messageSentEvent', { detail: { Type: VidaCommunicationTypes.VidaLog, Payload: `{ Type: "${logType}", Message: "Web: ${message}"}` } });
        document.dispatchEvent(event);
    }

    notifyProfileVehicleWithoutVinToVida = (selectedModel: number,
        selectedYear: number,
        selectedPartnerGroup: number,
        selectedEngine: number | null,
        selectedTransmission: number | null,
        selectedSteering: number,
        selectedSpecialVehicle: number,
        selectedBodyStyle: number) => {
        let event = new CustomEvent('messageSentEvent',
            {
                detail: {
                    Type: VidaCommunicationTypes.ProfileVehicleWithoutVin,
                    Payload: `{selectedModel: "${selectedModel}", 
                    selectedYear: "${selectedYear}",
                    selectedPartnerGroup: "${selectedPartnerGroup}",
                    selectedEngine: "${selectedEngine}",
                    selectedTransmission: "${selectedTransmission}",
                    selectedSteering: "${selectedSteering}",
                    selectedSpecialVehicle: "${selectedSpecialVehicle}",
                    selectedBodyStyle: "${selectedBodyStyle}", }`
                }
            });
        document.dispatchEvent(event);
    }

    receiveMessage = (e: any) => {
        switch (e.detail.Type) {
            case VidaCommunicationTypes.VtsToken:
                console.log('VidaCommunicationTypes.VtsToken');
                VtsToken.Token = e.detail.Payload.vtsToken;
                AppSetting.Username = e.detail.Payload.userName;
                break;
            case VidaCommunicationTypes.PartnerGroupCid:
                console.log('VidaCommunicationTypes.PartnerGroupCid');
                this.setState({ partnerGroupCid: e.detail.Payload.Cid });
                break;
            case VidaCommunicationTypes.TranslationTexts:
                console.log('VidaCommunicationTypes.TranslationTexts');
                if (e.detail.Payload.source == TranslationSourceTypes.RecentVehicle)
                    this.setState({ translationTexts: e.detail.Payload.dictionary });
                break;
            case VidaCommunicationTypes.RefreshRecentVehicle:
                console.log('VidaCommunicationTypes.RefreshRecentVehicle');
                this.recentVehicleRef.current?.populateData();
                break;
            case VidaCommunicationTypes.UserSettings:
                console.log('VidaCommunicationTypes.UserSettings');
                console.log(e.detail.Payload.usersetting);
                let response = IUserSettingArrayToIUserSettingList(e.detail.Payload.usersetting);
                this.props.setUserSettings(response);
                break;
        }
        this.setState({ isReady: true })
    }

    render() {
        return (
            <>
                {
                    this.state.isReady == true ? <RecentVehiclesTab ref={this.recentVehicleRef}
                        notifyVidaRecentVehicle={this.notifyVidaRecentVehicle}
                        PartnerGroupCid={this.state.partnerGroupCid}
                        notifyVidaVehicleViewDisplay={this.notifyVidaVehicleViewDisplay}
                        translationTexts={this.state.translationTexts} isVidaClientUi={true}
                        notifySendVehicleInfoToDms={this.notifySendVehicleInfoToDms}
                        notifyWebLogstoVida={this.notifyWebLogstoVida}
                        notifyProfileVehicleWithoutVinToVida={this.notifyProfileVehicleWithoutVinToVida} /> : (
                        <div>
                        </div>
                    )
                }
            </>
        )
    }
}

interface props extends PropsFromRedux { }

interface state {
    isReady: boolean,
    partnerGroupCid?: string,
    translationTexts: any
}
interface CefCommunicationModel {
    Type: string,
    Payload: string
}

const mapStateToProps = (store: RootState) => ({
})
const mapDispatchToProps = {
    setUserSettings
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const RecentVehicle = connector(RecentVehicleClass);
