import { useState } from 'react'
import { Accept, Cancel, Down, Note, Right } from '../../common/IconProps'
import { IconButton, Toggle } from '@fluentui/react'
import { IPartsViewModel } from 'vidaplanninglogic/lib/data/entities/IPackage';
import { IPackageViewModel } from 'vidaplanninglogic/lib/data/entities/IPackageViewModel';
import { Apis } from 'vidaplanninglogic/lib/services/apis/Apis';
import { ActionsEnum } from 'vidaplanninglogic/lib/data/entities/WorkListActions';
import { setLoading, setMessage, } from "vidaplanninglogic/lib/reduxx/slices/commonSlice";
import { connect, ConnectedProps } from 'react-redux';
import { MessageType } from 'vidaplanninglogic/lib/data/constants/MessageType';
import { IServiceOrderActionRequest } from 'vidaplanninglogic/lib/data/entities/IServiceOrderActionRequest';
import { actionCreators } from 'vidaplanninglogic/lib/services/services/worklist/actionCreators/actionCreators';
import { UpdateServiceProgram } from 'vidaplanninglogic/lib/services/services/worklist/actionCreators/UpdateServiceProgram.services';
import { IProfiledVehicle } from 'vidaplanninglogic/lib/data/entities/IProfiledVehicle';
import { ContentType } from 'vidaplanninglogic/lib/data/entities/ContentType';
import { profiledVehiclesActions } from 'vidaplanninglogic/lib/reduxx/slices/profiledVehiclesSlice';
import { IServiceProgram } from 'vidaplanninglogic/lib/data/entities/dtos/IServiceProgram';
import { RootState, store } from 'vidaplanninglogic/lib/reduxx/store';
import React from 'react';
function PackageRowFunction(props: props) {
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const onExpandButtonClick = () => {
        setShowDetails(!showDetails)
    }
    let qty = '';
    let operation = props.Package.includedOperations.operationitem?.[0];
    let jobNumber = '';

    React.useEffect(() => {
        if (props.ActionType == 'snoozeUnsnoozeServiceProgramPackages' && props.ActionPayload == props.Package.packageNumber) {
            console.log('useEffect hook is called');
        }
    }, [props.ActionId]);

    // const calculatePackageUnitCost = (): string => {

    //     let operationPrice = 0;
    //     let partsPrice = 0;

    //     if (operation?.dmsPrice.amountWithVAT)
    //         operationPrice = Number(operation?.vst) * 0.1 * operation?.dmsPrice.amountWithVAT!;

    //     props.Package.parts.part.forEach(part => {
    //         if (part.dmsPrice.amountWithVAT)
    //             partsPrice += part.dmsPrice.amountWithVAT
    //     })
    //     return (operationPrice + partsPrice).toFixed(2).toString();
    // }

    // const calculatePackageCost = (): string => {

    //     let operationPrice = 0;
    //     let partsPrice = 0;

    //     if (operation?.dmsPrice.amountWithVAT)
    //         operationPrice = Number(operation?.vst) * 0.1 * operation?.dmsPrice.amountWithVAT!;

    //     props.Package.parts.part.forEach(part => {
    //         if (part.dmsPrice.amountWithVAT)
    //             partsPrice += part.dmsPrice.amountWithVAT
    //     })
    //     return ((operationPrice + partsPrice)* props.Package.quantity).toFixed(2).toString();
    // }

    // const calculateOperationUnitCost = (operation: IOperationViewModel): string => {

    //     if (operation?.dmsPrice.amountWithVAT == undefined) return '';
    //     if (operation?.dmsPrice.amountWithoutVAT == undefined) return '';

    //     let price = Number(operation?.vst) * 0.1 * operation?.dmsPrice.amountWithVAT!;
    //     return price.toFixed(2).toString();
    // }

    // const calculateOperationCost = (operation: IOperationViewModel): string => {

    //     if (operation?.dmsPrice.amountWithVAT == undefined) return '';
    //     if (operation?.dmsPrice.amountWithoutVAT == undefined) return '';

    //     let price = Number(operation?.vst) * 0.1 * operation?.quantity * operation?.dmsPrice.amountWithVAT!;
    //     return price.toFixed(2).toString();
    // }

    return (
        <>
            {
                <tr key={props.Package.packageKey.mainOperationNumber} className='text-center'>
                    <td scope="row"><IconButton iconProps={Accept} /></td>
                    <td>
                        <IconButton onClick={onExpandButtonClick} iconProps={showDetails ? Down : Right} />
                    </td>
                    <td>Package</td>
                    <td style={{ textAlign: 'left' }}>{props.Package.packageNumber}</td>
                    <td className='text-nowrap text-start' style={{ overflow: 'hidden', maxWidth: 200 }}>
                        <p className='mb-0'>{operation?.operationDescription}</p>
                        <p className="mb-0 text-muted">{operation?.jobscopeDescription}</p>
                    </td>
                    <td>{operation?.skillLevel}</td>
                    <td><IconButton iconProps={Note} /></td>
                    <td>
                        {
                            props.ShowIncludeButton ? (
                                //Service Program package
                                props.Package.snoozed == true ? (props.Package.jobNumber) :
                                    <input size={1} type='string' value={props.Package.jobNumber?.toString()}
                                        onFocus={(x) => { jobNumber = x.target.value; }}
                                        onChange={(ev) => {
                                            let packageNumber = `${props.Package.packageNumber}`
                                            props.updateJobNumber!({ vin: props.SelectedVehicle.vin, rowId: packageNumber, jobNumber: ev.target.value, contentType: ContentType.ServiceProgramPackages })
                                        }}
                                        onBlur={(ev) => {
                                            if (jobNumber != ev.target.value) {
                                                console.log("before saving!");
                                                let packageNumber = `${props.Package.packageNumber}`
                                                let action: IServiceOrderActionRequest = UpdateServiceProgram(props.SelectedVehicle.currentWorkList!.ServiceProgram!, "", packageNumber);
                                                props.SaveEngineCall!(action)
                                            }
                                        }}>
                                    </input>)
                                :
                                //Package
                                <input size={1} type='string' value={props.Package.jobNumber?.toString()}
                                    onFocus={(x) => { jobNumber = x.target.value; }}
                                    onChange={(ev) => { props.updateJobNumber!({ vin: props.SelectedVehicle.vin, rowId: props.Package.rowId, jobNumber: ev.target.value, contentType: ContentType.Packages }) }}
                                    onBlur={(ev) => {
                                        if (jobNumber != ev.target.value) {
                                            console.log("before saving!");
                                            let action: IServiceOrderActionRequest = actionCreators.UpdatePackage(props.Package);
                                            props.SaveEngineCall(action)
                                        }
                                    }}>
                                </input>
                        }
                    </td>
                    <td>{operation?.vst}</td>
                    <td></td>
                    <td>{props.SelectedVehicle.currentWorkList!.IncludeTax ?
                        props.Package.dmsPrice.amountWithVAT?.toFixed(2) : props.Package.dmsPrice.amountWithoutVAT?.toFixed(2)}</td>
                    <td>{props.ShowIncludeButton ?
                        (<span>{props.Package.quantity?.toString()}</span>) :
                        (<input
                            size={1}
                            type='string'
                            name="Quantity"
                            value={props.Package.quantity?.toString()}
                            onFocus={(x) => {
                                qty = x.target.value;
                                console.log(qty);
                            }}
                            onChange={(ev) => {
                                if (operation?.maxquantity < parseFloat(ev.target.value) || parseFloat(ev.target.value) <= 0) {
                                    ev.preventDefault();
                                    props.setMessage({ messageText: 'Max quantity reached!', messageType: MessageType.warning });
                                    return;
                                }
                                props.updateQuantity({ vin: props.SelectedVehicle.vin, rowId: props.Package.rowId, quantity: parseFloat(ev.target.value), contentType: ContentType.Packages });
                            }}
                            onBlur={(ev) => {
                                if (qty == ev.target.value) {
                                    console.log("No update!");
                                    return;
                                }
                                console.log("before saving!");
                                let action: IServiceOrderActionRequest = actionCreators.UpdatePackage(props.Package);
                                props.SaveEngineCall(action)
                            }}>
                        </input>)}
                    </td>
                    <td>{props.SelectedVehicle.currentWorkList!.IncludeTax ?
                        props.Package.dmsPrice.amountWithVAT?.toFixed(2) : props.Package.dmsPrice.amountWithoutVAT?.toFixed(2)}</td>
                    <td>{props.ShowIncludeButton ?
                        (<Toggle checked={props.Package.snoozed! == false} onClick={() => {
                            props.setLoading(true);
                            console.log(`Snooze/Unsnooze PackageNumber: ${props.Package.packageNumber}`);
                            try {
                                let snoozed: boolean = props.Package.snoozed! == true ? false : true;

                                let previousState = store.getState();
                                props.snoozeUnsnoozeServiceProgramPackages({ vin: props.SelectedVehicle.vin, itemNumber: props.Package.packageNumber, snoozed })
                                let currentState = store.getState();

                                let counter = 1;
                                while (previousState == currentState && counter++ < 10) {
                                    new Promise(resolve => setTimeout(resolve, 500));
                                }

                                let serviceProgram: IServiceProgram = {
                                    ...props.SelectedVehicle.currentWorkList!.ServiceProgram!,
                                    serviceProgramPackages: props.SelectedVehicle.currentWorkList!.ServiceProgram!.serviceProgramPackages.map(x => {
                                        return x.packageItemNumber == props.Package.packageNumber ? { ...x, snooze: snoozed } : x
                                    })
                                }

                                let action: IServiceOrderActionRequest = UpdateServiceProgram(serviceProgram, "", props.Package.packageNumber);
                                props.SaveEngineCall(action);
                            }
                            catch (error) {
                                props.setMessage({ messageText: "Error occured in Snooze Package!", messageType: MessageType.error });
                            }
                            finally {
                                props.setLoading(false);
                            }
                        }} />) :
                        <IconButton iconProps={Cancel} onClick={() => {
                            let action: IServiceOrderActionRequest = actionCreators.RemovePackage(props.Package.rowId);
                            props.SaveEngineCall(action);
                            props.removePackage!({ rowId: props.Package.rowId, vin: props.SelectedVehicle.vin });
                            // props.setLoading(true);
                            // console.log(`Remove PackageNumber: ${props.Package.packageNumber}`);

                            // Apis.ServiceOrder.RemoveServiceOrderItem(ActionsEnum.RemovePackage, props.Package.rowId, props.SelectedVehicle.currentWorkList!.ServiceOrderId, props.GetLatestServiceOrderVersion!())
                            //     .then((response) => {
                            //         props.updateVersion({ version: response.data.version, vin: props.SelectedVehicle.vin });
                            //         console.log("Package Update done!!!");
                            //         console.log();

                            //         props.removePackage!({ rowId: props.Package.rowId, vin: props.SelectedVehicle.vin });
                            //         props.setMessage({ messageText: `Package ${props.Package.packageNumber} Removed Successfully!`, messageType: MessageType.success });
                            //     })
                            //     .catch((error) => {
                            //         props.setMessage({ messageText: "Error occured in Remove Package!", messageType: MessageType.error });
                            //     })
                            //     .finally(() => props.setLoading(false));;
                        }} />
                    }
                    </td>
                </tr>
            }
            {
                (showDetails) && (
                    <>
                        <tr key={props.Package.includedOperations.operationitem[0].operationNumber} className='text-center'>
                            <td scope="row"></td>
                            <td></td>
                            <td className='text-start'>Operation</td>
                            <td style={{ textAlign: 'left' }}>{operation?.operationNumber}-{operation?.operationType}</td>
                            <td className='text-nowrap text-start' style={{ overflow: 'hidden', maxWidth: 200 }}>
                                <p className='mb-0'>{operation?.operationDescription} <span className='text-muted'>- {props.Package.comment}</span></p>
                                {operation?.jobscopeDescription?.split('\n').map(str => <p className="mb-0 text-muted">{str}</p>)}
                            </td>
                            <td>{operation?.skillLevel}</td>
                            <td><IconButton iconProps={Note} /></td>
                            <td>{operation?.jobNumber}</td>
                            <td>{operation?.vst}</td>
                            <td></td>
                            <td>{props.SelectedVehicle.currentWorkList!.IncludeTax ?
                                operation?.dmsPrice.amountWithVAT?.toFixed(2) : operation?.dmsPrice.amountWithoutVAT?.toFixed(2)}</td>
                            <td>{operation?.quantity}</td>
                            <td>{props.SelectedVehicle.currentWorkList!.IncludeTax ?
                                operation?.dmsPrice.amountWithVAT?.toFixed(2) : operation?.dmsPrice.amountWithoutVAT?.toFixed(2)}</td>


                            {/* <div className='row p-1 text-nowrap text-center'>


                                    <div className='col-2 text-end'>Operation</div>
                                    <div className='col-2'>{operation?.operationNumber}-{operation?.operationType}</div>
                                    <div className='col-4 text-start' style={{ overflow: 'hidden' }}>
                                        <p className='mb-0'>{operation?.operationDescription} <span className='text-muted'>- {props.Package.comment}</span></p>
                                        {operation?.jobscopeDescription.split('\n').map(str => <p className="mb-0 text-muted">{str}</p>)}
                                    </div>
                                    <div className='col-2'>Job Time: {operation?.vst}</div>
                                    <div className='col-2'>{operation?.mpCategory}: {operation?.functionGroup}</div>
                                </div> */}
                        </tr>
                        {
                            props.Package.parts.part.map((part: IPartsViewModel) => {
                                return (
                                    <tr className='text-center'>
                                        <td scope="row"></td>
                                        <td></td>
                                        <td className='text-start'>Part</td>
                                        <td style={{ textAlign: 'left' }}>{part.partItem.partNumber}</td>
                                        <td className='text-nowrap text-start' style={{ overflow: 'hidden', maxWidth: 200 }}>
                                            {part.partItem.description}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>{part.jobNumber}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{props.SelectedVehicle.currentWorkList!.IncludeTax ?
                                            part.dmsPrice?.amountWithVAT?.toFixed(2) : part.dmsPrice?.amountWithoutVAT?.toFixed(2)}</td>
                                        <td>{part.quantity}
                                        </td>
                                        <td>{props.SelectedVehicle.currentWorkList!.IncludeTax ?
                                            part.dmsPrice?.amountWithVAT?.toFixed(2) : part.dmsPrice?.amountWithoutVAT?.toFixed(2)}</td>
                                        <td></td>
                                    </tr>
                                )
                            })
                        }

                    </>
                )
            }
        </>
    )
}

interface props extends PropsFromRedux {
    Package: IPackageViewModel;
    SelectedVehicle: IProfiledVehicle;
    // newVehicle: IProfiledVehicle[]
    ShowIncludeButton: boolean;
    SaveEngineCall(data: IServiceOrderActionRequest): void,
    GetLatestServiceOrderVersion?(): number,
}

const mapStateToProps = (store: RootState) => ({
    // newVehicle: store.profiledVehicles.Vehicles
    ActionType: store.profiledVehicles.ActionType,
    ActionPayload: store.profiledVehicles.ActionPayload,
    ActionId: store.profiledVehicles.ActionId,
})
const mapDispatchToProps = {
    setLoading,
    setMessage,
    removeOperation: profiledVehiclesActions.removeOperation,
    updateJobNumber: profiledVehiclesActions.updateJobNumber,
    updateVersion: profiledVehiclesActions.updateVersion,
    removePackage: profiledVehiclesActions.removePackage,
    updateQuantity: profiledVehiclesActions.updateQuantity,
    snoozeUnsnoozeServiceProgramPackages: profiledVehiclesActions.snoozeUnsnoozeServiceProgramPackages
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const PackageRow = connector(PackageRowFunction)